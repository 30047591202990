@mixin per( $property, $val ) {
	#{$property}: $val;
}

@function calcPer( $parent: 1440, $val: '', $unit: '%' ) {
	@return unquote( ( $val / $parent ) * 100 + $unit );
}

@function calcPerTypo( $parent: 1440, $val: '', $unit: '%' ) {
	@return unquote( ( $val / $parent ) + $unit );
}

// @mixin per( $property, $parent, $val, $unit ) {
// 	#{$property}: calcPer( $parent, $val, $unit );
// }

// @mixin per( $property, $parent, $val ) {
// 	#{$property}: unquote( ( $val / $parent ) * 100 + "%" );
// }

// @mixin pervw( $property, $vw: 1440, $val ) {
// 	#{$property}: unquote( ( $val / $vw ) * 100 + "vw" );
// }

// @mixin pervw2( $property, $vw: 1440, $val1, $val2 ) {
// 	#{$property}: unquote( ( $val1 / $vw ) * 100 + "vw" ) unquote( ( $val2 / $vw ) * 100 + "vw" );
// }

// @mixin pervw3( $property, $vw: 1440, $val1, $val2, $val3 ) {
// 	#{$property}: unquote( ( $val1 / $vw ) * 100 + "vw" ) unquote( ( $val2 / $vw ) * 100 + "vw" ) unquote( ( $val3 / $vw ) * 100 + "vw" );
// }

// @mixin pervw4( $property, $vw: 1440, $val1, $val2, $val3, $val4 ) {
// 	#{$property}: unquote( ( $val1 / $vw ) * 100 + "vw" ) unquote( ( $val2 / $vw ) * 100 + "vw" ) unquote( ( $val3 / $vw ) * 100 + "vw" ) unquote( ( $val4 / $vw ) * 100 + "vw" );
// }

// @function calcPer( $parent: 1440, $val: '', $unit: '%' ) {
// 	@return unquote( ( $val / $parent ) * 100 + $unit );
// }

// @mixin pervw( $property, $val, $vw: 1440 ) {
// 	#{$property}: unquote( $val + "px" );
// }

// @mixin pervw2( $property, $val1, $val2, $vw: 1440 ) {
// 	#{$property}: unquote( $val1 + "px" ) unquote( $val2 + "px" );
// }

// @mixin pervw3( $property, $val1, $val2, $val3, $vw: 1440 ) {
// 	#{$property}: unquote( $val1 + "px" ) unquote( $val2 + "px" ) unquote( $val3 + "px" );
// }

// @mixin pervw4( $property, $val1, $val2, $val3, $val4, $vw: 1440 ) {
// 	#{$property}: unquote( $val1 + "px" ) unquote( $val2 + "px" ) unquote( $val3 + "px" ) unquote( $val4 + "px" );
// }
