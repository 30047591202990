/*
 * Montserrat Regular: 400, Medium: 500, Bold: 700
 *
 * Layout
 * $block90: 1296px; / .cp-block-90 --- 1296px / 1440px
 * $block80: 1152px; / .cp-block-80 --- 1152px / 1440px
 * $block70: 1008px; / .cp-block-70 --- 1008px / 1440px
 * $block60:  864px; / .cp-block-60 ---  864px / 1440px
 */

 /****************************************************
 * COMPONENT
 */
@keyframes fade-show{
    0% { opacity: 0; display: none; }
    1% { opacity: 0; display: block; }
    100% { opacity: 1; display: block; }
}
// @keyframes fade-hidden{
//     0% { opacity: 1; display: block; }
//     99% { opacity: 0; display: block; }
//     100% { opacity: 0; display: none; }
// }

.cp {

	&-block {

		&-mv {
			background-color: #eeeeee;
			@include per( $property: 'height', $val: calcPer( 1440, 432, 'vw') );
		}

		// 1296px / 1440px
		&-90 {
			// @include per( $property: 'padding', $val: calcPer( 1440, 96, '%') 0 0 );
			@include per( $property: 'margin', $val: calcPer( 1440, 96, '%') 0 );

			@include mx-mq-max($bp-sp-max) {
				// @include per( $property: 'padding', $val: calcPer( $block100sp, 120, '%') 0 0 );
				@include per( $property: 'margin', $val: calcPer( $block100sp, 120, '%') 0 );
			}

			> .block__inner {
				// background-color: #eeeeee;
				width: 90%;
				margin: 0 auto;

				@include mx-mq-max($bp-sp-max) {
					width: 92%;
				}

				> [class*="lv"] {
					@include per( $property: 'margin', $val: 0 0 calcPer( $block90, 60, '%') );
					text-align: center;

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'margin', $val: 0 0 calcPer( $block90sp, 72, '%') );
					}
				}
			}
		}

		// 1152px / 1440px
		&-80 {
			// @include per( $property: 'padding', $val: calcPer( 1440, 96, '%') 0 0 );
			@include per( $property: 'margin', $val: calcPer( 1440, 96, '%') 0 );

			@include mx-mq-max($bp-sp-max) {
				// @include per( $property: 'padding', $val: calcPer( $block100sp, 120, '%') 0 0 );
				@include per( $property: 'margin', $val: calcPer( $block100sp, 120, '%') 0 );
			}

			> .block__inner {
				// background-color: #eeeeee;
				width: 80%;
				margin: 0 auto;

				@include mx-mq-max($bp-sp-max) {
					width: 84%;
				}

				> [class*="lv"] {
					@include per( $property: 'margin', $val: 0 0 calcPer( $block80, 60, '%') );
					text-align: center;

					@include mx-mq-max( $bp-sp-max ) {
						@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 72, '%') );
					}
				}
			}
		}

		// 1008px / 1440px
		&-70 {
			// @include per( $property: 'padding', $val: calcPer( 1440, 96, '%') 0 0 );
			@include per( $property: 'margin', $val: calcPer( 1440, 96, '%') 0 );

			@include mx-mq-max($bp-sp-max) {
				// @include per( $property: 'padding', $val: calcPer( $block100sp, 120, '%') 0 0 );
				@include per( $property: 'margin', $val: calcPer( $block100sp, 120, '%') 0 );
			}

			> .block__inner {
				// background-color: #eeeeee;
				width: 70%;
				margin: 0 auto;

				@include mx-mq-max($bp-sp-max) {
					width: 84%;
				}

				> [class*="lv"] {
					@include per( $property: 'margin', $val: 0 0 calcPer( $block70, 60, '%') );
					text-align: center;

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'margin', $val: 0 0 calcPer( $block70sp, 72, '%') );
					}
				}
			}
		}

		// 864px / 1440px
		&-60 {
			// @include per( $property: 'padding-bottom', $val: calcPer( 1440, 96, '%') );
			@include per( $property: 'margin', $val: calcPer( 1440, 96, '%') 0 );
			position: relative;

			@include mx-mq-max($bp-sp-max) {
				// @include per( $property: 'padding-bottom', $val: calcPer( $block100sp, 120, '%') );
				@include per( $property: 'margin', $val: calcPer( $block100sp, 120, '%') 0 );
			}

			> .block__inner {
				// background-color: #eeeeee;
				width: 60%;
				margin: 0 auto;

				@include mx-mq-max($bp-sp-max) {
					width: 84%;
				}

				> [class*="lv"] {
					@include per( $property: 'margin', $val: 0 0 calcPer( $block60, 36, '%') );
					@include lh( 42, 24 );
					text-align: center;

					@include mx-mq-max( $bp-sp-max ) {
						@include per( $property: 'margin-bottom', $val: calcPer( $block60sp, 72, '%') );
					}
				}

				> .lv3 {
					@include per( $property: 'margin-bottom', $val: calcPer( $block60, 60, '%') );
					@include lh( 42, 36 );

					@include mx-mq-max( $bp-sp-max ) {
						// @include per( $property: 'margin-bottom', $val: calcPer( $block60sp, 72, '%') );
					}
				}
			}

            // &.lead {
            //     // @include per( $property: 'padding-top', $val: calcPer( 1440, 72, '%') );
            //     @include per( $property: 'margin-top', $val: calcPer( 1440, 72, '%') );

			// 	@include mx-mq-max($bp-sp-max) {
			// 		// @include per( $property: 'padding-top', $val: calcPer( $block100sp, 96, '%') );
			// 		@include per( $property: 'margin-top', $val: calcPer( $block100sp, 96, '%') );
			// 	}
			// }
        }

		// &--space {

		// 	&--top {
		// 		@include per( $property: 'padding-top', $val: calcPer( 1440, 96, '%') );

		// 		@include mx-mq-max($bp-sp-max) {
		// 			@include per( $property: 'padding-top', $val: calcPer( $block100sp, 120, '%') );
		// 		}
		// 	}

		// 	&--bottom {
		// 		@include per( $property: 'padding-bottom', $val: calcPer( 1440, 96, '%') );

		// 		@include mx-mq-max($bp-sp-max) {
		// 			@include per( $property: 'padding-bottom', $val: calcPer( $block100sp, 120, '%') );
		// 		}
		// 	}
		// }

		// &--nospace {

		// 	&--top {
		// 		padding-top: 0;
		// 	}

		// 	&--bottom {
		// 		padding-bottom: 0;
		// 	}
		// }

		&--bg {
			background-color: #f8f8f8;
			// @include per( $property: 'padding-bottom', $val: calcPer( 1440, 96, '%') );
			@include per( $property: 'padding', $val: calcPer( 1440, 96, '%') 0 );

			@include mx-mq-max($bp-sp-max) {
				// @include per( $property: 'padding-bottom', $val: calcPer( $block100sp, 120, '%') );
				@include per( $property: 'padding', $val: calcPer( $block100sp, 120, '%') 0 );
			}
		}
	}

	&-news {

		.news {

			&__ttl {
				@include per( $property: 'margin', $val: 0 0 calcPer( $block80, 24, '%') );
				@include fs( 24 );
				// @include lh( 36, 24 );
				font-weight: bold;

				@include mx-mq-max($bp-sp-max) {
					@include fs( 20 );
					@include lh( 25, 20 );
					@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 36, '%') );
				}
			}

			&-list {
				@include per( $property: 'margin-bottom', $val: calcPer( $block80, 48, '%') );
				border-top: 1px solid #ddd;

				@include mx-mq-max($bp-sp-max) {
					@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 72, '%') );
				}

				&__unit {

					&:nth-child(n+6){
						display: none;

						// &.is-show {
						// 	display: flex;
						// }
					}
				}

				&__inner {
					display: flex;
					// @include per( $property: 'padding', $val: calcPer( $block80, 24, '%') 0 );
					@include per( $property: 'padding', $val: calcPerTypo( 16, 24, 'em') 0 );
					border-bottom: 1px solid #ddd;

					@include mx-mq-max($bp-sp-max) {
						flex-wrap: wrap;
						// @include per( $property: 'padding', $val: calcPer( $block80sp, 36, '%') 0 );
						@include per( $property: 'padding', $val: calcPerTypo( 16, 18, 'em') 0 );
					}

					dt {
						@include per( $property: 'width', $val: calcPer( $block80, 144, '%') );
						@include fs( 14 );
						// @include lh( 21, 14 );
						font-weight: bold;

						@include mx-mq-max($bp-sp-max) {
							// @include per( $property: 'width', $val: calcPer( $block80sp, 252, '%') );
							@include per( $property: 'width', $val: calcPerTypo( 14, 120, 'em') );
						}

						time {
							letter-spacing: normal;
						}
					}

					.category {
						width: 120px;
						@include fs( 12 );
						line-height: 1;
						color: $c-thm-01;

						@include mx-mq-max($bp-sp-max) {
							// @include per( $property: 'width', $val: calcPer( $block80sp, 200, '%') );
							@include per( $property: 'width', $val: calcPerTypo( 12, 100, 'em') );
							@include per( $property: 'margin-left', $val: calcPerTypo( 12, 12, 'em') );
							letter-spacing: normal;
						}

						span {
							display: block;
							@include per( $property: 'padding', $val: calcPerTypo( 12, 5, 'em') 0 );
							font-weight: 500;
							text-align: center;
							border: 1px solid currentColor;
						}
					}

					.title {
						flex: 1;
						@include per( $property: 'padding-left', $val: calcPer( $block80, 36, '%') );

						@include mx-mq-max($bp-sp-max) {
							flex: auto;
							width: 100%;
							padding-left: 0;
							// @include per( $property: 'margin-top', $val: calcPer( $block80sp, 24, '%') );
							@include per( $property: 'margin-top', $val: calcPerTypo( 12, 12, 'em') );
							@include fs( 12 );
							@include lh( 21, 12 );
						}
					}

					a {
						transition: all .9s $easing;

						&:hover {
							opacity: .6;
						}
					}
				}
			}
		}

		.link-btn {
			width: 240px;
			margin: 0 auto;

			// &.is-hidden {
			// 	display: none;
			// }
		}
	}

	&-contact {

		// .block__inner {
		// 	@include per( $property: 'padding-top', $val: calcPer( 1440, 95, '%') );
		// }

		.m-hdr {

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'width', $val: calcPer( 690, 630, '%') );
				@include per( $property: 'margin', $val: 0 auto calcPer( 690, 36, '%') );
			}

			.m-ttl {

				@include mx-mq-min-max($bp-sp-max, $bp-tb-max) {
					@include per( $property: 'margin-bottom', $val: calcPerTypo( 36, 12, 'em') );
					font-size: ( 36 / 14 ) + rem;
				}
			}

			.lead {

				@include mx-mq-min-max($bp-sp-max, $bp-tb-max) {
					font-size: ( 16 / 14 ) + rem;
				}
			}
		}

		.contact {

			&-list {
				display: flex;
				justify-content: center;
				@include per( $property: 'padding', $val: calcPer( $block90, 59, '%') 0 calcPer( $block90, 63, '%') );
				border: 1px solid #dddddd;

				@include mx-mq-max($bp-tb-max) {
					flex-direction: column;
					@include per( $property: 'padding', $val: 0 calcPer( 691.19, 38, '%') );
				}

				@include mx-mq-max($bp-sp-max) {
					@include per( $property: 'padding', $val: 0 calcPer( $block90sp, 28, '%') );
				}

				&__unit {
					width: 50%;

					@include mx-mq-max($bp-tb-max) {
						width: 100%;
						@include per( $property: 'padding', $val: calcPer( 613.22, 48, '%') 0 );
					}

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'padding', $val: calcPer( 630, 70, '%') 0 );
					}

					&:not(:last-child) {
						border-right: 1px solid #dddddd;

						@include mx-mq-max($bp-tb-max) {
							border-right: none;
							border-bottom: 1px solid #dddddd;
						}
					}

					dt {
						@include per( $property: 'margin-bottom', $val: calcPer( 647, 24, '%') );
						@include ff( noto );
						@include letter( 50 );
						font-weight: bold;

						@include mx-mq-min-max($bp-sp-max, $bp-tb-max) {
							@include per( $property: 'margin-bottom', $val: calcPerTypo( 16, 24, 'em') );
							font-size: ( 16 / 14 ) + rem;
						}

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'margin-bottom', $val: calcPer( 630, 24, '%') );
						}
					}

					dt, dd {
						text-align: center;
					}

					.btn {
						@include per( $property: 'width', $val: calcPer( 647, 400, '%') );
						margin: 0 auto;

						@include mx-mq-max($bp-tb-max) {
							@include per( $property: 'width', $val: calcPer( 613.22, 400, '%') );
						}

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'width', $val: calcPer( 630, 570, '%') );
						}
					}

					a {
						display: block;
						background-color: #222;
						@include per( $property: 'padding', $val: calcPerTypo( 16, 18, 'em') 0 );
						@include letter( 50 );
						color: #fff;
						font-weight: bold;
						transition: all .3s $easing;

						@include mx-mq-min-max($bp-sp-max, $bp-tb-max) {
							font-size: ( 16 / 14 ) + rem;
						}

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'padding', $val: calcPerTypo( 28, 28, 'em') );
						}

						&:hover {
							background-color: $c-thm-01;
						}
					}

					span {
						display: block;
						@include fs( 40 );
						@include lh( 48, 40 );
						font-weight: bold;
						letter-spacing: normal;

						@include mx-mq-min-max($bp-sp-max, $bp-tb-max) {
							font-size: ( 40 / 14 ) + rem;
						}

						@include mx-mq-max($bp-sp-max) {
							@include fs( 30 );
							@include lh( 37.5, 30 );
							@include letter( 50 );
						}
					}

					small {
						display: block;
						font-weight: bold;
						@include fs( 14 );
						@include letter( 50 );

						@include mx-mq-min-max($bp-sp-max, $bp-tb-max) {
							font-size: ( 14 / 14 ) + rem;
						}

						@include mx-mq-max($bp-sp-max) {
							@include fs( 12 );
							@include lh( 21, 12 );
						}
					}
				}
			}
		}
	}

	&-banner {
		background-color: #f8f8f8;
		@include per( $property: 'padding', $val: calcPer( 1440, 72, '%') 0 );

		@include mx-mq-max($bp-sp-max) {
			@include per( $property: 'padding', $val: calcPer( $block100sp, 96, '%') 0 );
		}

		.banner {

			&__inner {
				@include per( $property: 'width', $val: calcPer( 1440, $block90, '%') );
				margin: 0 auto;

				@include mx-mq-max($bp-sp-max) {
					width: 100%;
				}
			}

			&-list {

				&:not(.slick-slider) {
					display: flex;
				}

				&.slick-slider {
					@include per( $property: 'margin', $val: 0 calcPer( $block90, -12, '%') );

					.slick-slide {
						@include per( $property: 'width', $val: 0 calcPer( $block100sp, 480, '%') );
						@include per( $property: 'padding', $val: 0 calcPer( 100, 0.8333333, 'vw') );

						@include mx-mq-max($bp-sp-max) {
							width: 69.333vw;
							padding: 0 2.667vw;
						}
					}
				}

				&__unit {
					flex: 1;
					@include per( $property: 'width', $val: calcPer( $block90, 306, '%') );

					&:not(:nth-child( -n + 1 )) {
						@include per( $property: 'margin-left', $val: calcPer( $block90, 24, '%') );
					}
				}

				a {
					display: block;

					&:hover {

						dt, picture {

							&:after {
								opacity: .4;
							}
						}

						dd {
							opacity: .6;
						}
					}
				}

				dt, picture {
					position: relative;

					&:after {
						display: block;
						background-color: #fff;
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						z-index: 50;
						opacity: 0;
						content: '';
						transition: all .3s $easing;
					}
				}

				dt {
					@include per( $property: 'margin-bottom', $val: calcPer( 306, 12, '%') );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'margin-bottom', $val: calcPer( 480, 24, '%') );
					}
				}

                img {
					width: 100%;
                }

				dd {
					@include fs( 14 );
					transition: all .3s $easing;

					@include mx-mq-max($bp-sp-max) {
						@include fs( 12 );
						@include lh( 21, 12 );
					}
				}
			}
		}

		&--en {

			.banner {

				&__inner {

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'width', $val: calcPer( $block100, $block90, '%') )
						margin: 0 auto;
					}
				}
			}
		}
	}

	&-card-list {

		&--3col {

			.card-list {

				&__unit {
					@include per( $property: 'width', $val: calcPer( $block90, 416, '%') );

					@include mx-mq-max($bp-sp-max) {
						width: 100%;
					}

					&:not(:nth-child(3n+3)) {
						@include per( $property: 'margin-right', $val: calcPer( $block90, 24, '%') );

						@include mx-mq-max($bp-sp-max) {
							margin-right: 0;
							@include per( $property: 'margin-bottom', $val: calcPer( $block90sp, 24, '%') );
						}
					}

					&:not(:nth-last-child(-n+3)) {
						@include per( $property: 'margin-bottom', $val: calcPer( $block90, 24, '%') );
					}

					.image {
						@include per( $property: 'padding-bottom', $val: calcPer( 416, 207, '%') );
					}
				}

				&__text {
					@include per( $property: 'padding', $val: calcPer( 416, 36, '%') calcPer( 416, 36, '%') calcPer( 416, 48, '%') );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'padding', $val: calcPer( 690, 48, '%') );
					}

					.type {
						@include per( $property: 'margin-bottom', $val: calcPer( 343, 6, '%') );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'margin-bottom', $val: calcPer( 594, 12, '%') );
						}
					}

					.lv6 {
						@include per( $property: 'margin', $val: 0 0 calcPer( 343, 6, '%') );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'margin-bottom', $val: calcPer( 594, 12, '%') );
						}
					}

					.description {
						@include per( $property: 'margin', $val: 0 0 calcPer( 343, 12, '%') );
						@include fs( 14 );
						@include letter( 50 );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'margin-bottom', $val: calcPer( 594, 24, '%') );
							@include fs( 12 );
						}
					}
				}
			}
		}

		&--4col {
			// @include per( $property: 'margin-bottom', $val: calcPer( $block90, -38, '%') );

			.card-list {

				&__unit {
					@include per( $property: 'width', $val: calcPer( $block90, 306, '%') );
					@include per( $property: 'margin-bottom', $val: calcPer( $block90, 38, '%') );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'width', $val: calcPer( $block90sp, 330, '%') );
						@include per( $property: 'margin-bottom', $val: calcPer( $block90sp, 30, '%') );
					}

					// &:not(:nth-last-child( -n + 4 ) ) {
					// 	@include per( $property: 'margin-bottom', $val: calcPer( $block90, 38, '%') );
					// }

					&:not(:nth-child( 4n + 4 )) {
						@include per( $property: 'margin-right', $val: calcPer( $block90, 24, '%') );

						@include mx-mq-max($bp-sp-max) {
							margin-right: 0;
						}
					}

					&:not(:nth-child( 2n + 2 )) {

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'margin-right', $val: calcPer( $block90sp, 30, '%') );
						}
					}

					.image {
						@include per( $property: 'padding-bottom', $val: calcPer( 306, 204, '%') );
					}

					&:nth-child(4n+1):nth-last-child(-n+4) {

						@include mx-mq-min($bp-sp-max, 'gt') {
							margin-bottom: 0;
						}

						& ~ .card-list__unit {

							@include mx-mq-min($bp-sp-max, 'gt') {
								margin-bottom: 0;
							}
						}
					}

					&:nth-child(2n+1):nth-last-child(-n+2) {

						@include mx-mq-max($bp-sp-max) {
							margin-bottom: 0;
						}

						& ~ .card-list__unit {

							@include mx-mq-max($bp-sp-max) {
								margin-bottom: 0;
							}
						}
					}
				}

				&__text {
					@include per( $property: 'padding', $val: calcPer( 306, 36, '%') calcPer( 306, 30, '%') );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'padding', $val: calcPer( 330, 36, '%') calcPer( 330, 30, '%') );
					}

					// .type {
					// 	@include per( $property: 'margin-bottom', $val: calcPer( 246, 6, '%') );
					// }

					.lv6 {
						@include per( $property: 'margin', $val: 0 0 calcPer( 246, 8, '%') );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'margin', $val: 0 0 calcPer( 272, 12, '%') );
						}
					}

					.description {
						@include per( $property: 'margin', $val: 0 0 calcPer( 246, 8, '%') );
						@include fs( 14 );
						@include letter( 50 );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'margin', $val: 0 0 calcPer( 272, 12, '%') );
							@include fs( 10 );
							@include lh( 18, 10 );
						}
					}
				}
			}
		}

		&--4col--simple {
			@include per( $property: 'width', $val: calcPer( $block90, 1152, '%') );
			margin: 0 auto;

			@include mx-mq-max($bp-sp-max) {
				width: 100%;
			}

			.card-list {

				// &__container {
				// 	@include per( $property: 'padding-top', $val: calcPer( $block80, 60, '%') );

				// 	@include mx-mq-max($bp-sp-max) {
				// 		@include per( $property: 'padding-top', $val: calcPer( 690, 96, '%') );
				// 	}
				// }

				&__unit {
					@include per( $property: 'width', $val: calcPer( $block80, 270, '%') );
					@include per( $property: 'margin-bottom', $val: calcPer( $block80, 24, '%') );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'width', $val: calcPer( $block90sp, 330, '%') );
						@include per( $property: 'margin-bottom', $val: calcPer( $block90sp, 30, '%') );
					}

					&:not(:nth-child( 4n + 4 )) {
						@include per( $property: 'margin-right', $val: calcPer( $block80, 24, '%') );

						@include mx-mq-max($bp-sp-max) {
							margin-right: 0;
						}
					}

					&:not(:nth-child( 2n + 2 )) {

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'margin-right', $val: calcPer( $block90sp, 30, '%') );
						}
					}

					&:nth-last-child(-n+2) {

						@include mx-mq-max($bp-sp-max) {
							margin-bottom: 0;
						}
					}

					.image {
						@include per( $property: 'padding-bottom', $val: calcPer( 306, 204, '%') );
					}

					&:nth-child(4n+1):nth-last-child(-n+4) {

						@include mx-mq-min($bp-sp-max, 'gt') {
							margin-bottom: 0;
						}

						& ~ .card-list__unit {

							@include mx-mq-min($bp-sp-max, 'gt') {
								margin-bottom: 0;
							}
						}
					}

					&:nth-child(2n+1):nth-last-child(-n+2) {

						@include mx-mq-max($bp-sp-max) {
							margin-bottom: 0;
						}

						& ~ .card-list__unit {

							@include mx-mq-max($bp-sp-max) {
								margin-bottom: 0;
							}
						}
					}
				}

				&__text {
					@include per( $property: 'padding', $val: calcPer( 270, 36, '%') calcPer( 270, 24, '%') );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'padding', $val: calcPer( 330, 36, '%') calcPer( 330, 30, '%') );
					}

					.lv6 {
						@include per( $property: 'margin', $val: 0 0 calcPer( 222, 12, '%') );
						text-align: left;
						@include lh( 24, 16 );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'margin', $val: 0 0 calcPer( 272, 12, '%') );
						}
					}
				}
			}
		}

		> [class^="lv"] {
			@include per( $property: 'margin', $val: 0 0 calcPer( $block90, 48, '%') );
			@include lh( 31.5, 18 );
			text-align: center;
			@include letter( 50 );

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'margin', $val: 0 0 calcPer( $block90sp, 48, '%') );
			}
		}

		.card-list {

			&__container {
				display: flex;
				flex-wrap: wrap;
			}

			&__unit {

                .cp-block--bg & {
                    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);

					@include mx-mq-max($bp-sp-max) {
						box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
					}
				}

				a {
					display: block;
					height: 100%;

					.image {

						&:after {
							display: block;
							background-color: #fff;
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							z-index: 50;
							opacity: 0;
							content: '';
							transition: all .3s $easing;
						}

						img {
							transition: all .6s $easing;
						}
					}

					&:hover {

						.image {

							&:after {
								opacity: .4;
							}

							img {
								transform: scale(1.05);
							}
						}

						.card-list__text {
							opacity: .6;
						}
					}
				}
			}

			&__inner {
                background-color: #f4f4f4;

                .cp-block--bg & {
					background-color: #fff;
				}
			}

			&__text {
				transition: all .3s $easing;

				.type {
					// @include fs( 14 );
					color: $c-thm-01;
					font-weight: bold;
				}
			}
		}

		.card-list {

			&__container {

				.slick-arrow {
					@include per( $property: 'margin-top', $val: calcPer( 1320, -21, '%') );
				}

				&.slick-slider {
					// @include per( $property: 'padding-top', $val: calcPer( $block90, 44, '%') );
					// @include per( $property: 'margin', $val: calcPer( $block90, -44, '%') calcPer( 100, -0.8333333, 'vw') 0 );
					// @include per( $property: 'margin', $val: calcPer( $block90, -44, '%') calcPer( $block90, -12, '%') 0 );
					@include per( $property: 'margin', $val: 0 calcPer( $block90, -12, '%') );

					@include mx-mq-max($bp-sp-max) {
						// @include per( $property: 'margin', $val: calcPer( $block100sp, -32, '%') calcPer( $block100sp, -30, '%') 0 );
						@include per( $property: 'margin', $val: 0 calcPer( $block100sp, -30, '%') );
					}

					.slick-list {
						width: 100%;
						@include per( $property: 'padding', $val: calcPer( 1320, 8, '%') 0 );
						@include per( $property: 'margin-top', $val: calcPer( 1320, -8, '%') );

						@include mx-mq-max($bp-sp-max) {
							padding: 2.1333333% 0 !important;
							margin-top: -2.1333333%;
						}
					}

					.slick-slide {
						// width: 33.333%;
						@include per( $property: 'padding', $val: 0 calcPer( 100, 0.8333333, 'vw') );

						@include mx-mq-max($bp-sp-max) {
							width: 80vw;
							padding: 0 2vw;
						}

						.card-list__unit {
							margin-bottom: 0;
						}
					}
				}
			}
		}

		.link-btn {
			width: 360px;
			@include per( $property: 'padding-top', $val: calcPer( $block90, 72, '%') );
			margin: 0 auto;

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'padding-top', $val: calcPer( $block90sp, 72, '%') );
			}
		}
	}

    &-horizontally-card-list {

		.horizontally-card-list {

			&__container {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				& + .link-btn {
					@include per( $property: 'width', $val: calcPer( $block90sp, 570, '%') );
					@include per( $property: 'margin', $val: calcPer( $block90sp, 30, '%') auto 0 );
					// transition: all .3s $easing;

					a {
						@include fs( 16 );
					}

					// &.is-hidden {
					// 	display: none;
					// 	// visibility: hidden;
					// 	// opacity: 0;
					// 	// animation: fade-hidden 1s linear 0s forwards;
					// }
				}
			}

            &__unit {
                @include per( $property: 'width', $val: calcPer( $block90, 636, '%') );

				@include mx-mq-max($bp-sp-max) {
					width: 100%;
					transition: all .6s $easing;
				}

				&:not(:nth-last-child(-n+2)) {
					@include per( $property: 'margin-bottom', $val: calcPer( $block90, 24, '%') );
				}

				&:not(:last-child ) {

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'margin-bottom', $val: calcPer( $block90sp, 30, '%') );
					}
				}

				&:nth-child( n + 5 ) {

					@include mx-mq-max($bp-sp-max) {
						display: none;
						opacity: 0;
					}
				}

                a {
                    display: flex;
					align-items: center;
					background-color: #f8f8f8;
					height: 100%;
					@include per( $property: 'padding', $val: calcPer( 636, 24, '%') );

					@include mx-mq-max($bp-sp-max) {
						align-items: flex-start;
						@include per( $property: 'padding', $val: calcPer( $block90sp, 30, '%') );
					}

					&:hover {

						.image, .text {
							opacity: .6;
						}
					}
				}

				.image, .text {
					transition: all .3s $easing;
				}

                .image {
                    @include per( $property: 'width', $val: calcPer( 588, 168, '%') );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'width', $val: calcPer( 630, 144, '%') );
					}
				}

                .text {
                    flex: 1;
                    @include per( $property: 'padding-left', $val: calcPer( 588, 24, '%') );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'padding-left', $val: calcPer( 630, 30, '%') );
					}
				}

                [class*="lv"] {
                    @include per( $property: 'margin', $val: 0 0 calcPer( 396, 6, '%') );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'margin', $val: 0 0 calcPer( 456, 12, '%') );
					}
				}

				.icon {
					display: block;

					span {
						display: inline-block;
						@include per( $property: 'padding', $val: calcPerTypo( 12, 5, 'em') calcPerTypo( 12, 17, 'em') );
						@include per( $property: 'margin-bottom', $val: calcPerTypo( 12, 12, 'em') );
						@include fs( 12 );
						@include lh( 18, 12 );
						@include letter( 50 );
						font-weight: bold;
						border: 1px solid currentColor;
						@include per( $property: 'border-radius', $val: calcPerTypo( 12, 15, 'em') );
					}

					&--ifound {
						color: $c-thm-01;
					}

					&--callout {
						color: $c-act-05;
					}
				}

                .description {
                    @include per( $property: 'margin', $val: 0 0 calcPer( 396, 12, '%') );
                    @include fs( 12 );
                    @include letter( 50 );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'margin', $val: 0 0 calcPer( 456, 24, '%') );
						@include fs( 10 );
					}
				}

				// &.is-show {
				// 	// display: block;
				// 	// opacity: 1;
				// 	animation: fade-show .3s linear 0s forwards;
				// }
            }
		}
    }

	&-cnt-list {

		> [class^="lv"] {
			@include per( $property: 'margin', $val: 0 0 calcPer( $block90, 48, '%') );
			text-align: center;
			@include lh( 31.5, 18 );
			@include letter( 50 );

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'margin', $val: 0 0 calcPer( $block90sp, 48, '%') );
			}
		}

		.cnt-list {

			&__container {
				display: flex;
				flex-wrap: wrap;
                text-align: center;

                &--space--bottom {
                    @include per( $property: 'margin-bottom', $val: calcPer( $block90, 72, '%') );

					@include mx-mq-max($bp-sp-max) {
                  	  @include per( $property: 'margin-bottom', $val: calcPer( $block90sp, 72, '%') );
					}
				}
			}

			&__unit {

				@include mx-mq-max($bp-sp-max) {
					@include per( $property: 'padding', $val: calcPer( $block90sp, 60, '%') 0 );
				}

				a {
					display: block;

					&:hover {

						.icon {
							opacity: .5;
						}

						.cnt-list__text {
							opacity: .6;
						}
					}
				}

                .cp-block-90 & {

                    &:not(:last-child) {
                        position: relative;

                        &:after {
                            display: block;
                            background-color: #eee;
                            width: 1px;
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            content: '';
						}
					}

					&:nth-child( 2n + 1 ) {

						@include mx-mq-max($bp-sp-max) {
							border-bottom: 1px solid #eee;
						}

						.cnt-list__text {

							@include mx-mq-max($bp-sp-max) {
								@include per( $property: 'padding', $val: 0 calcPer( 345, 44, '%') 0 calcPer( 345, 28, '%') );
							}
						}
					}

					&:nth-child( 2n + 2 ) {

						@include mx-mq-max($bp-sp-max) {
							border-bottom: 1px solid #eee;

							&:after {
								display: none;
							}
						}

						.cnt-list__text {

							@include mx-mq-max($bp-sp-max) {
								@include per( $property: 'padding', $val: 0 calcPer( 345, 28, '%') 0 calcPer( 345, 44, '%') );
							}
						}
					}
                }

                .cp-block-80 & {

                    &:nth-child( 3n + 2 ) {
                        position: relative;

                        &:before, &:after {
                            display: block;
                            background-color: #eee;
                            width: 1px;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            content: '';

							@include mx-mq-max($bp-sp-max) {
								display: none;
							}
						}

                        &:before {
                             @include per( $property: 'left', $val: calcPer( 320, -48, '%') );
                        }

                        &:after {
                             @include per( $property: 'right', $val: calcPer( 320, -48, '%') );
                        }
                    }
                }

				.icon {
					transition: all .3s $easing;

                    .cp-block-90 & {
                        @include per( $property: 'width', $val: calcPer( 324, 96, '%') );
                        @include per( $property: 'margin', $val: 0 auto calcPer( 324, 24, '%') );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'width', $val: calcPer( 345, 128, '%') );
							@include per( $property: 'margin', $val: 0 auto calcPer( 345, 24, '%') );
						}
					}

                    .cp-block-80 & {
                        @include per( $property: 'width', $val: calcPer( 320, 96, '%') );
                        @include per( $property: 'margin', $val: 0 auto calcPer( 320, 24, '%') );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'width', $val: calcPer( 630, 128, '%') );
							@include per( $property: 'margin', $val: 0 auto calcPer( 630, 24, '%') );
						}
					}
				}
			}

			&__text {
				transition: all .3s $easing;

                .cp-block-90 & {
                    @include per( $property: 'padding', $val: 0 calcPer( 324, 41, '%') );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'padding', $val: 0 calcPer( 345, 44, '%') );
					}

                    .lv6 {
                        @include per( $property: 'margin', $val: 0 0 calcPer( 242, 12, '%') );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'margin', $val: 0 auto calcPer( 272, 12, '%') );
						}
					}

                    .description {
                        @include per( $property: 'margin', $val: 0 0 calcPer( 242, 8, '%') );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'margin', $val: 0 auto calcPer( 272, 12, '%') );
							text-align: center;
						}
					}
                }

                .cp-block-80 & {
                    // @include per( $property: 'padding', $val: 0 calcPer( 320, 41, '%') );

                    .lv6 {
                        @include per( $property: 'margin', $val: 0 0 calcPer( 320, 12, '%') );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 36, '%') );
							@include fs( 16 );
						}
					}

                    .description {
                        @include per( $property: 'margin', $val: 0 0 calcPer( 320, 8, '%') );
                    }
                }

                .description {
					// @include fs( 14 );
					@include lh( 28, 16 );
                    @include letter( 50 );

					@include mx-mq-max($bp-sp-max) {
						@include lh( 24.5, 14 );
					}
				}
            }
        }

		&--4col {

			.cnt-list {

				&__container {

					@include mx-mq-max($bp-sp-max) {
						border-top: 1px solid #eee;
					}
				}

				&__unit {

                    .cp-block-90 & {
                        @include per( $property: 'width', $val: calcPer( $block90, 324, '%') );

						@include mx-mq-max($bp-sp-max) {
							width: 50%;
						}
					}
				}
			}
		}

		&--3col {

			.cnt-list {

				// &__container {
				// }

				&__unit {
                    // width: 33.333%;

                    .cp-block-80 & {
						@include per( $property: 'width', $val: calcPer( $block80, 320, '%') );

						@include mx-mq-max($bp-sp-max) {
							width: 100%;
							padding: 0;

							&:not(:last-child) {
								@include per( $property: 'padding', $val: 0 0 calcPer( $block80sp, 72, '%') );
								@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 72, '%') );
								border-bottom: 1px solid #eee;
							}
						}
					}

					.description {
						text-align: left;
					}
				}
			}
		}

        &--b {

            .cnt-list {

                &__unit {

                    .icon {

                        .cp-block-80 & {
                            @include per( $property: 'width', $val: calcPer( 320, 64, '%') );
                            @include per( $property: 'margin', $val: 0 auto calcPer( 320, 12, '%') );

							@include mx-mq-max($bp-sp-max) {
								@include per( $property: 'width', $val: calcPer( 630, 128, '%') );
								@include per( $property: 'margin', $val: 0 auto calcPer( 630, 24, '%') );
							}
						}
                    }

                    &:nth-child( 3n + 2 ) {

                        .cp-block-80 & {
                            @include per( $property: 'margin', $val: 0 calcPer( $block80, 96, '%') );

							@include mx-mq-max($bp-sp-max) {
								@include per( $property: 'margin', $val: 0 auto calcPer( 630, 72, '%') );
							}
						}
					}
                }

                &__text {

                    .cp-block-80 & {
                        // @include per( $property: 'padding', $val: 0 calcPer( 320, 47, '%') 0 calcPer( 320, 48, '%') );

                        .lv6 {
                            @include per( $property: 'margin', $val: 0 0 calcPer( 320, 24, '%') );
                            color: $c-thm-01;

							@include mx-mq-max($bp-sp-max) {
								@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 36, '%') );
								@include fs( 16 );
							}
						}

                        .description {
                            margin: 0;
                        }
                    }
                }
            }
        }

		.link-btn {
			width: 360px;
			margin: 0 auto;
		}
	}

	&-service-list {

		> [class^="lv"] {
			// margin: 0;
			@include per( $property: 'margin', $val: 0 0 calcPer( $block80, 48, '%') );
			text-align: center;
			@include lh( 31.5, 18 );
			@include letter( 50 );

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'margin', $val: 0 0 calcPer( $block80sp, 48, '%') );
			}
		}

		.service-list {

			&__container {
				display: flex;
				flex-wrap: wrap;
                text-align: center;

				// @include mx-mq-max($bp-sp-max) {
				// 	@include per( $property: 'width', $val: calcPer( 345, 315, '%') );
				// 	margin: 0 auto;
				// }
			}

			&__unit {
				@include per( $property: 'width', $val: calcPer( $block80, 320, '%') );
				@include per( $property: 'margin', $val: 0 calcPer( $block80, 48, '%') calcPer( $block80, 96, '%') );
				position: relative;

				@include mx-mq-max($bp-sp-max) {
					@include per( $property: 'width', $val: calcPer( $block80sp, 285, '%') );
					@include per( $property: 'margin', $val: 0 calcPer( $block80sp, 60, '%') calcPer( $block80sp, 120, '%') 0 );
				}

				&:before, &:after {
					display: block;
					background-color: #eee;
					position: absolute;
					content: '';
				}

				&:before {
					width: 100%;
					height: 1px;
					@include per( $property: 'margin-top', $val: calcPer( 320, 48, '%') );
					top: 100%;
					left: 0;

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'margin-top', $val: calcPer( 285, 60, '%') );
					}
				}

				&:after {
					width: 1px;
					height: 100%;
					top: 0;
					@include per( $property: 'right', $val: calcPer( 320, -48, '%') );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'right', $val: calcPer( 285, -30, '%') );
					}
				}

				&:nth-child(3n+1) {
					margin-left: 0;
				}

				&:nth-child(3n+3) {
					margin-right: 0;

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'margin-right', $val: calcPer( $block80sp, 60, '%') );
					}

					&:after {

						@include mx-mq-min($bp-sp-max,'gt') {
							display: none;
						}
					}
				}

				&:nth-child(3n+1):nth-last-child(-n+3),
				&:nth-child(3n+1):nth-last-child(-n+3) ~ .service-list__unit {

					@include mx-mq-min($bp-sp-max,'gt') {
						margin-bottom: 0;

						&:before {
							display: none;
						}
					}
				}

				&:nth-child(-n+3) {
					margin-top: 0;

					// @include mx-mq-max($bp-sp-max) {
					// 	@include per( $property: 'margin-top', $val: calcPer( $block80sp, 120, '%') );
					// }
				}

				&:nth-child(-n+2) {

					@include mx-mq-max($bp-sp-max) {
						margin-top: 0;
					}
				}

				&:nth-child(2n+2) {

					@include mx-mq-max($bp-sp-max) {
						margin-right: 0;

						&:after {
							display: none;
						}
					}
				}

				&:nth-child(2n+1):nth-last-child(-n+2),
				&:nth-child(2n+1):nth-last-child(-n+2) ~ .service-list__unit {

					@include mx-mq-max($bp-sp-max) {
						margin-bottom: 0;

						&:before {
							display: none;
						}
					}
				}

				a {
					display: block;

					&:hover {

						.lv6 {
							opacity: .6;
						}

						.icon {
							opacity: .5;
						}

						.service-list__text {
							opacity: .6;
						}
					}
				}

				.icon {
					@include per( $property: 'width', $val: calcPer( 384, 96, '%') );
					@include per( $property: 'margin', $val: 0 auto calcPer( 384, 12, '%') );
					transition: all .3s $easing;

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'width', $val: calcPer( 285, 128, '%') );
						@include per( $property: 'margin', $val: 0 auto calcPer( 285, 24, '%') );
					}
				}

				.lv6 {
					@include per( $property: 'margin', $val: 0 0 calcPerTypo( 16, 12, 'em') );
					color: $c-thm-01;
					transition: all .3s $easing;

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'margin', $val: 0 auto calcPer( 272, 12, '%') );
					}
				}
			}

			&__text {
				transition: all .3s $easing;

				.name {
					@include per( $property: 'margin', $val: 0 0 calcPerTypo( 14, 12, 'em') );
					@include fs( 14 );
					@include letter( 50 );
					font-weight: bold;

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'margin', $val: 0 auto calcPer( 272, 12, '%') );
						@include fs( 12 );
						@include lh( 18, 12 );
						text-align: center;
					}
				}
            }
		}

		.link-btn {
			width: 360px;
			@include per( $property: 'padding-top', $val: calcPer( $block80, 72, '%') );
			margin: 0 auto;

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'padding-top', $val: calcPer( $block80sp, 72, '%') );
			}
		}
	}

	&-gallery {
		text-align: center;

		> [class*="lv"] {
			@include per( $property: 'margin', $val: 0 0 calcPer( $block90, 36, '%') );

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'margin-bottom', $val: calcPer( $block90sp, 48, '%') );
			}
		}

		&:not(:last-of-type()) {
			@include per( $property: 'margin', $val: 0 0 calcPer( $block90, 60, '%') );

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'margin-bottom', $val: calcPer( $block90sp, 72, '%') );
			}
		}

		.gallery-list {
			display: flex;
			flex-wrap: wrap;
			list-style: none;
			@include per( $property: 'padding', $val: calcPer( $block90, 36, '%') calcPer( $block90, 60, '%') calcPer( $block90, 48, '%') );
			box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'padding', $val: calcPer( $block90sp, 48, '%') calcPer( $block90sp, 30, '%') );
				box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
			}

			&__unit {
				@include per( $property: 'width', $val: calcPer( 1176, 216, '%') );

				a {
					display: block;

					&:not([href="#"]) {
						transition: all .3s $easing;

						&:hover {
							opacity: .6;
						}
					}

					&[href="#"] {
						pointer-events: none;
					}
				}

				&:not(:nth-child( 5n + 5 )) {
					@include per( $property: 'margin-right', $val: calcPer( 1176, 24, '%') );

					@include mx-mq-max($bp-sp-max) {
						margin-right: 0;
					}
				}

				&:not(:nth-last-child( -n + 5 )) {
					@include per( $property: 'margin-bottom', $val: calcPer( 1176, 24, '%') );

					@include mx-mq-max($bp-sp-max) {
						margin-bottom: 0;
					}
				}
			}

			&--spcol3 {

				.gallery-list {

					&__unit {

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'width', $val: calcPer( 630, 190, '%') );
						}

						&:not(:nth-child( 3n + 3 )) {

							@include mx-mq-max($bp-sp-max) {
								@include per( $property: 'margin-right', $val: calcPer( 630, 30, '%') );
							}
						}

						&:not(:nth-last-child( -n + 3 )) {

							@include mx-mq-max($bp-sp-max) {
								@include per( $property: 'margin-bottom', $val: calcPer( 630, 30, '%') );
							}
						}
					}
				}
			}

			&--spcol2 {

				.gallery-list {

					&__unit {

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'width', $val: calcPer( 630, 300, '%') );
						}

						&:not(:nth-child( 2n + 2 )) {

							@include mx-mq-max($bp-sp-max) {
								@include per( $property: 'margin-right', $val: calcPer( 630, 30, '%') );
							}
						}

						&:not(:nth-last-child( -n + 2 )) {

							@include mx-mq-max($bp-sp-max) {
								@include per( $property: 'margin-bottom', $val: calcPer( 630, 30, '%') );
							}
						}
					}
				}
			}
		}

		&--logo {

			.gallery-list {
				@include per( $property: 'padding', $val: calcPer( $block90, 60, '%') );
			}
		}

		.intro {
			text-align: center;

			dt {
				@include per( $property: 'margin-bottom', $val: calcPer( 240, 12, '%') );
				@include fs( 14 );
				@include letter( 50 );
				font-weight: bold;

				@include mx-mq-max($bp-sp-max) {
					@include per( $property: 'margin-bottom', $val: calcPer( 300, 12, '%') );
				}
			}

			dd {
				@include fs( 12 );
				@include letter( 50 );
			}
		}

		.note {
			display: block;
			@include per( $property: 'margin', $val: calcPer( $block90, 12, '%') 0 0 );
			@include fs( 12 );
			@include letter( 50 );
			color: #888888;
			text-align: right;

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'margin-top', $val: calcPer( $block90sp, 24, '%') );
				@include lh( 21, 12 );
			}
		}
	}

	&-table {

		> [class*="lv"] {
			@include per( $property: 'margin', $val: 0 0 calcPer( $block80, 36, '%') );
			@include lh( 31.5, 18 );
			text-align: center;

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 48, '%') );
				@include lh( 24, 16 );
			}
		}

		.table {

			&__container {
				border-top: 1px solid #eeeeee;
			}

			&__block {
				display: flex;
				// @include per( $property: 'padding', $val: calcPer( $block80, 30, '%') 0 );
				@include per( $property: 'padding', $val: calcPerTypo( 14, 30, 'em') 0 );
				@include fs( 14 );
				@include lh( 24.5, 14 );
				@include letter( 50 );
				border-bottom: 1px solid #eeeeee;

				@include mx-mq-max( $bp-sp-max ) {
					flex-direction: column;
					// @include per( $property: 'padding', $val: calcPer( $block80sp, 60, '%') 0 );
					// @include per( $property: 'padding', $val: calcPerTypo( 14, 30, 'em') 0 );
				}

				.cp-text-btn {
					display: flex;
					justify-content: space-between;
					align-items: center;
					background-color: #f8f8f8;
					// @include per( $property: 'padding', $val: calcPer( $block60, 20, '%') );
					@include per( $property: 'padding', $val: calcPerTypo( 14, 24, 'em') );

					@include mx-mq-max( $bp-sp-max ) {
						flex-direction: column;
						align-items: flex-start;
						// @include per( $property: 'padding', $val: calcPer( $block80sp, 48, '%') calcPer( $block80sp, 26, '%') );
						@include per( $property: 'padding', $val: calcPerTypo( 14, 24, 'em') calcPerTypo( 14, 15, 'em') );
					}

					&:not(:last-of-type) {
						@include per( $property: 'margin-bottom', $val: calcPer( $block60, 12, '%') );

						@include mx-mq-max( $bp-sp-max ) {
							@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 24, '%') );
						}
					}

					dt {
						@include per( $property: 'padding-right', $val: calcPer( $block60, 20, '%') );
						@include fs( 14 );
						@include lh( 24.5, 14 );
						@include letter( 50 );
						font-weight: bold;

						@include mx-mq-max( $bp-sp-max ) {
							width: 100%;
							padding-right: 0;
							// @include per( $property: 'margin-bottom', $val: calcPer( $block80sp - 56, 24, '%') );
							@include per( $property: 'margin-bottom', $val: calcPerTypo( 14, 12, 'em') );
						}
					}

					dd {
						// flex: 1;
						flex: 0 0 auto;

						@include mx-mq-max( $bp-sp-max ) {
							width: 100%;
						}

						// .link-btn {
						// 	width: 202px;
						// }
					}
				}

				.cp-group-system {

					.group-system {

						&__parent {
							background-color: #fff;
							position: relative;
							z-index: 100;

							p {
								@include per( $property: 'padding', $val: calcPerTypo( 14, 34, 'em') calcPerTypo( 14, 22, 'em') );
								margin: 0;
								@include fs( 14 );
								@include lh( 24.5, 14 );
								@include letter( 50 );
								font-weight: bold;
								text-align: center;
								border: 2px solid #dddddd;

								@include mx-mq-max($bp-sp-max) {
									@include per( $property: 'padding', $val: calcPerTypo( 12, 22, 'em') calcPerTypo( 12, 13, 'em') calcPerTypo( 12, 22, 'em') calcPerTypo( 12, 27, 'em') );
									@include fs( 12 );
									text-align: left;
								}
							}
						}

						&__children {
							display: flex;
							justify-content: space-between;
							// align-items: center;
							@include per( $property: 'padding-top', $val: calcPer( $block60, 18, '%') );
							list-style: none;

							@include mx-mq-max($bp-sp-max) {
								flex-direction: column;
								@include per( $property: 'padding', $val: 0 0 0 calcPer( $block60sp, 70, '%') );
							}

							&__unit {
								@include per( $property: 'width', $val: calcPer( $block60, 272, '%') );
								@include per( $property: 'padding', $val: calcPerTypo( 14, 34, 'em') calcPerTypo( 14, 22, 'em') );
								@include fs( 14 );
								@include lh( 24.5, 14 );
								@include letter( 50 );
								text-align: center;
								font-weight: bold;
								border: 2px solid #dddddd;
								position: relative;

								@include mx-mq-max($bp-sp-max) {
									width: 100%;
									@include per( $property: 'margin-top', $val: calcPer( $block60sp - 70, 24, '%') );
									@include fs( 12 );
									@include lh( 21, 12 );
									text-align: left;
								}

								&:before {
									display: block;
									background-color: #ddd;
									@include per( $property: 'width', $val: calcPer( 272, 12, '%') );
									@include per( $property: 'padding-bottom', $val: calcPer( 272, 18, '%') );
									margin-bottom: 2px;
									position: absolute;
									bottom: 100%;
									left: 50%;
									transform: translateX(-50%);
									content: '';

									@include mx-mq-max($bp-sp-max) {
										@include per( $property: 'width', $val: calcPer( 560 - 8, 44, '%') );
										@include per( $property: 'padding-bottom', $val: calcPer( 560 - 8, 18, '%') );
										margin: 0 2px 0 0;
										bottom: 50%;
										left: 0;
										transform: translate(-100%, 50%);
									}
								}

								&:after {

									@include mx-mq-max($bp-sp-max) {
										display: block;
										background-color: #ddd;
										@include per( $property: 'width', $val: calcPer( 560 - 8, 18, '%') );
										height: calc( 100% + 4px );
										@include per( $property: 'padding-top', $val: calcPer( 560 - 8, 24, '%') );
										margin-top: 2px;
										position: absolute;
										bottom: 50%;
										@include per( $property: 'left', $val: calcPer( 560 - 8, -26, '%') );
										transform: translate(-100%, 0);
										box-sizing: initial;
										content: '';
									}
								}
							}

							span {
								display: flex;
								align-items: center;
								justify-content: center;
								height: 100%;
								text-align: center;

								@include mx-mq-max($bp-sp-max) {
									justify-content: flex-start;
									text-align: left;
								}
							}
						}
					}
				}

				.cp-base {

					.base {

						&__unit {

							&:not(:nth-child(1)) {
								@include per( $property: 'padding-top', $val: calcPerTypo( 14, 30, 'em') );
								@include per( $property: 'margin-top', $val: calcPerTypo( 14, 30, 'em') );
								border-top: 1px solid #eeeeee;

								@include mx-mq-max( $bp-sp-max ) {
									@include per( $property: 'padding-top', $val: calcPerTypo( 14, 30, 'em') );
									@include per( $property: 'margin-top', $val: calcPerTypo( 14, 30, 'em') );
								}
							}
						}

						&__hdr {
							display: flex;
							justify-content: space-between;
							align-items: center;

							@include mx-mq-max( $bp-sp-max ) {
								flex-direction: column;
							}

							.title {
								@include per( $property: 'padding-right', $val: calcPerTypo( 14, 24, 'em') );
								margin: 0;
								@include fs( 14 );
								@include lh( 24.5, 14 );

								@include mx-mq-max( $bp-sp-max ) {
									padding-right: 0;
									margin-right: auto;
								}
							}

							.link-btn {
								flex: 0 0 auto;

								@include mx-mq-max( $bp-sp-max ) {
									@include per( $property: 'margin', $val: calcPerTypo( 14, 12, 'em') auto 0 0 );
								}
							}
						}

						&__contents {
							display: flex;
							@include per( $property: 'padding-top', $val: calcPer( 864, 24, '%') );

							@include mx-mq-max( $bp-sp-max ) {
								display: block;
								@include per( $property: 'padding-top', $val: calcPer( $block80sp, 30, '%') );
								overflow: hidden;
							}

							.photo {
								// flex: 0 0 auto;
								@include per( $property: 'width', $val: calcPer( 864, 156, '%') );
								@include per( $property: 'padding-right', $val: calcPer( 864, 36, '%') );

								@include mx-mq-max( $bp-sp-max ) {
									float: left;
									@include per( $property: 'width', $val: calcPer( $block80sp, 288, '%') );
									@include per( $property: 'padding-right', $val: calcPer( $block80sp, 48, '%') );
									@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 24, '%') );
								}
							}

							.text {
								flex: 1;
								align-self: center;
							}

							dt {
								@include per( $property: 'margin-bottom', $val: calcPerTypo( 14, 12, 'em') );
								@include fs( 14 );
								@include lh( 24.5, 14 );
								@include letter( 50 );
								font-weight: bold;
							}

							dd {
								@include fs( 14 );
								@include lh( 24.5, 14 );
								@include letter( 50 );
							}
						}
					}
				}
			}

			&__ttl {
				@include calc(width, '25% - 24px');
				// @include per( $property: 'width', $val: calcPer( $block80, 288, '%') );
                // @include per( $property: 'padding-right', $val: calcPer( $block80, 24, '%') );
				font-weight: bold;
				@include fs( 14 );
                // @include ff( yugo );

				@include mx-mq-max( $bp-sp-max ) {
					width: 100%;
					// @include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 24, '%') );
					@include per( $property: 'margin-bottom', $val: calcPerTypo( 14, 12, 'em') );
					font-family: $font-family--hiragino;
				}
			}

			&__contents {
				@include calc(width, '75% + 24px');
				padding-left: 24px;
				@include fs( 14 );

				@include mx-mq-max( $bp-sp-max ) {
					width: 100%;
					padding-left: 0;
				}

				> .link-btn {
					@include per( $property: 'margin', $val: calcPer( $block60, 12, '%') 0 calcPer( $block60, 6, '%') );
					line-height: 1.5;

					@include mx-mq-max( $bp-sp-max ) {
						@include per( $property: 'margin-top', $val: calcPer( $block60sp, 24, '%') );
					}
				}
			}
		}
	}

	&-team {
		border-top: 1px solid #eeeeee;

		.team {

			&__unit {
				display: flex;
				// align-items: center;
				@include per( $property: 'padding', $val: calcPer( $block80, 60, '%') 0 );
				border-bottom: 1px solid #eeeeee;

				@include mx-mq-max( $bp-sp-max ) {
					flex-direction: column;
					@include per( $property: 'padding', $val: calcPer( $block80sp, 72, '%') 0 );
				}

				.photo {
					@include per( $property: 'width', $val: calcPer( $block80, 216, '%') );

					@include mx-mq-max( $bp-sp-max ) {
						@include per( $property: 'width', $val: calcPer( $block80sp, 389, '%') );
						@include per( $property: 'margin', $val: 0 auto calcPer( $block80sp, 60, '%') );
					}

					&:empty, &.is-empty {
						margin-bottom: 0;
					}

					img {
						width: 100%;
					}
				}

				.text {
					flex: 1;
					align-self: center;
					@include per( $property: 'padding-left', $val: calcPer( $block80, 72, '%') );

					@include mx-mq-max( $bp-sp-max ) {
						padding-left: 0;
					}
				}

				.post {
					@include per( $property: 'margin', $val: 0 0 calcPerTypo( 16, 6, 'em') );
                    @include ff( noto );
					@include letter( 50 );

					@include mx-mq-max( $bp-sp-max ) {
						@include per( $property: 'margin-bottom', $val: calcPerTypo( 12, 3, 'em') );
						@include fs( 12 );
					}
				}

				.lv4 {
					// @include per( $property: 'margin', $val: 0 0 calcPer( 792, 24, '%') );
					@include per( $property: 'margin', $val: 0 0 calcPerTypo( 24, 24, 'em') );

					@include mx-mq-max( $bp-sp-max ) {
						@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 36, '%') );
						@include fs( 20 );
					}
				}

				.intro {
					margin: 0;
					@include lh( 28, 16 );
					@include letter( 50 );

					@include mx-mq-max( $bp-sp-max ) {
						@include fs( 14 );
						@include lh( 24.5, 14 );
						@include letter( 50 );
					}

					// &:not(:last-child) {
					// 	@include per( $property: 'margin', $val: 0 0 calcPer( 792, 24, '%') );

					// 	@include mx-mq-max( $bp-sp-max ) {
					// 		@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 36, '%') );
					// 	}
					// }
				}

				ul {
					list-style: none;
				}

				.link-container {
					@include per( $property: 'margin-top', $val: calcPer( $block60, 24, '%') );

					@include mx-mq-max( $bp-sp-max ) {
						@include per( $property: 'margin-top', $val: calcPer( $block60sp, 36, '%') );
					}
				}
			}
		}
	}

	&-image-link {
		display: flex;
		justify-content: space-between;

		@include mx-mq-max( $bp-sp-max ) {
			flex-direction: column;
		}

		.image-link {

			&__list {
				@include per( $property: 'width', $val: calcPer( $block90, 636, '%') );
				list-style: none;

				@include mx-mq-max( $bp-sp-max ) {
					width: 100%;

					&:not(:last-child) {
						@include per( $property: 'margin-bottom', $val: calcPer( $block90sp, 31, '%') );
					}
				}

				a {
					display: block;
					@include per( $property: 'padding-bottom', $val: calcPer( 636, 254, '%') );
					color: #fff;
					position: relative;

					@include mx-mq-max( $bp-sp-max ) {
						@include per( $property: 'padding-bottom', $val: calcPer( $block90sp, 310, '%') );
					}

					&:after {
						display: block;
						background-color: hsla(0, 0%, 27%,.25);
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						z-index: 10;
						content: '';
					}
				}

				.text {
					text-align: center;
					position: absolute;
					top: 50%;
					right: 0;
					left: 0;
					z-index: 50;
					transform: translateY(-50%);
				}

				strong {
					display: block;
					@include per( $property: 'margin', $val: 0 0 calcPer( 636, 18, '%') );

					@include mx-mq-max( $bp-sp-max ) {
						@include per( $property: 'margin-bottom', $val: calcPer( $block90sp, 24, '%') );
						@include fs( 20 );
						@include lh( 25, 20 );
					}
				}

				span {
					display: inline-block;
					min-width: 133px;
					// height: 33px;
					@include per( $property: 'padding', $val: calcPerTypo( 14, 5, 'em') calcPerTypo( 14, 17, 'em') );
					border: 1px solid currentColor;
					@include fs( 14 );
					// line-height: 31px;
					@include letter( 50 );
					font-weight: bold;
					@include per( $property: 'border-radius', $val: calcPerTypo( 14, 16, 'em') );

					@include mx-mq-max( $bp-sp-max ) {
						min-width: inherit;
						@include per( $property: 'padding', $val: calcPerTypo( 12, 5.5, 'em') calcPerTypo( 12, 17.5, 'em') );
						@include fs( 12 );
						letter-spacing: normal;
						@include per( $property: 'border-radius', $val: calcPerTypo( 12, 15, 'em') );
					}
				}
			}
		}
	}

	&-text {

		[class*="lv"] {
			@include per( $property: 'margin', $val: 0 0 calcPer( $block60, 36, '%') );
			@include lh( 42, 24 );
			@include letter( 50 );
			text-align: center;

			@include mx-mq-max( $bp-sp-max ) {
				@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 60, '%') );
				@include lh( 31.5, 18 );
			}
		}

		.text {
			@include lh( 28, 16 );
            @include letter( 50 );

			&__hdr {
				background: {
					repeat: no-repeat;
					position: 50% 50%;
					size: cover;
				}
				@include per( $property: 'padding-bottom', $val: calcPer( $block90, 360, '%') );
				@include per( $property: 'margin-bottom', $val: calcPer( $block90, 60, '%') );
				position: relative;

				@include mx-mq-max( $bp-sp-max ) {
					@include per( $property: 'padding-bottom', $val: calcPer( $block90sp, 345, '%') );
					@include per( $property: 'margin', $val: 0 calcPer( $block90sp, -30, '%') calcPer( $block90sp, 96, '%') );
				}

				&:after {
					display: block;
					background-color: hsla(0, 0%, 27%, .25);
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					content: '';
				}

				[class*="lv"] {
					margin: 0;
					@include lh( 45, 36 );
					text-align: center;
					color: #fff;
					position: absolute;
					top: 50%;
					right: 0;
					left: 0;
					z-index: 100;
					transform: translateY(-50%);

					@include mx-mq-max( $bp-sp-max ) {
						@include lh( 24, 30 );
					}
				}
			}

			&__container {
				@include per( $property: 'width', $val: calcPer( 1296, 864, '%') );
				// width: 60%;
				margin: 0 auto;

				@include mx-mq-max($bp-sp-max) {
					@include per( $property: 'width', $val: calcPer( 690, 630, '%') );
				}
			}

			&__block {

				&:not(:nth-child(1)) {
					@include per( $property: 'margin-top', $val: calcPer( $block60, 60, '%') );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'margin-top', $val: calcPer( $block60sp, 96, '%') );
					}
				}
			}

            p {
				margin: 0;
				@include letter( 50 );

				&:not(:last-child) {
					@include per( $property: 'margin-bottom', $val: calcPerTypo( 16, 24, 'em') );

					@include mx-mq-max( $bp-sp-max ) {
						@include per( $property: 'margin-bottom', $val: calcPerTypo( 14, 24, 'em') );
					}
				}
			}

			a {
				text-decoration: underline;
				color: $c-thm-01;
				transition: all .3s $easing;

				&:hover {
					opacity: .6;
				}
			}

			&--center {
				text-align: center;
			}
		}

		&.results {

			> [class*="lv"] {
				@include per( $property: 'margin', $val: 0 0 calcPer( $block60, 60, '%') );

				@include mx-mq-max( $bp-sp-max ) {
					@include per( $property: 'margin', $val: 0 0 calcPer( $block60sp, 72, '%') );
					@include lh( 30, 24 );
				}
			}

			.results {

				&__contents {
					@include per( $property: 'padding', $val: 0 0 calcPer( $block60, 72, '%') );
					@include per( $property: 'margin', $val: calcPer( $block60, 60, '%') 0 0 );
					border-bottom: 1px solid #eee;

					@include mx-mq-max( $bp-sp-max ) {
						@include per( $property: 'padding', $val: 0 0 calcPer( $block60sp, 96, '%') );
						@include per( $property: 'margin', $val: calcPer( $block60sp, 72, '%') 0 0 );
					}

					> [class*="lv"] {
						// @include per( $property: 'padding', $val: 0 0 calcPer( $block60, 36, '%') );
						// border-bottom: 1px solid #eee;

						@include mx-mq-max( $bp-sp-max ) {
							@include per( $property: 'margin', $val: 0 0 calcPer( $block60sp, 48, '%') );
							@include lh( 20, 16 );
						}
					}
				}

				&__lead {
					@include per( $property: 'padding', $val: calcPer( $block60, 72, '%') 0 0 );
					@include per( $property: 'margin', $val: 0 0 calcPer( $block60, 48, '%') );
					border-top: 1px solid #eee;

					@include mx-mq-max( $bp-sp-max ) {
						@include per( $property: 'padding', $val: calcPer( $block60sp, 96, '%') 0 0 );
						@include per( $property: 'margin', $val: 0 0 calcPer( $block60sp, 72, '%') );
					}

					[class*="lv"] {
						text-align: left;
					}
				}

				&__q {

					[class*="lv"] {
						@include per( $property: 'margin', $val: 0 0 calcPer( $block60, 24, '%') );
						text-align: left;
						color: $c-thm-01;

						@include mx-mq-max( $bp-sp-max ) {
							@include per( $property: 'margin', $val: 0 0 calcPer( $block60sp, 48, '%') );
						}
					}
				}

				&__questions {
					@include per( $property: 'margin', $val: 0 0 calcPer( $block60, 48, '%') );

					@include mx-mq-max( $bp-sp-max ) {
						@include per( $property: 'margin', $val: 0 0 calcPer( $block60sp, 72, '%') );
					 }

					 .fig {
						margin: 0;
						@include fs( 16 );
						@include lh( 24, 16 );
					 }
				}

				&__a {

					> [class*="lv"] {
						@include per( $property: 'margin', $val: 0 0 calcPer( $block60, 24, '%') );
						text-align: left;
						color: $c-thm-01;

						@include mx-mq-max( $bp-sp-max ) {
							@include per( $property: 'margin', $val: 0 0 calcPer( $block60sp, 48, '%') );
						}
					}

					dd {
						@include lh( 28, 16 );
					}
				}

				&__answers {

					&:not(:last-child) {
						@include per( $property: 'margin', $val: 0 0 calcPer( $block60, 24, '%') );

						@include mx-mq-max( $bp-sp-max ) {
							@include per( $property: 'margin', $val: 0 0 calcPer( $block60sp, 48, '%') );
						}
					}

					[class*="lv"] {
						margin: 0;
						text-align: left;
					}
				}
			}
		}

        .m-columns {

            &--col2 {
				display: flex;
				justify-content: space-between;

				@include mx-mq-max( $bp-sp-max ) {
					flex-direction: column;
				}

                p {
					@include per( $property: 'width', $val: calcPer( 1152, 546, '%') );

					@include mx-mq-max( $bp-sp-max ) {
						width: 100%;
					}

                    &:nth-child( 1 ) {
                        // @include per( $property: 'margin-right', $val: calcPer( 1152, 30, '%') );

						@include mx-mq-max( $bp-sp-max ) {
							@include per( $property: 'margin', $val: 0 0 calcPer( $block80sp, 48, '%') );
						}
					}

                    &:nth-child( 2 ) {
                        // @include per( $property: 'margin-left', $val: calcPer( 1152, 30, '%') );

						// @include mx-mq-max( $bp-sp-max ) {
						// 	margin-left: 0;
						// }
					}
                }
            }
        }

		// .fig {
		// 	@include per( $property: 'margin-top', $val: calcPer( $block80, 60, '%') );
		// 	text-align: center;

		// 	@include mx-mq-max( $bp-sp-max ) {
		// 		@include per( $property: 'margin-top', $val: calcPer( $block80sp, 60, '%') );
		// 	}
		// }

		.fig {
			@include per( $property: 'margin', $val: calcPer( $block60, 48, '%') calcPer( $block60, -18, '%') 0 );
			text-align: center;

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'margin', $val: calcPer( $block60sp, 48, '%') 0 0 );
			}
		}

		.link-btn {
			@include per( $property: 'margin', $val: calcPer( $block60, 60, '%') auto 0 );

			@include mx-mq-max( $bp-sp-max ) {
				@include per( $property: 'margin-top', $val: calcPer( $block60sp, 72, '%') );
			}
		}
	}

	&-infographic {
		width: 70%;
		// @include per( $property: 'padding-top', $val: calcPer( $block60, 60, '%') );
		@include per( $property: 'margin', $val: calcPer( $block100, 60, '%') auto 0 );

		@include mx-mq-max($bp-sp-max) {
			width: 84%;
			@include per( $property: 'margin-top', $val: calcPer( $block100sp, 96, '%') );
		}

		.infographic {

			&-list {
				display: flex;

				@include mx-mq-max( $bp-sp-max ) {
					flex-wrap: wrap;
				}

				&__unit {
					width: 25%;
					text-align: center;
					list-style: none;
					color: $c-thm-01;

					@include mx-mq-max( $bp-sp-max ) {
						width: 50%;

						&:not(:nth-last-child( -n + 2 )) {
							@include per( $property: 'margin-bottom', $val: calcPer( $block60sp, 46, '%') );
						}
					}

					.icon {
						@include per( $property: 'width', $val: calcPer( 216, 64, '%') );
						// @include per( $property: 'margin', $val: 0 calcPer( 216, 76, '%') );
						margin: 0 auto;

						@include mx-mq-max( $bp-sp-max ) {
							@include per( $property: 'width', $val: calcPer( 315, 128, '%') );
							// @include per( $property: 'margin', $val: 0 calcPer( 315, 93.5, '%') );
						}
					}

					dt, dd, p {
						text-align: center;
					}

					dt {
						@include per( $property: 'padding', $val: calcPer( 216, 6, '%') 0 calcPer( 216, 12, '%') );
						margin: 0;

						@include mx-mq-max( $bp-sp-max ) {
							@include per( $property: 'padding', $val: calcPer( 315, 6, '%') 0 calcPer( 315, 12, '%') );
							@include fs( 15 );
						}
					}

					[class*="lv5"] {
						letter-spacing: normal;
					}

					.number {
						display: block;
						margin: 0;
						line-height: 1;
						@include letter( -25 );

						@include mx-mq-max( $bp-sp-max ) {
							@include fs( 45 );
						}

						span {
							margin-left: 0.333em;
							@include fs( 40 );

							@include mx-mq-max( $bp-sp-max ) {
								@include fs( 22.5 );
							}
						}
					}

					p {
						@include per( $property: 'margin', $val: calcPer( 216, 6, '%') 0 0 );
						font-weight: bold;

						@include mx-mq-max( $bp-sp-max ) {
							@include per( $property: 'margin-top', $val: calcPer( 315, 12, '%') );
						}
					}
				}
			}
		}

		&--col2 {
			display: flex;
			width: 70%;
			@include per( $property: 'margin', $val: calcPer( $block100, 60, '%') auto 0 );

			@include mx-mq-max($bp-sp-max) {
				flex-direction: column;
				width: 84%;
				@include per( $property: 'margin-top', $val: calcPer( $block100sp, 96, '%') );
			}

			.infographic {

				&-group {
					width: 50%;

					@include mx-mq-max( $bp-sp-max ) {
						width: 100%;
					}

					&:not(:last-child) {
						border-right: 1px solid $c-thm-01;

						@include mx-mq-max( $bp-sp-max ) {
							@include per( $property: 'padding-bottom', $val: calcPer( $block80sp, 72, '%') );
							@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 72, '%') );
							border-right: none;
							border-bottom: 1px solid $c-thm-01;
						}
					}

					> [class*="lv"] {
						@include per( $property: 'margin-bottom', $val: calcPerTypo( 18, 25, 'em') );
						@include lh( 27, 18 );
						letter-spacing: normal;
						color: $c-thm-01;
						text-align: center;

						@include mx-mq-max( $bp-sp-max ) {
							@include per( $property: 'margin-bottom', $val: calcPerTypo( 20, 24, 'em') );
							@include fs( 20 );
							@include lh( 25, 20 );
						}
					}
				}

				&-list {
					display: flex;
					width: 100%;

					@include mx-mq-max( $bp-sp-max ) {
						flex-wrap: wrap;
					}

					&__unit {
						width: 50%;
						text-align: center;
						list-style: none;
						color: $c-thm-01;

						@include mx-mq-max( $bp-sp-max ) {
						}

						&:not(:last-child) {
							border-right: 1px solid $c-thm-01;
						}

						.icon {
							@include per( $property: 'width', $val: calcPer( 251, 64, '%') );
							margin: 0 auto;

							@include mx-mq-max( $bp-sp-max ) {
								@include per( $property: 'width', $val: calcPer( 315, 128, '%') );
							}
						}

						dt, dd, p {
							text-align: center;
						}

						dt {
							@include per( $property: 'padding', $val: calcPerTypo( 16, 24, 'em') 0 calcPerTypo( 16, 6, 'em') );
							@include lh( 24, 16 );

							@include mx-mq-max( $bp-sp-max ) {
								@include per( $property: 'padding', $val: calcPerTypo( 14, 18, 'em') 0 calcPerTypo( 14, 6, 'em') );
								@include fs( 14 );
							}
						}

						[class*="lv"] {
							margin: 0;
							letter-spacing: normal;
						}

						.number {
							display: block;
							margin: 0;
							line-height: 1;
							@include letter( -25 );

							@include mx-mq-max( $bp-sp-max ) {
								@include fs( 45 );
							}

							span {
								margin-left: 0.333em;
								@include fs( 40 );

								@include mx-mq-max( $bp-sp-max ) {
									@include fs( 22.5 );
								}
							}
						}

						p {
							@include per( $property: 'margin', $val: calcPerTypo( 16, 6, 'em') 0 0 );
							font-weight: bold;
							letter-spacing: normal;

							@include mx-mq-max( $bp-sp-max ) {
								@include per( $property: 'margin-top', $val: calcPerTypo( 14, 6, 'em') );
							}
						}
					}
				}
			}
		}
	}

	&-infographic-a {
		@include per( $property: 'margin', $val: calcPer( $block60, 60, '%') auto 0 );

		@include mx-mq-max($bp-sp-max) {
			// width: 84%;
			// @include per( $property: 'margin-top', $val: calcPer( $block100sp, 96, '%') );
		}

		.infographic {

			&-list {
				display: flex;

				@include mx-mq-max( $bp-sp-max ) {
					flex-wrap: wrap;
				}

				&__unit {
					text-align: center;
					list-style: none;
					color: $c-thm-01;
					position: relative;

					@include mx-mq-max( $bp-sp-max ) {
					}

					.heading {
						display: flex;
						align-items: flex-end;
						justify-content: center;
						@include per( $property: 'margin', $val: 0 0 calcPerTypo( 18, 24, 'em') );
						@include fs( 18 );
						@include lh( 27, 18 );
						font-weight: bold;
						letter-spacing: normal;

						@include mx-mq-max( $bp-sp-max ) {
							@include per( $property: 'margin-bottom', $val: calcPerTypo( 16, 18, 'em') );
							@include fs( 16 );
						}
					}

					.icon {
						@include per( $property: 'width', $val: calcPerTypo( 16, 64, 'em') );
						margin: 0 auto;
					}

					dt, dd, p {
						text-align: center;
					}

					dt {
						@include per( $property: 'padding', $val: calcPerTypo( 16, 24, 'em') 0 calcPerTypo( 16, 6, 'em') );
						margin: 0;
						letter-spacing: normal;
						font-weight: bold;

						@include mx-mq-max( $bp-sp-max ) {
							@include per( $property: 'padding', $val: calcPerTypo( 14, 18, 'em') 0 calcPerTypo( 14, 6, 'em') );
							@include fs( 14 );
						}
					}

					.number {
						display: block;
						margin: 0;
						line-height: 1;
						@include letter( -25 );

						@include mx-mq-max( $bp-sp-max ) {
							@include fs( 45 );
						}
					}

					dd {

						p {
							@include per( $property: 'margin', $val: calcPerTypo( 16, 6, 'em') 0 0 );
							font-weight: bold;
							letter-spacing: normal;

							@include mx-mq-max( $bp-sp-max ) {
								@include per( $property: 'margin-top', $val: calcPerTypo( 14, 6, 'em') );
								@include fs( 14 );
							}
						}
					}
				}
			}
		}

		&--col2 {

			.infographic {

				&-list {

					@include mx-mq-max( $bp-sp-max ) {
						@include per( $property: 'width', $val: calcPer( 315, 255, '%') );
						margin: 0 auto;
					}

					&__unit {
						@include per( $property: 'width', $val: calcPer( $block60, 408, '%') );
						// width: 50%;

						@include mx-mq-max( $bp-sp-max ) {
							width: 100%;
						}

						&:nth-child( 2n + 1 ) {
							@include per( $property: 'margin-right', $val: calcPer( $block60, 48, '%') );

							@include mx-mq-max( $bp-sp-max ) {
								@include per( $property: 'padding', $val: 0 0 calcPer( 255, 36, '%') );
								margin-right: 0;
							}

							&:after {
								background-color: $c-thm-01;
								width: 1px;
								position: absolute;
								top: 0;
								bottom: 0;
								@include per( $property: 'right', $val: calcPer( 408, -24, '%') );
								content: '';

								@include mx-mq-max( $bp-sp-max ) {
									width: 100%;
									height: 1px;
									top: inherit;
									right: 0;
									left: 0;
								}
							}
						}

						&:nth-child( 2n + 2 ) {

							@include mx-mq-max( $bp-sp-max ) {
								@include per( $property: 'padding', $val: calcPer( 255, 36, '%') 0 0 );
							}
						}
					}
				}
			}
		}

		&--col3 {

			.infographic {

				&-list {
					@include per( $property: 'margin', $val: 0 calcPer( $block70, 36, '%') );

					@include mx-mq-max( $bp-sp-max ) {
						@include per( $property: 'width', $val: calcPer( 315, 255, '%') );
						margin: 0 auto;
					}

					&__unit {
						@include per( $property: 'width', $val: calcPer( $block70 - 72, 280, '%') );
						// width: 50%;

						@include mx-mq-max( $bp-sp-max ) {
							width: 100%;
							@include per( $property: 'padding', $val: calcPer( 255, 36, '%') 0 );

							&:nth-child( 1 ) {
								padding-top: 0;
							}

							&:nth-child( 3 ) {
								padding-bottom: 0;
							}
						}

						&:not(:nth-child( 3n + 3 ) ) {
							@include per( $property: 'margin-right', $val: calcPer( $block70 - 72, 48, '%') );

							@include mx-mq-max( $bp-sp-max ) {
								margin-right: 0;
							}

							&:after {
								background-color: $c-thm-01;
								width: 1px;
								position: absolute;
								top: 0;
								bottom: 0;
								@include per( $property: 'right', $val: calcPer( 280, -24, '%') );
								content: '';

								@include mx-mq-max( $bp-sp-max ) {
									width: 100%;
									height: 1px;
									top: inherit;
									right: 0;
									left: 0;
								}
							}
						}
					}
				}
			}
		}

		&--col4 {

			.infographic {

				&-list {

					@include mx-mq-max( $bp-sp-max ) {
						@include per( $property: 'width', $val: calcPer( 315, 255, '%') );
						margin: 0 auto;
					}

					&__unit {
						@include per( $property: 'width', $val: calcPer( $block70, 252, '%') );

						@include mx-mq-max( $bp-sp-max ) {
							width: 100%;
							@include per( $property: 'padding', $val: calcPer( 255, 36, '%') 0 );

							&:nth-child( 1 ) {
								padding-top: 0;
							}

							&:nth-child( 4 ) {
								padding-bottom: 0;
							}
						}

						&:not(:nth-child( 4n + 4 ) ) {

							&:after {
								background-color: $c-thm-01;
								width: 1px;
								position: absolute;
								top: 0;
								bottom: 0;
								@include per( $property: 'right', $val: 0 );
								content: '';

								@include mx-mq-max( $bp-sp-max ) {
									width: 100%;
									height: 1px;
									top: inherit;
									right: 0;
									left: 0;
								}
							}
						}
					}
				}
			}
		}
	}

	&-image-text {
		@include per( $property: 'padding-top', $val: calcPer( $block60, 60, '%') );

		@include mx-mq-max( $bp-sp-max ) {
			@include per( $property: 'padding-top', $val: calcPer( $block60sp, 72, '%') );
		}

		.image-text {

			&-list {
				display: flex;
				flex-wrap: wrap;

				&__unit {
					@include per( $property: 'width', $val: calcPer( $block60, 198, '%') );
					list-style: none;

					@include mx-mq-max( $bp-sp-max ) {
						@include per( $property: 'width', $val: calcPer( $block60sp, 300, '%') );
					}

					&:not(:nth-child(4n+4)) {
						@include per( $property: 'margin', $val: 0 calcPer( $block60, 24, '%') calcPer( $block60, 36, '%') 0 );

						@include mx-mq-max( $bp-sp-max ) {
							margin: 0;
						}
					}

					&:nth-last-child(-n+4) {
						margin-bottom: 0;
					}

					&:not(:nth-child(2n+2)) {

						@include mx-mq-max( $bp-sp-max ) {
							@include per( $property: 'margin-right', $val: calcPer( $block60sp, 30, '%') );
						}
					}

					&:not(:nth-last-child(-n+2)) {

						@include mx-mq-max( $bp-sp-max ) {
							@include per( $property: 'margin-bottom', $val: calcPer( $block60sp, 48, '%') );
						}
					}

					.image {
						@include per( $property: 'margin-bottom', $val: calcPer( 198, 12, '%') );

						@include mx-mq-max( $bp-sp-max ) {
							@include per( $property: 'margin-bottom', $val: calcPer( 300, 24, '%') );
						}
					}
				}
			}
		}
	}

	&-ico-text {
		@include per( $property: 'padding-top', $val: calcPer( $block60, 60, '%') );

		@include mx-mq-max( $bp-sp-max ) {
			@include per( $property: 'padding-top', $val: calcPer( $block60sp, 72, '%') );
		}

		.ico-text {

			&-list {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				&__unit {
					background-color: hsl(0, 0%, 97%);
					@include per( $property: 'width', $val: calcPer( $block60, 420, '%') );
					@include per( $property: 'padding', $val: calcPer( $block60, 48, '%') calcPer( $block60, 36, '%') );
					list-style: none;
					@include letter( 50 );

					@include mx-mq-max( $bp-sp-max ) {
						width: 100%;
						@include per( $property: 'padding', $val: calcPer( $block60sp, 60, '%') calcPer( $block60sp, 48, '%') );

						&:not(:last-child) {
							@include per( $property: 'margin-bottom', $val: calcPer( $block60sp, 30, '%') );
						}
					}

					dt {
						@include per( $property: 'padding-left', $val: calcPerTypo( 16, 58, 'em') );
						@include per( $property: 'margin-bottom', $val: calcPer( 420, 24, '%') );
                        font-weight: bold;
                        @include ff( noto );
						color: $c-thm-01;
						position: relative;

						@include mx-mq-max( $bp-sp-max ) {
							@include per( $property: 'padding-left', $val: calcPerTypo( 14, 60, 'em') );
							@include per( $property: 'margin-bottom', $val: calcPer( 534, 36, '%') );
						}

						[class^="icon-"] {
							@include fs( 39 );
							position: absolute;
							top: 50%;
							left: 0;
							transform: translateY(-50%);

							@include mx-mq-max( $bp-sp-max ) {
								@include fs( 48 );
							}
						}
					}

					dd {
						@include lh( 28, 16 );

						@include mx-mq-max( $bp-sp-max ) {
							@include fs( 14 );
						}
					}
				}
			}
		}
	}

	&-message-photo {
		display: flex;

		@include mx-mq-max( $bp-sp-max ) {
			flex-direction: column-reverse;
		}

		.text {
			@include per( $property: 'width', $val: calcPer( $block80, 744, '%') );
			@include per( $property: 'padding-right', $val: calcPer( $block80, 72, '%') );

			@include mx-mq-max( $bp-sp-max ) {
				width: 100%;
				padding-right: 0;
			}

			.catch {
				@include per( $property: 'margin', $val: 0 0 calcPer( 672, 36, '%') );
				@include fs( 24 );
				@include lh( 42, 24 );
				@include letter( 50 );
				font-weight: bold;

				@include mx-mq-max( $bp-sp-max ) {
					@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 60, '%') );
					@include fs( 18 );
				}
			}

			p {
				@include lh( 28, 16 );
				@include letter( 50 );

				@include mx-mq-max( $bp-sp-max ) {
					@include fs( 14 );
				}

				&:not(:last-of-type()) {
					@include per( $property: 'margin', $val: 0 0 calcPer( 672, 24, '%') );

					@include mx-mq-max( $bp-sp-max ) {
						@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 48, '%') );
					}
				}

				&:last-of-type {
					margin: 0;
				}
			}

			.sign {
				@include per( $property: 'padding-top', $val: calcPer( 672, 36, '%') );

				@include mx-mq-max( $bp-sp-max ) {
					@include per( $property: 'padding-top', $val: calcPer( $block80sp, 60, '%') );
				}

				dt {
					@include per( $property: 'margin-bottom', $val: calcPer( 672, 12, '%') );
					@include fs( 14 );
					// @include lh( 21, 14 );
					letter-spacing: normal;

					@include mx-mq-max( $bp-sp-max ) {
						@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 24, '%') );
						@include lh( 21, 12 );
						@include letter( 50 );
					}
				}

				dd {
					// width: 160px;
					width: 120px;

					img {
						width: auto;
						max-width: 100%;

						@include mx-mq-max( $bp-sp-max ) {
							@include per( $property: 'width', $val: calcPer( $block80sp, 514, '%') );
						}
					}
				}
			}
		}

		.photo {
			@include per( $property: 'width', $val: calcPer( $block80, 408, '%') );

			@include mx-mq-max( $bp-sp-max ) {
				width: 100%;
				@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 72, '%') );
			}
		}
	}

	&-text-table-list {
		@include per( $property: 'padding', $val: calcPer( $block80, 60, '%') calcPer( $block80, 72, '%') );
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);

		@include mx-mq-max($bp-sp-max) {
			@include per( $property: 'padding', $val: calcPer( $block80sp, 96, '%') calcPer( $block80sp, 60, '%') );
			@include per( $property: 'margin', $val: 0 calcPer( $block80sp, -30, '%') );
		}

		.text-table-list {

			&__unit {
				display: flex;

				@include mx-mq-max($bp-sp-max) {
					flex-direction: column;
				}

				&:not(:last-child) {
					@include per( $property: 'padding-bottom', $val: calcPer( 1008, 48, '%') );
					@include per( $property: 'margin-bottom', $val: calcPer( 1008, 48, '%') );
					border-bottom: 1px solid #eee;

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'padding-bottom', $val: calcPer( 510, 72, '%') );
						@include per( $property: 'margin-bottom', $val: calcPer( 510, 72, '%') );
					}
				}
			}

			&__hdr {
				@include per( $property: 'width', $val: calcPer( 1008, 336, '%') );

				@include mx-mq-max($bp-sp-max) {
					width: 100%;
					@include per( $property: 'margin-bottom', $val: calcPer( 510, 60, '%') );
				}

				p {
					margin: 0;
					@include fs( 18 );
					@include lh( 31.5, 18 );
					@include letter( 50 );
					font-weight: bold;

					@include mx-mq-max($bp-sp-max) {
						@include fs( 16 );
						@include lh( 28, 16 );

						br {
							display: none;
						}
					}
				}
			}

			&__ttl {
				@include per( $property: 'margin', $val: 0 0 calcPerTypo( 20, 3, 'em') );
				@include fs( 20 );
				@include lh( 35, 20 );
				font-weight: bold;

				@include mx-mq-max($bp-sp-max) {
					@include per( $property: 'margin-bottom', $val: calcPerTypo( 20, 6, 'em') );
					@include lh( 25, 20 );
				}
			}

			&__contents {
				@include per( $property: 'width', $val: calcPer( 1008, 672, '%') );

				@include mx-mq-max($bp-sp-max) {
					width: 100%;
				}

				ul {
					list-style: none;
				}

				li {
					padding-left: .5em;
					@include lh( 28, 16 );
					font-weight: bold;
					position: relative;

					@include mx-mq-max($bp-sp-max) {
						padding-left: 1em;
						@include fs( 14 );
						@include lh( 24.5, 14 );
					}

					&:not(:last-child) {
						@include per( $property: 'margin-bottom', $val: calcPerTypo( 16, 12, 'em') );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'margin-bottom', $val: calcPerTypo( 14, 12, 'em') );
						}
					}

					&:before {
						font-weight: bold;
						position: absolute;
						top: 0;
						left: 0;
						content: '\0000B7';
					}
				}
			}
		}
	}

	&-related-link {
		@include per( $property: 'margin', $val: calcPer( $block100, 96, '%') 0 );

		@include mx-mq-max($bp-sp-max) {
			@include per( $property: 'margin', $val: calcPer( $block100sp, 120, '%') 0 );
		}

		&.m-border {

			&--top {
				@include per( $property: 'padding-top', $val: calcPer( $block100, 72, '%') );

				@include mx-mq-max($bp-sp-max) {
					@include per( $property: 'padding-top', $val: calcPer( $block100sp, 96, '%') );
				}
			}

			&--bottom {
				@include per( $property: 'padding-bottom', $val: calcPer( $block100, 72, '%') );

				@include mx-mq-max($bp-sp-max) {
					@include per( $property: 'padding-bottom', $val: calcPer( $block100sp, 96, '%') );
				}
			}
		}

		.message {
			@include per( $property: 'margin', $val: 0 0 calcPer( $block80, 36, '%') );
			@include fs( 18 );
			@include ff( noto );
			// @include letter( 50 );
			@include lh( 31.5, 18 );
			font-weight: bold;
			text-align: center;

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 48, '%') );
				@include fs( 16 );
				@include lh( 24, 16 );
				// @include letter( 50 );
			}
		}

		&--col1 {

			.related-link {

				&-list {
					justify-content: center;

					li {
						@include per( $property: 'width', $val: calcPer( $block80, 648, '%') );

						@include mx-mq-max($bp-sp-max) {
							width: 100%;
						}
					}

					a {
						@include per( $property: 'padding', $val: calcPer( 648, 24, '%') );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'padding', $val: calcPer( $block80sp, 36, '%') calcPer( $block80sp, 30, '%') );
						}
					}

					.image {
						@include per( $property: 'width', $val: calcPer( 600, 168, '%') );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'width', $val: calcPer( 570, 144, '%') );
						}

						span {
							@include per( $property: 'padding-bottom', $val: calcPer( 168, 112, '%') );

							@include mx-mq-max($bp-sp-max) {
								padding-bottom: 100%;
							}
						}
					}

					.text {
						@include per( $property: 'padding-left', $val: calcPer( 600, 24, '%') );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'padding-left', $val: calcPer( 570, 30, '%') );
						}
					}

					.link-arrow {
						@include per( $property: 'padding-top', $val: calcPerTypo( 14, 12, 'em') );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'padding-top', $val: calcPerTypo( 12, 6, 'em') );
						}
					}
				}
			}
		}

		&--col2 {

			.related-link {

				&-list {
					justify-content: center;

					li {
						@include per( $property: 'width', $val: calcPer( $block80, 564, '%') );

						@include mx-mq-max($bp-sp-max) {
							width: 100%;
						}

						&:not(:nth-child(2n+2)){
							@include per( $property: 'margin-right', $val: calcPer( $block80, 24, '%') );
						}
					}

					a {
						@include per( $property: 'padding', $val: calcPer( 564, 24, '%') );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'padding', $val: calcPer( $block80sp, 36, '%') calcPer( $block80sp, 30, '%') );
						}
					}

					.image {
						@include per( $property: 'width', $val: calcPer( 564, 168, '%') );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'width', $val: calcPer( 570, 144, '%') );
						}

						span {
							@include per( $property: 'padding-bottom', $val: calcPer( 168, 112, '%') );
						}
					}

					.text {
						@include per( $property: 'padding-left', $val: calcPer( 564, 24, '%') );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'padding-left', $val: calcPer( 570, 30, '%') );
						}
					}

					.link-arrow {
						@include per( $property: 'padding-top', $val: calcPerTypo( 14, 12, 'em') );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'padding-top', $val: calcPerTypo( 12, 6, 'em') );
						}
					}
				}
			}
		}

		&--col3 {

			.related-link {

				&-list {

					li {
						@include per( $property: 'width', $val: calcPer( $block80, 366, '%') );

						@include mx-mq-max($bp-sp-max) {
							width: 100%;
						}

						&:not(:nth-child(3n+3)) {
							@include per( $property: 'margin', $val: 0 calcPer( $block80, 27, '%') 0 0 );

							@include mx-mq-max($bp-sp-max) {
								margin: 0;
							}
						}
					}

					a {
						@include per( $property: 'padding', $val: calcPer( 366, 24, '%') );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'padding', $val: calcPer( $block80sp, 36, '%') calcPer( $block80sp, 30, '%') );
						}
					}

					.image {
						@include per( $property: 'width', $val: calcPer( 318, 96, '%') );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'width', $val: calcPer( 570, 144, '%') );
						}

						span {
							padding-bottom: 100%;
						}
					}

					.text {
						@include per( $property: 'padding-left', $val: calcPer( 318, 24, '%') );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'padding-left', $val: calcPer( 570, 30, '%') );
						}

						p {
							@include fs( 14 );
						}
					}

					.link-arrow {
						@include per( $property: 'padding-top', $val: calcPerTypo( 14, 6, 'em') );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'padding-top', $val: calcPerTypo( 12, 6, 'em') );
						}
					}
				}
			}
		}

		.related-link {

			&-list {
				display: flex;
				list-style: none;

				@include mx-mq-max($bp-sp-max) {
					flex-direction: column;
				}

				li {

					&:not(:last-child) {

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 30, '%') );
						}
					}
				}

				a {
					display: flex;
					align-items: center;
					background-color: #f4f4f4;
					height: 100%;
					border-radius: 6px;
					transition: all .3s $easing;

					&:hover {
						opacity: .6;

						[class^="icon-"] {
							right: -4px;
						}
					}
				}

				.image {

					span {
						display: block;
						border-radius: 3px;
					}
				}

				.text {
					flex: 1;
					line-height: 1.5;

					p {
						margin: 0;
						@include ff( noto );
						font-weight: bold;
						@include letter( 50 );
					}
				}
			}
		}

		&--impact {

			.related-link {

				&-list {

					a {
						background: url(../img/bg_banner.jpg) no-repeat 50% 50%;
						background-size: cover;
						position: relative;

						&:after {
							display: block;
							border: 1px solid hsla(0, 0%, 13%, .15);
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							border-radius: 3px;
							content: '';
						}
					}

					.text {
						color: #fff;
					}

					.link-arrow {

						> span {
							color: #fff;
						}
					}

					[class^="icon-"] {

						&:before {
							color: #fff;
						}
					}
				}
			}
		}
	}

	&-block-list {
		display: flex;

		@include mx-mq-max( $bp-sp-max ) {
			flex-direction: column;
			@include per( $property: 'margin', $val: 0 calcPer( 690, 30, '%') );
		}

		.block-list {

			&__unit {
				@include per( $property: 'width', $val: calcPer( $block90, 392, '%') );

				@include mx-mq-max( $bp-sp-max ) {
					width: 100%;
				}

				&:not(:last-child) {

					@include mx-mq-max( $bp-sp-max ) {
						@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 72, '%') );
					}
				}

				&:not(:nth-child(3n+3)) {
					@include per( $property: 'margin-right', $val: calcPer( $block90, 60, '%') );

					@include mx-mq-max( $bp-sp-max ) {
						margin-right: 0;
					}
				}

				[class*="lv"] {
					@include per( $property: 'margin', $val: 0 0 calcPer( 392, 36, '%') );
					letter-spacing: normal;
					text-align: center;

					@include mx-mq-max( $bp-sp-max ) {
						@include per( $property: 'margin-bottom', $val: calcPer( $block90sp, 48, '%') );
					}
				}

				.image {
					@include per( $property: 'margin', $val: 0 0 calcPer( 392, 25, '%') );

					@include mx-mq-max( $bp-sp-max ) {
						@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 48, '%') );
					}
				}

				.description {
					margin: 0;
					@include lh( 28, 16 );
					@include letter( 50 );
				}
			}
		}
    }

    &-alternate {

        .alternate-list {

            &__unit {
                display: flex;
                background-color: #f8f8f8;

				@include mx-mq-max($bp-sp-max) {
					flex-direction: column;
				}

                &:not(:last-child) {
                    @include per( $property: 'margin-bottom', $val: calcPer( $block90, 24, '%') );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'margin-bottom', $val: calcPer( $block90sp, 30, '%') );
					}
				}

                .image, .fig, .text {
                    width: 50%;

					@include mx-mq-max($bp-sp-max) {
						width: 100%;
					}
				}

                .image {
					@include per( $property: 'padding-top', $val: calcPer( $block90, 432, '%') );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'padding-bottom', $val: calcPer( $block90sp, 460, '%') );
					}

                    img {
                        width: 100%;
                    }
                }

                .fig {
                    display: flex;
                    align-items: center;

					@include mx-mq-max($bp-sp-max) {
					}

					&__contents {
						display: flex;
						align-items: center;
						background-color: #fff;
						width: 100%;
						height: 100%;
						// @include per( $property: 'padding', $val: calcPer( $block90, 72, '%') calcPer( $block90, 24, '%') );
						padding: 12.5% 4.1666%;
						text-align: center;
						color: $c-thm-01;

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'padding', $val: calcPer( 285, 36, '%') calcPer( 285, 15, '%') );
						}

						dl {
							width: 100%;
						}

						dt {
							@include per( $property: 'margin-bottom', $val: calcPerTypo( 18, 24, 'em') );
							@include fs( 18 );
							@include lh( 27, 18 );

							@include mx-mq-max($bp-sp-max) {
								@include per( $property: 'margin-bottom', $val: calcPerTypo( 16, 18, 'em') );
								@include fs( 16 );
							}
						}

						dd {
							display: flex;
							justify-content: center;
							align-items: center;
							position: relative;

							span {
								display: block;
							}
						}

						.before, .after {
							width: 50%;
						}

						.before {

							&:after {
								display: block;
								background-color: $c-thm-01;
								width: 1px;
								position: absolute;
								top: 0;
								left: 50%;
								bottom: 0;
								content: '';
							}
						}

						[class^="icon-"] {
							background-color: #fff;
							@include per( $property: 'width', $val: calcPerTypo( 24, 24, 'em') );
							@include per( $property: 'height', $val: calcPerTypo( 24, 40, 'em') );
							@include per( $property: 'padding-top', $val: calcPerTypo( 24, 8, 'em') );
							@include fs( 24 );
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%,-50%);

							@include mx-mq-max($bp-sp-max) {
								@include per( $property: 'height', $val: calcPerTypo( 18, 30, 'em') );
								@include per( $property: 'padding-top', $val: calcPerTypo( 18, 5.5, 'em') );
								@include fs( 18 );
							}
						}

						.note {
							display: block;
							@include per( $property: 'margin-bottom', $val: calcPerTypo( 16, 6, 'em') );
							text-align: center;
							font-weight: bold;
						}

						.number {
							@include per( $property: 'margin-bottom', $val: calcPerTypo( 60, 6, 'em') );
							@include fs( 60 );
							line-height: 1;
							@include letter( -25 );
							font-weight: bold;

							@include mx-mq-max($bp-sp-max) {
								@include per( $property: 'margin-bottom', $val: calcPerTypo( 36, 6, 'em') );
								@include fs( 36 );
							}
						}

						.term {
							font-weight: bold;
							letter-spacing: normal;
						}
					}

					&__image {
						text-align: center;
					}
				}

                .text {
                    display: flex;
                    align-items: center;
                    @include per( $property: 'padding', $val: calcPer( $block90, 72, '%') );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'padding', $val: calcPer( $block90sp, 60, '%') );
					}
				}

                dt {
                    @include per( $property: 'margin-bottom', $val: calcPer( 504, 24, '%') );
                    @include fs( 20 );
                    @include lh( 35, 20 );
                    @include letter( 50 );
                    @include ff( noto );
                    font-weight: bold;

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'margin-bottom', $val: calcPer( 570, 36, '%') );
						@include fs( 16 );
					}
				}

                dd {
                    @include lh( 28, 16 );
                    @include letter( 50 );

					@include mx-mq-max($bp-sp-max) {
						@include fs( 14 );
						@include lh( 21, 14 );
					}
				}

                &:nth-child( odd ) {

					.fig {
						@include per( $property: 'padding', $val: calcPer( $block90, 72, '%') 0 calcPer( $block90, 72, '%') calcPer( $block90, 72, '%') );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'padding', $val: calcPer( $block90sp, 60, '%') calcPer( $block90sp, 60, '%') 0 );
						}
					}
				}

                &:nth-child( even ) {
                    flex-direction: row-reverse;

					@include mx-mq-max($bp-sp-max) {
						flex-direction: column;
					}

					.fig {
						@include per( $property: 'padding', $val: calcPer( $block90, 72, '%') calcPer( $block90, 72, '%') calcPer( $block90, 72, '%') 0 );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'padding', $val: calcPer( $block90sp, 60, '%') calcPer( $block90sp, 60, '%') 0 );
						}
					}
				}
            }
        }
    }

	&-flow-simple {
        counter-reset: number 0;

		> [class^="lv"] {
			@include per( $property: 'margin', $val: 0 0 calcPer( $block80, 36, '%') );
			text-align: center;
			@include lh( 31.5, 18 );
			@include letter( 50 );

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'margin', $val: 0 0 calcPer( $block80sp, 48, '%') );
			}
		}

		.flow {

			&__unit {
				display: flex;
				@include per( $property: 'padding', $val: calcPer( $block80, 60, '%') 0 );
				box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
				position: relative;

				@include mx-mq-max($bp-sp-max) {
					flex-direction: column;
					@include per( $property: 'padding', $val: calcPer( $block80sp, 60, '%') 0 );
				}

                &:not(:last-child) {
                    @include per( $property: 'margin-bottom', $val: calcPer( $block80, 24, '%') );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 30, '%') );
					}
				}

                &:not(:nth-child(1)) {
                    counter-increment: number;

					&:before {
						display: block;
						background-color: #eeeeee;
						@include per( $property: 'width', $val: calcPer( $block80, 18, '%') );
						@include per( $property: 'padding-bottom', $val: calcPer( $block80, 24, '%') );
						position: absolute;
						bottom: 100%;
						left: 50%;
						z-index: -1;
						content: '';
						transform: translateX(-50%);

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'width', $val: calcPer( $block80sp, 24, '%') );
							@include per( $property: 'padding-bottom', $val: calcPer( $block80sp, 30, '%') );
						}
					}
				}

                .step {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
                    @include per( $property: 'width', $val: calcPer( $block80, 192, '%') );
					text-align: center;
					color: $c-thm-01;
					font-weight: bold;
					border-right: 1px solid #eeeeee;

					@include mx-mq-max($bp-sp-max) {
						width: auto;
						@include per( $property: 'padding-bottom', $val: calcPer( $block80sp, 48, '%') );
						@include per( $property: 'margin', $val: 0 calcPer( $block80sp, 60, '%') );
						border-right: none;
						border-bottom: 1px solid #eeeeee;
					}

					span {
						@include fs( 16 );
						@include lh( 21, 16 );

						@include mx-mq-max($bp-sp-max) {
							@include fs( 12 );
							@include lh( 15, 12 );
						}
					}

					&:after {
						display: block;
						@include per( $property: 'margin-top', $val: calcPer( 360, 3, '%') );
						@include fs( 60 );
						line-height: 1;
						@include letter( 50 );
						content: counter(number);

						@include mx-mq-max($bp-sp-max) {
							margin-top: 0;
							@include fs( 48 );
						}
					}
                }

                .text {
                    display: flex;
                    align-items: center;
					flex: 1;
					@include per( $property: 'padding', $val: 0 calcPer( $block80, 48, '%') 0 calcPer( $block80, 47, '%') );

					@include mx-mq-max($bp-sp-max) {
						flex-direction: column;
						align-items: flex-start;
						@include per( $property: 'padding', $val: calcPer( $block80sp, 60, '%') calcPer( $block80sp, 60, '%') 0 );
					}
				}

                .term {
                    @include per( $property: 'width', $val: calcPer( $block80, 180, '%') );
					@include per( $property: 'padding', $val: calcPerTypo( 14, 5, 'em') 0 );
                    @include per( $property: 'margin', $val: calcPer( $block80, 24, '%') 0 0 );
					text-align: center;
					color: $c-thm-01;
					@include fs( 14 );
					@include lh( 21, 14 );
                    @include letter( 50 );
                    @include ff( noto );
                    font-weight: bold;
					@include per( $property: 'border-radius', $val: calcPerTypo( 14, 16.5, 'em') );
                    border: 1px solid currentColor;
                    position: absolute;
                    top: 0;
                    @include per( $property: 'right', $val: calcPer( $block80, 24, '%') );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'width', $val: calcPer( $block80sp, 240, '%') );
						@include per( $property: 'padding', $val: calcPerTypo( 10, 5, 'em') calcPerTypo( 10, 11, 'em') );
						@include per( $property: 'margin', $val: calcPer( $block80sp, 36, '%') 0 0 calcPer( $block80sp, 60, '%') );
						@include fs( 10 );
						@include lh( 15, 10 );
						position: static;
						@include per( $property: 'border-radius', $val: calcPerTypo( 10, 13.5, 'em') );
					}
				}

                dt {
                    @include per( $property: 'margin-bottom', $val: calcPerTypo( 20, 24, 'em') );
                    @include fs( 20 );
                    @include lh( 35, 20 );
                    @include letter( 50 );
					@include ff( noto );
					color: $c-thm-01;
                    font-weight: bold;

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'margin-bottom', $val: calcPer( 510, 24, '%') );
						@include fs( 16 );
					}
				}

                dd {
                    @include lh( 28, 16 );
                    @include letter( 50 );

					@include mx-mq-max($bp-sp-max) {
						@include fs( 14 );
					}
				}
			}
        }
    }

	&-flow-image {
        counter-reset: number 0;

		> [class^="lv"] {
			@include per( $property: 'margin', $val: 0 0 calcPer( $block80, 36, '%') );
			text-align: center;
			@include lh( 31.5, 18 );
			@include letter( 50 );

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'margin', $val: 0 0 calcPer( $block80sp, 48, '%') );
			}
		}

		.flow {

			&__unit {
                display: flex;
                background-color: #f8f8f8;

				@include mx-mq-max($bp-sp-max) {
					flex-direction: column;
				}

                &:not(:last-child) {
                    @include per( $property: 'margin-bottom', $val: calcPer( $block80, 24, '%') );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 36, '%') );
					}
				}

                &:not(:nth-child(1)) {
                    counter-increment: number;
                }

                .image {
                    @include per( $property: 'width', $val: calcPer( $block80, 360, '%') );
                    @include per( $property: 'padding-bottom', $val: calcPer( $block80, 314, '%') );
                    position: relative;

					@include mx-mq-max($bp-sp-max) {
						width: 100%;
						@include per( $property: 'padding-bottom', $val: calcPer( 630, 472, '%') );
					}

                    &:before {
                        display: block;
                        background-color: hsla(0, 0%, 27%, .25);
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 100;
                        content: '';
                    }

                    .step {
                        text-align: center;
                        @include fs( 24 );
                        @include lh( 27, 24 );
                        font-weight: bold;
                        color: #fff;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        left: 0;
                        z-index: 150;
                        transform: translateY(-50%);

						@include mx-mq-max($bp-sp-max) {
							@include lh( 30, 24 );
						}

                        &:after {
                            display: block;
                            @include per( $property: 'margin-top', $val: calcPer( 360, 3, '%') );
                            @include fs( 60 );
                            line-height: 1;
                            @include letter( 50 );
                            content: '0' counter(number);

							@include mx-mq-max($bp-sp-max) {
								@include per( $property: 'margin-top', $val: calcPer( $block60sp, 6, '%') );
							}
						}
                    }
                }

                .text {
                    display: flex;
                    align-items: center;
                    flex: 1;
                    @include per( $property: 'padding', $val: calcPer( $block80, 68, '%') calcPer( $block80, 72, '%') );
                    position: relative;

					@include mx-mq-max($bp-sp-max) {
						flex-direction: column;
						align-items: flex-start;
						@include per( $property: 'padding', $val: calcPer( $block80sp, 60, '%') );
					}
				}

                .term {
                    @include per( $property: 'width', $val: calcPer( 792, 180, '%') );
                    @include per( $property: 'margin', $val: calcPer( 792, 24, '%') 0 0 );
                    line-height: 31px;
                    text-align: center;
                    @include fs( 14 );
                    @include letter( 50 );
                    @include ff( yugo );
                    font-weight: bold;
                    border-radius: 15px;
                    border: 1px solid hsla(0, 0%, 13%, .5);
                    position: absolute;
                    top: 0;
                    @include per( $property: 'right', $val: calcPer( 792, 24, '%') );

					@include mx-mq-max($bp-sp-max) {
						width: 90px;
						@include per( $property: 'padding', $val: calcPerTypo( 10, 5, 'em') 0 );
						@include per( $property: 'margin', $val: 0 0 calcPer( 510, 24, '%') );
						@include fs( 10 );
						@include lh( 15, 10 );
						position: static;
						@include per( $property: 'border-radius', $val: calcPerTypo( 10, 13.5, 'em') );
					}
				}

                dt {
                    @include per( $property: 'margin-bottom', $val: calcPer( 648, 24, '%') );
                    @include fs( 20 );
                    @include lh( 35, 20 );
                    @include letter( 50 );
                    @include ff( noto );
                    font-weight: bold;

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'margin-bottom', $val: calcPer( 510, 24, '%') );
						@include fs( 16 );
					}
				}

                dd {
                    @include lh( 28, 16 );
                    @include letter( 50 );

					@include mx-mq-max($bp-sp-max) {
						@include fs( 14 );
					}
				}
			}
        }
    }

    &-entry-list {
        @include per( $property: 'padding-bottom', $val: calcPer( $block90, 72, '%') );

		@include mx-mq-max($bp-sp-max) {
			@include per( $property: 'padding-bottom', $val: calcPer( $block90sp, 96, '%') );
		}

		> [class^="lv"] {
			@include per( $property: 'margin', $val: 0 0 calcPer( $block90, 48, '%') );
            text-align: center;
            letter-spacing: normal;
		}

        .entry-list {

            &__container {
                display: flex;
                flex-wrap: wrap;
            }

            &__unit {
                @include per( $property: 'width', $val: calcPer( $block90, 288, '%') );

				@include mx-mq-max($bp-sp-max) {
					width: 100%;
				}

                &:not(:nth-child( 4n + 4 )) {
                    @include per( $property: 'margin-right', $val: calcPer( $block90, 48, '%') );

					@include mx-mq-max($bp-sp-max) {
						margin-right: 0;
					}
				}

                &:not(:nth-last-child(-n+4)) {
                    @include per( $property: 'margin-bottom', $val: calcPer( $block90, 48, '%') );

					// @include mx-mq-max($bp-sp-max) {
					// 	@include per( $property: 'margin-bottom', $val: calcPer( $block90, 72, '%') );
					// }
				}

				&:not(:last-child) {

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'margin-bottom', $val: calcPer( $block90sp, 72, '%') );
					}
				}

                a {
					display: block;

					&:hover {

						.image {

							&:after {
								opacity: .4;
							}

							img {
								transform: scale(1.05);
							}
						}

						.entry-list__text {
							opacity: .6;
						}
					}
                }

                .image {
                    @include per( $property: 'padding-bottom', $val: calcPer( 288, 192, '%') );
					@include per( $property: 'margin-bottom', $val: calcPer( 288, 24, '%') );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'padding-bottom', $val: calcPer( $block90sp, 460, '%') );
						@include per( $property: 'margin-bottom', $val: calcPer( $block90sp, 36, '%') );
					}

					&:after {
						display: block;
						background-color: #fff;
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						z-index: 50;
						opacity: 0;
						content: '';
						transition: all .3s $easing;
					}

					img {
						transition: all .6s $easing;
					}
				}
            }

            &__text {
				transition: all .3s $easing;

                .type {
                    @include per( $property: 'margin-bottom', $val: calcPer( 288, 6, '%') );
                    @include fs( 14 );
                    color: $c-thm-01;
                    font-weight: bold;

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'margin-bottom', $val: calcPer( $block90sp, 12, '%') );
					}
				}

                .lv6 {
					@include per( $property: 'margin', $val: 0 0 calcPer( 288, 6, '%') );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'margin-bottom', $val: calcPer( $block90sp, 12, '%') );
					}
				}

                .data {
                    display: flex;
                    @include fs( 12 );
                    color: #888888;
                }

                .publish {

					&:not(:last-child) {

						&:after {
							display: inline-block;
							margin: 0 .5em;
							content: '\0000B7';
						}
					}
                }
            }
        }
    }

    &-paging {
        text-align: center;

        .wp-pagenavi {
            display: flex;
            justify-content: center;
        }

        a, span {
            @include per( $property: 'width', $val: calcPer( $block90, 40, '%') );
            @include per( $property: 'padding', $val: calcPer( $block90, 8, '%') 0 );
            @include per( $property: 'margin', $val: 0 calcPer( $block90, 4, '%') 0 );

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'width', $val: calcPer( $block100sp, 72, '%') );
				@include per( $property: 'padding', $val: calcPer( $block100sp, 16, '%') 0 );
				@include per( $property: 'margin', $val: 0 calcPer( $block100sp, 6, '%') 0 );
			}

            &:not(.previouspostslink):not(.nextpostslink) {
                @include fs( 14 );
                @include letter( 20 );
                font-weight: bold;
                text-align: center;
                border: 1px solid #eeeeee;
            }

            &.current {
                background-color: #222;
                color: #fff;
                border-color: #222;
            }
        }

        .previouspostslink, .nextpostslink {
            text-align: center;
            position: relative;

            /* use !important to prevent issues with browser extensions that change fonts */
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;

            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            &:before {
                display: inline-block;
                content: "\e900";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }
        }

        .previouspostslink {
            @include per( $property: 'margin-right', $val: calcPer( $block90, 24, '%') );

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'margin-right', $val: calcPer( $block100sp, 67, '%') );
			}

            &:before {
                display: block;
                transform: translate(-50%,-50%) rotate(180deg);
            }
        }

        .nextpostslink {
            @include per( $property: 'margin-left', $val: calcPer( $block90, 24, '%') );

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'margin-left', $val: calcPer( $block100sp, 67, '%') );
			}
		}
	}

	&-text-contents {
		@include lh( 28, 16 );

		.block__inner {

			p, ul {
				@include letter( 50 );
			}
		}

		.text-contents {

			&-list {

				&__unit {

					&:nth-child( 1 ) {
						border-top: 1px solid #eee;
					}
				}

				&__ttl {
					@include per( $property: 'margin', $val: calcPerTypo( 24, 72, 'em') 0 calcPerTypo( 24, 36, 'em') );
					@include fs( 24 );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'margin', $val: calcPerTypo( 18, 48, 'em') 0 calcPerTypo( 18, 24, 'em') );
						@include fs( 18 );
					}
				}
			}
		}

		.lead {
			@include per( $property: 'margin-bottom', $val: calcPerTypo( 16, 72, 'em') );

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'margin-bottom', $val: calcPerTypo( 16, 48, 'em') );
			}
		}

		.list-order {
			list-style: none;
			counter-reset: number;

			li {
				@include per( $property: 'padding-left', $val: calcPerTypo( 16, 24, 'em') );
				position: relative;
				counter-increment: number;

				&:not(:last-child) {
					@include per( $property: 'margin-bottom', $val: calcPerTypo( 16, 24, 'em') );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'margin-bottom', $val: calcPerTypo( 14, 24, 'em') );
					}
				}

				&:before {
					position: absolute;
					top: 0;
					left: 0;
					content: counter(number) '.';
				}
			}
		}

		.establish {
			@include per( $property: 'margin', $val: calcPerTypo( 16, 72, 'em') 0 calcPerTypo( 16, 24, 'em') );

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'margin', $val: calcPerTypo( 14, 24, 'em') 0 calcPerTypo( 14, 48, 'em') );
			}
		}

		.sign {
			@include per( $property: 'margin-bottom', $val: calcPerTypo( 16, 72, 'em') );
			text-align: right;

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'margin-bottom', $val: calcPerTypo( 16, 48, 'em') );
			}
		}

		.box {
			background-color: #f8f8f8;
			// @include per( $property: 'padding', $val: calcPerTypo( 16, 48, 'em') );
			@include per( $property: 'padding', $val: calcPer( $block60, 48, '%') );
			@include per( $property: 'margin-top', $val: calcPer( $block60, 48, '%') );

			@include mx-mq-max($bp-sp-max) {
				// @include per( $property: 'padding', $val: calcPer( 16, 30, 'em') );
				@include per( $property: 'padding', $val: calcPer( $block60sp, 60, '%') );
				@include per( $property: 'margin-top', $val: calcPer( $block60sp, 72, '%') );
			}

			&__unit {

				&:not(:last-child) {
					@include per( $property: 'margin-bottom', $val: calcPerTypo( 16, 24, 'em') );
				}
			}

			&__ttl {
				@include per( $property: 'margin', $val: 0 0 calcPerTypo( 14, 12, 'em') );
				@include fs( 14 );
				@include lh( 21, 14 );
				font-weight: bold;

				@include mx-mq-max($bp-sp-max) {
					@include per( $property: 'margin-bottom', $val: calcPerTypo( 12, 12, 'em') );
					@include fs( 12 );
					@include lh( 21, 12 );
				}
			}

			&__text {
				@include fs( 14 );
				@include lh( 21, 14 );

				@include mx-mq-max($bp-sp-max) {
					@include per( $property: 'margin-bottom', $val: calcPerTypo( 12, 12, 'em') );
					@include fs( 12 );
					@include lh( 21, 12 );
				}

				&:not(:last-child) {
					@include per( $property: 'margin', $val: 0 0 calcPerTypo( 14, 12, 'em') );
				}
			}

			address {
				font-style: normal;
			}
		}
	}

	&-form-flow {
		@include per( $property: 'margin-bottom', $val: calcPer( $block80, 60, '%') );

		@include mx-mq-max($bp-sp-max) {
			@include per( $property: 'margin-bottom', $val: calcPer( $block90sp, 96, '%') );
		}

		.cp-form--complete & {
			@include per( $property: 'margin-bottom', $val: calcPer( $block80, 96, '%') );

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'margin-bottom', $val: calcPer( $block90sp, 96, '%') );
			}
		}

		ol {
			display: flex;
			list-style: none;
			counter-reset: number;
		}

		li {
			background-color: $c-thm-01;
			@include per( $property: 'width', $val: calcPer( $block80, 380, '%') );
			@include per( $property: 'padding', $val: calcPerTypo( 16, 18, 'em') 0 );
			font-weight: bold;
			text-align: center;
			color: #fff;
			position: relative;
			counter-increment: number;

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'width', $val: calcPer( $block90sp, 222, '%') );
				@include per( $property: 'padding', $val: calcPerTypo( 14, 13.5, 'em') 0 );
				@include fs( 14 );
			}

			&:before, &:after {
				display: block;
				border: {
					width: calcPerTypo( 16, 30, 'em') 0 calcPerTypo( 16, 30, 'em') calcPerTypo( 16, 12, 'em');
					style: solid;
					color: transparent;
				}
				position: absolute;
				top: 0;
				content: '';

				@include mx-mq-max($bp-sp-max) {
					border-width: calcPerTypo( 14, 24, 'em') 0 calcPerTypo( 14, 24, 'em') calcPerTypo( 14, 9, 'em');
				}
			}

			&:before {
				border-left-color: #fff;
				left: 0;
			}

			&:after {
				border-left-color: $c-thm-01;
				left: 100%;
			}

			&:nth-child( 1 ) {
				z-index: 100;

				&:before {
					display: none;
				}
			}

			&:nth-child( 2 ) {
				z-index: 50;
			}

			&:last-child {

				&:after {
					display: none;
				}
			}

			&:not(:last-child) {
				@include per( $property: 'margin-right', $val: calcPer( $block80, 6, '%') );

				@include mx-mq-max($bp-sp-max) {
					@include per( $property: 'margin-right', $val: calcPer( $block90sp, 12, '%') );
				}
			}

			> span {
				display: block;

				&:before {
					margin-right: 0.5em;
					content: counter(number) '.';
				}

				span {

					@include mx-mq-max($bp-sp-max) {
						display: none;
					}
				}
			}

			&.is-current {
				// background-color: $c-thm-01;
				// color: #fff;

				// &:after {
				// 	border-left-color: $c-thm-01;
				// }

				& ~ li {
					background-color: #eff4f7;
					color: $c-thm-01;

					&:after {
						border-left-color: #eff4f7;
					}
				}
			}
		}
	}

	&-form-lead {

		dl {

			@include mx-mq-max($bp-sp-max) {
				// width: 84%;
				@include per( $property: 'width', $val: calcPer( 345, 315, '%') );
				margin: 0 auto;
			}

			dt {
				@include per( $property: 'margin-bottom', $val: calcPerTypo( 18, 24, 'em') );
				@include fs( 18 );
				@include ff( noto );
				@include lh( 31.5, 18 );
				font-weight: bold;
				text-align: center;
				@include letter( 50 );

				@include mx-mq-max($bp-sp-max) {
					@include per( $property: 'margin-bottom', $val: calcPerTypo( 16, 18, 'em') );
					@include fs( 16 );
				}
			}

			p {
				margin: 0;
				@include lh( 28, 16 );
				text-align: center;
				@include letter( 50 );

				@include mx-mq-max($bp-sp-max) {
					@include fs( 14 );
				}

				&:not(:last-child) {
					@include per( $property: 'margin-bottom', $val: calcPerTypo( 16, 24, 'em') );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'margin-bottom', $val: calcPerTypo( 14, 18, 'em') );
					}
				}
			}
		}
	}

	&-form {

		.form {
			background-color: #f8f8f8;
			@include per( $property: 'padding', $val: calcPer( $block80, 72, '%') );
			@include per( $property: 'margin-top', $val: calcPer( $block80, 60, '%') );

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'padding', $val: calcPer( $block90sp, 72, '%') calcPer( $block90sp, 30, '%') );
				@include per( $property: 'margin-top', $val: calcPer( $block90sp, 48, '%') );
			}
		}

		.block__inner {

			@include mx-mq-max($bp-sp-max) {
				width: 92%;
			}
		}

		.note {
			@include per( $property: 'padding-bottom', $val: calcPerTypo( 16, 48, 'em') );
			@include per( $property: 'margin-bottom', $val: calcPerTypo( 16, 48, 'em') );
			list-style: none;
			color: #333;
			border-bottom: 1px solid #ddd;

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'padding-bottom', $val: calcPerTypo( 14, 36, 'em') );
				@include per( $property: 'margin-bottom', $val: calcPerTypo( 14, 36, 'em') );
			}

			li {
				@include per( $property: 'padding-left', $val: calcPerTypo( 14, 17, 'em') );
				@include fs( 14 );
				position: relative;

				&:before {
					position: absolute;
					top: 0;
					left: .5em;
					content: '\0000B7';
				}

				&:not(:last-child) {
					@include per( $property: 'margin-bottom', $val: calcPerTypo( 14, 6, 'em') );
				}
			}

			.attention {
				color: #e91b2e;
				font-style: normal;
			}
		}

		.hidden {
			display: none;
		}

		[class*="error"] {
			color: #e91b2e;
		}

		.error-message {
			@include per( $property: 'margin', $val: 0 0 calcPer( 1008, 48, '%') );
			transition: all .6s $easing;

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'margin-bottom', $val: calcPer( 345, 36, '%') );
			}

			p {
				background-color: #fef3f4;
				@include per( $property: 'padding', $val: calcPerTypo( 14, 11, 'em') calcPerTypo( 14, 17, 'em') );
				@include fs( 14 );
				@include letter( 50 );
				font-weight: bold;
				text-align: center;
				border-radius: 4px;
				border: 1px solid #f08993;

				@include mx-mq-max($bp-sp-max) {
					@include per( $property: 'padding', $val: calcPerTypo( 12, 11, 'em') calcPerTypo( 12, 17, 'em') );
					@include fs( 12 );
				}
			}
		}

		.form-area {

			&__block {

				&:not(:last-child) {
					@include per( $property: 'padding-bottom', $val: calcPer( 1008, 18, '%') );
					@include per( $property: 'margin-bottom', $val: calcPer( 1008, 48, '%') );
					border-bottom: 1px solid #ddd;

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'padding-bottom', $val: calcPer( 630, 12, '%') );
						@include per( $property: 'margin-bottom', $val: calcPer( 630, 72, '%') );
					}
				}
			}

			&__unit {
				display: flex;
				justify-content: space-between;
				@include per( $property: 'margin-bottom', $val: calcPerTypo( 16, 30, 'em') );

				@include mx-mq-max($bp-sp-max) {
					flex-direction: column;
				}

				dt {
					display: flex;
					justify-content: space-between;
					@include per( $property: 'width', $val: calcPer( 1008, 192, '%') );
					@include per( $property: 'padding', $val: calcPerTypo( 14, 6, 'em') 0 );
					@include fs( 14 );
					@include lh( 24.5, 14 );
					font-weight: bold;

					@include mx-mq-max($bp-sp-max) {
						width: 100%;
						padding: 0;
						@include per( $property: 'margin-bottom', $val: calcPerTypo( 14, 12, 'em') );
					}

					.essential {
						display: block;
						background-color: #e91b2e;
						@include per( $property: 'padding', $val: 0 calcPerTypo( 14, 6, 'em') );
						color: #fff;
						font-style: normal;
						border-radius: 4px;
						white-space: nowrap;
					}
				}

				dd, li, p {
					@include fs( 14 );
					@include lh( 24.5, 14 );
				}

				dd {
					// width: calc(75% + 24px);
					// @include calc( width, '#{calcPer( 1008, 792, "%")} + 24px');
					// padding-left: 24px;
					@include per( $property: 'width', $val: calcPer( 1008, 816, '%') );
					@include per( $property: 'padding-left', $val: calcPer( 1008, 24, '%') );

					@include mx-mq-max($bp-sp-max) {
						width: 100%;
						padding-left: 0;
					}
				}

				.parsley-errors-list {
					list-style: none;
				}

				// .parsley-custom-error-message {
				// 	@include per( $property: 'padding-top', $val: calcPerTypo( 14, 8, 'em') );
				// 	@include lh( 21, 14 );
				// }
			}

			&__parts {

				// input {
				// 	width: 100%;
				// }

				&--l {

					input, textarea, select {

						@include per( $property: 'width', $val: calcPer( 792, 600, '%') );

						@include mx-mq-max($bp-sp-max) {
							width: 100%;
						}
					}
				}

				&--m {

					input, textarea, select {
						@include per( $property: 'width', $val: calcPer( 792, 360, '%') );

						@include mx-mq-max($bp-sp-max) {
							width: 100%;
						}
					}
				}

				&--s {

					input, textarea, select {
						@include per( $property: 'width', $val: calcPer( 792, 240, '%') );

						@include mx-mq-max($bp-sp-max) {
							width: 100%;
						}
					}
				}
			}

			&__check-group {
				display: flex;
				flex-wrap: wrap;
				// justify-content: space-between;
				list-style: none;

				li {
					@include per( $property: 'width', $val: calcPer( 792, 256, '%') );

					@include mx-mq-max($bp-sp-max) {
						width: 100%;
					}

					&:not(:nth-child(3n+3)) {
						@include per( $property: 'margin-right', $val: calcPer( 792, 12, '%') );

						@include mx-mq-max($bp-sp-max) {
							margin-right: 0;
						}
					}

					&:not(:nth-child(-n+3)) {
						@include per( $property: 'margin-top', $val: calcPer( 792, 12, '%') );

						// @include mx-mq-max($bp-sp-max) {
						// 	@include per( $property: 'margin-top', $val: calcPer( $block90sp, 24, '%') );
						// }
					}

					&:not(:nth-child(1)) {

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'margin-top', $val: calcPer( $block90sp, 24, '%') );
						}
					}
				}

				label {
					display: block;
					height: 100%;
					position: relative;
				}

				span {
					display: flex;
					align-items: center;
					background-color: #fff;
					height: 100%;
					@include per( $property: 'padding', $val: calcPerTypo( 14, 15, 'em') calcPerTypo( 14, 16, 'em') calcPerTypo( 14, 15, 'em') calcPerTypo( 14, 34, 'em') );
					border: 1px solid #ddd;
					border-radius: 4px;

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'padding', $val: calcPerTypo( 14, 15.5, 'em') calcPerTypo( 14, 15.5, 'em') calcPerTypo( 14, 15.5, 'em') calcPerTypo( 14, 35.5, 'em') );
					}
				}

				&.parsley-error {

					span {
						border-color: #f08993;
					}
				}

				input {
					position: absolute;
					top: 50%;
					@include per( $property: 'left', $val: calcPerTypo( 14, 14, 'em') );
					transform: translateY( -50% );

					&:checked {

						& + span {
							background-color: #eff4f7;
							border-color: #c9deea;
						}
					}
				}
			}

			&__btns {
				display: flex;
				justify-content: center;
				list-style: none;

				@include mx-mq-max($bp-sp-max) {
					flex-direction: column;
				}

				li {
					@include per( $property: 'width', $val: calcPer( 1008, 360, '%') );
					@include per( $property: 'margin', $val: 0 calcPer( 1008, 12, '%') );

					@include mx-mq-max($bp-sp-max) {
						width: 100%;
						margin: 0;

						&:not(:last-child) {
							@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 48, '%') );
						}
					}
				}
			}

			a {
				text-decoration: underline;
				color: $c-thm-01;
			}

			label {
				cursor: pointer;
			}

			.privacy-note {

				input {
					@include per( $property: 'margin-right', $val: calcPerTypo( 14, 10, 'em') );
					position: relative;
					top: -1px;
				}

				p {
					@include per( $property: 'margin', $val: calcPerTypo( 14, 72, 'em') 0 calcPerTypo( 14, 36, 'em') );
					@include fs( 14 );
					text-align: center;

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'margin', $val: 0 0 calcPerTypo( 14, 24, 'em') );
					}
				}
			}
		}

		[id^="error-"] {
			// @include per( $property: 'padding-top', $val: calcPerTypo( 14, 8, 'em') );
			// @include lh( 21, 14 );
			line-height: 0;
			opacity: 0;
			visibility: hidden;
			transition: padding-top 0.25s ease, line-height 0.25s ease, opacity 0.25s ease, visibility 0s 0.25s;
		}

		input[type="text"], textarea {
			padding: .5em;
			border: 1px solid #ddd;
			border-radius: 4px;

			// & + [id^="error-"] {
			// }

			&.parsley-error {
				border-color: #f08993;

				& + [id^="error-"] {
					@include per( $property: 'padding-top', $val: calcPerTypo( 14, 8, 'em') );
					line-height: 1.5;
					opacity: 1;
					visibility: visible;
					transition: padding-top 0.25s ease, line-height 0.25s ease, opacity 0.25s ease, visibility 0s 0s;
				}
			}
		}

		.parsley-error {

			& + [id^="error-"] {
				@include per( $property: 'padding-top', $val: calcPerTypo( 14, 8, 'em') );
				line-height: 1.5;
				opacity: 1;
				visibility: visible;
				transition: padding-top 0.25s ease, line-height 0.25s ease, opacity 0.25s ease, visibility 0s 0s;
			}
		}

		textarea {
			width: 100%;
			max-width: 100%;
			min-height: 226px;
			resize: vertical;
		}

		select {
			// @include per( $property: 'width', $val: calcPerTypo( 14, 247, 'em') );
			width: 100%;
			@include calc( height, '#{calcPerTypo( 14, 38, 'em')} + 2px' );
			border: 1px solid #ddd;
			// @include per( $property: 'padding', $val: calcPerTypo( 14, 12, 'em') 0 calcPerTypo( 14, 12, 'em') calcPerTypo( 14, 8, 'em') );
			// -webkit-appearance: none;
			// -moz-appearance: none;
			// appearance: none;
		}

		&.cp-form {

			&--confirm {

				.form-area {

					&__unit {

						dt {
							// @include per( $property: 'padding', $val: calcPerTypo( 14, 6, 'em') 0 calcPerTypo( 14, 6, 'em') calcPer( 1008, 24, '%') );
							padding: 0;
						}
					}

					&__check-group {
						flex-direction: column;

						li {

							&:not(:nth-child(1)) {
								@include per( $property: 'margin', $val: calcPerTypo( 14, 12, 'em') 0 0 );
							}
						}
					}
				}
			}
		}
	}

	&-message {

		> [class*="lv"] {
			@include per( $property: 'margin', $val: calcPerTypo( 18, 60, 'em') 0 calcPerTypo( 18, 36, 'em') );
			@include lh( 31.5, 18 );
			text-align: center;

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'margin', $val: calcPerTypo( 16, 48, 'em') 0 calcPerTypo( 16, 24, 'em') );
				@include fs( 16 );
				@include lh( 20, 16 );
			}
		}

		.movie {
			@include per( $property: 'width', $val: calcPer( $block60, 720, '%') );
			@include per( $property: 'padding-bottom', $val: calcPer( 720, 405, '%') );
			margin: 0 auto;
			position: relative;

			@include mx-mq-max($bp-sp-max) {
				width: 100%;
			}
		}

		.image {
			@include per( $property: 'width', $val: calcPer( $block90, 720, '%') );
			margin: 0 auto;

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'width', $val: calcPer( $block90sp, 630, '%') );
			}
		}

		iframe {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&-theme-list {

		> [class*="lv"] {
			@include per( $property: 'margin', $val: 0 0 calcPerTypo( 18, 36, 'em') );
			@include lh( 31.5, 18 );
			text-align: center;

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'margin', $val: 0 0 calcPerTypo( 16, 24, 'em') );
				@include lh( 20, 16 );
			}
		}

		.theme-list {

			&__container {
				list-style: none;

				@include mx-mq-max($bp-sp-max) {
					@include per( $property: 'width', $val: calcPer( 690, 630, '%') );
					margin: 0 auto;
				}
			}

			&__unit {

				&:not(:last-child){
					@include per( $property: 'margin-bottom', $val: calcPer( $block90, 24, '%') );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 30, '%') );
					}
				}

				a {
					display: flex;
					// align-items: center;
					background-color: #f8f8f8;

					@include mx-mq-max($bp-sp-max) {
						flex-direction: column;
					}

					&:hover {

						.text {
							opacity: .6;
						}

						.image {

							&:after {
								background-color: hsla(0,100%,100%,.4);
							}

							img {
								transform: scale(1.05);
							}
						}
					}
				}

				.text {
					flex: 1;
					@include per( $property: 'padding', $val: calcPer( $block90, 72, '%') );
					transition: all .3s $easing;

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'padding', $val: calcPer( $block80sp, 60, '%') );
					}

					dt {
						@include per( $property: 'margin-bottom', $val: calcPerTypo( 20, 24, 'em') );
						@include fs( 20 );
						@include lh( 35, 20 );
						@include letter( 50 );
						font-weight: bold;

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'margin-bottom', $val: calcPerTypo( 16, 18, 'em') );
							@include fs( 16 );
							@include lh( 28, 16 );
						}
					}

					dd {
						@include lh( 28, 16 );
					}
				}

				.image {
					@include per( $property: 'width', $val: calcPer( $block90, 504, '%') );
					// @include per( $property: 'padding-bottom', $val: calcPer( $block90, 288, '%') );
					position: relative;

					@include mx-mq-max($bp-sp-max) {
						width: 100%;
						@include per( $property: 'padding-bottom', $val: calcPer( $block80sp, 420, '%') );
					}

					&:before, &:after {
						display: block;
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						content: '';
					}

					&:before {
						background-color: hsla(0, 0%, 27%, .25);
						z-index: 10;
					}

					&:after {
						background-color: hsla(0,100%,100%,0);
						transition: all .3s $easing;
					}

					img {
						transition: all .6s $easing;
					}
				}

				.title {
					text-align: center;
					color: #fff;
					position: absolute;
					top: 50%;
					right: 0;
					left: 0;
					z-index: 20;
					transform: translateY(-50%);

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'padding', $val: 0 calcPer( $block80sp, 30, '%') );
					}

					[class*="lv"] {
						display: block;
						@include per( $property: 'margin', $val: 0 0 calcPerTypo( 36, 18, 'em') );
						@include lh( 45, 36 );

						@include mx-mq-max($bp-sp-max) {
							@include fs( 24 );

							br {
								display: none;
							}
						}
					}

					.btn {
						display: inline-block;
						// @include per( $property: 'width', $val: calcPer( 504, 130, '%') );
						@include per( $property: 'width', $val: calcPerTypo( 14, 130, 'em') );
						@include per( $property: 'padding', $val: calcPerTypo( 14, 5, 'em') calcPerTypo( 14, 17, 'em') );
						margin: 0 auto;
						@include fs( 14 );
						font-weight: bold;
						border: 1px solid #ffffff;
						@include per( $property: 'border-radius', $val: calcPerTypo( 14, 16.5, 'em') );

						@include mx-mq-max($bp-sp-max) {
							width: auto;
							// @include per( $property: 'width', $val: calcPer( $block80sp, 206, '%') );
							@include per( $property: 'padding', $val: calcPerTypo( 12, 5, 'em') calcPerTypo( 12, 17, 'em') );
							@include fs( 12 );
							@include lh( 18, 12 );
							@include per( $property: 'border-radius', $val: calcPerTypo( 12, 15, 'em') );
						}
					}
				}
			}
		}
	}

	&-download-banner {

		.download-banner-container {
			@include ff( noto );
			font-weight: bold;

			a {
				// display: block;
				display: flex;
				align-items: center;
				// justify-content: center;
				// flex-direction: column;
				background: url(../img/bg_banner.jpg) no-repeat 50% 50%;
				background-size: cover;
				min-height: 160px;
				@include per( $property: 'padding', $val: calcPer( $block60, 36, '%') calcPerTypo( 16, 66, 'em') calcPer( $block60, 36, '%') calcPer( $block60, 280, '%') );
				// @include per( $property: 'padding', $val: calcPer( $block60, 36, '%') calcPerTypo( 16, 66, 'em') calcPer( $block60, 36, '%') 280px );
				color: #fff;
				border-radius: 6px;
				box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
				position: relative;
				transition: opacity .3s $easing;

				@include mx-mq-max($bp-sp-max) {
					min-height: inherit;
					@include per( $property: 'padding', $val: calcPer( $block60sp, 48, '%') 0 calcPer( $block60sp, 347, '%') calcPer( $block60sp, 48, '%') );
				}

				&:before {
					display: block;
					background: url(../img/img_case.png) no-repeat 50% 100%;
					background-size: contain;
					@include per( $property: 'width', $val: calcPer( $block60, 240, '%') );
					// width: 240px;
					// height: 100%;
					// @include per( $property: 'padding-bottom', $val: calcPer( $block60, 144, '%') );
					position: absolute;
					@include per( $property: 'top', $val: calcPer( 160, 20, '%') );
					@include per( $property: 'left', $val: calcPer( $block60, 19, '%') );
					bottom: 0;
					content: '';

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'width', $val: calcPer( $block60sp, 534, '%') );
						@include per( $property: 'padding-bottom', $val: calcPer( $block60sp, 320, '%') );
						left: 50%;
						transform: translateX(-50%);
					}
				}

				&:after {
					display: block;
					border: 1px solid hsla(0, 0%, 13%, .15);
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					border-radius: 3px;
					content: '';
				}

				&:hover {
					opacity: .6;
				}
			}

			.text {

				@include mx-mq-max($bp-sp-max) {
					@include per( $property: 'padding-right', $val: calcPer( $block60sp - 48, 114, '%') );
					position: relative;
				}
			}

			[class^="icon-"] {
				display: block;
				@include per( $property: 'width', $val: calcPerTypo( 12, 30, 'em') );
				@include per( $property: 'height', $val: calcPerTypo( 12, 30, 'em') );
				border: 1px solid #fff;
				text-align: center;
				border-radius: 50%;

				@include fs( 12 );
				position: absolute;
				top: 50%;
				@include per( $property: 'right', $val: calcPerTypo( 12, 24, 'em') );
				transform: translateY( -50% );

				@include mx-mq-max($bp-sp-max) {
					@include per( $property: 'right', $val: calcPerTypo( 12, 15, 'em') );
				}

				&:before {
					margin-left: 2px;
					color: #fff;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate( -50%, -50% );
				}
			}

			dt {
				@include per( $property: 'margin-bottom', $val: calcPerTypo( 20, 3, 'em') );
				@include fs( 20 );
				@include lh( 35, 20 );
				@include letter( 50 );

				@include mx-mq-max($bp-sp-max) {
					@include per( $property: 'margin-bottom', $val: calcPerTypo( 16, 6, 'em') );
					@include fs( 16 );
					@include lh( 24, 16 );
				}
			}

			dd {
				@include fs( 14 );
				@include lh( 24.5, 14 );
				@include letter( 50 );

				@include mx-mq-max($bp-sp-max) {
					@include fs( 12 );
				}
			}
		}
	}

	&-px {

		&:not(:last-child) {
			@include per( $property: 'margin-bottom', $val: calcPer( $block70, 60, '%') );

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'margin-bottom', $val: calcPer( $block70sp, 96, '%') );
			}
		}

		> [class*="lv5"] {
			@include per( $property: 'margin', $val: 0 0 calcPer( $block70, 36, '%') );
			text-align: center;
			@include lh( 31.5, 18 );

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'margin-bottom', $val: calcPer( $block70sp, 48, '%') );
				@include lh( 20, 16 );
			}
		}

		.px {

			&__container {
				@include per( $property: 'margin-bottom', $val: calcPer( $block70, 48, '%') );
				border-top: 1px solid #eeeeee;

				@include mx-mq-max($bp-sp-max) {
					@include per( $property: 'margin-bottom', $val: calcPer( $block70sp, 72, '%') );
				}
			}

			&__unit {

				&:nth-child(n+6) {
					display: none;
				}
			}

			&__inner {
				display: flex;
				align-items: center;
				@include per( $property: 'padding', $val: calcPerTypo( 16, 30, 'em') 0 );
				border-bottom: 1px solid #eeeeee;

				@include mx-mq-max($bp-sp-max) {
					align-items: flex-start;
				}

				.icon {
					@include per( $property: 'width', $val: calcPerTypo( 16, 96, 'em') );
					@include per( $property: 'padding', $val: 0 calcPerTypo( 16, 24, 'em') );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'width', $val: calcPerTypo( 16, 63, 'em') );
						@include per( $property: 'padding', $val: calcPerTypo( 16, 6, 'em') calcPerTypo( 16, 15, 'em') calcPerTypo( 16, 6, 'em') 0 );
					}
				}

				.text {
					flex: 1;

					[class*="lv"] {
						margin: 0;
						@include lh( 28, 16 );
					}

					p {
						@include per( $property: 'margin', $val: calcPerTypo( 14, 6, 'em') 0 0 );
						@include fs( 14 );
						@include lh( 24.5, 14 );
						@include letter( 50 );
					}

					ul {
						display: flex;
						flex-wrap: wrap;
						list-style: none;
						@include fs( 12 );
						@include letter( 50 );
					}

					li {
						@include per( $property: 'padding', $val: calcPerTypo( 12, 5, 'em') calcPerTypo( 12, 17, 'em') );
						@include per( $property: 'margin', $val: calcPerTypo( 12, 12, 'em') calcPerTypo( 12, 6, 'em') 0 0 );
						color: $c-thm-01;
						font-weight: bold;
						border: 1px solid currentColor;
						@include per( $property: 'border-radius', $val: calcPerTypo( 12, 15, 'em') );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'padding', $val: calcPerTypo( 12, 7, 'em') calcPerTypo( 12, 15, 'em') );
							@include per( $property: 'border-radius', $val: calcPerTypo( 12, 17, 'em') );
						}
					}
				}
			}
		}

		.link-btn {
			width: 240px;
			margin: 0 auto;

			@include mx-mq-max($bp-sp-max) {
				width: 100%;
			}
		}
	}
}
