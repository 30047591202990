/*
 * Montserrat Regular: 400, Medium: 500, Bold: 700
 *
 * Layout
 * $block90: 1296px; / .cp-block-90 --- 1296px / 1440px
 * $block80: 1152px; / .cp-block-80 --- 1152px / 1440px
 * $block70: 1008px; / .cp-block-70 --- 1008px / 1440px
 * $block60:  864px; / .cp-block-60 ---  864px / 1440px
 */

/****************************************************
 * STATE
 */
.is {

	&-vsb {

		&-pc {

			@include mx-mq-max( $bp-sp ) {
				display: none;
			}
		}

		&-sp {
			display: none;

			@include mx-mq-max( $bp-sp ) {
				display: block;
			}
		}
	}

	&-off {
		pointer-events: none;
		cursor: default;
	}

	&-wauto {
		width: auto;
	}
}
