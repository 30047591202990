@charset "UTF-8";

// main: style.scss


// Media queries
// -------------------------------------------------

@mixin mx-mq-max($break-point, $if: "lgt") {
  @if $if == "gt" {
    @media screen and (max-width: $break-point + 1 ) {
      @content;
    }
  }
  @if $if == "lgt" {
    @media screen and (max-width: $break-point ) {
      @content;
    }
  }
}

@mixin mx-mq-min($break-point, $if: "lgt") {
  @if $if == "gt" {
    @media screen and (min-width: $break-point + 1) {
      @content;
    }
  }
  @if $if == "lgt" {
    @media screen and (min-width: $break-point) {
      @content;
    }
  }
}

@mixin mx-mq-min-max($break-point-min, $break-point-max) {
  @media screen and (min-width: $break-point-min + 1) and (max-width: $break-point-max) {
    @content;
  }
}

// Typography Components
// -------------------------------------------------

@mixin mx-font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


// Font Size Scaling
// -------------------------------------------------

//  default font scale bounding
$font-lock-min:  768px !default;
$font-lock-max: 1600px !default;

//  font-size
@mixin mx-font-size-scaling(
  $min,                       // font-size
  $max: null,                 // optional maximum font size
  $lock-min: $font-lock-min,  // optional minimum viewport width
  $lock-max: $font-lock-max   // optional maximum viewport width
){
  @if $max {
    & {
      font-size: calc(
        #{$min} +
        #{num($max) - num($min)} *
        (100vw - #{$lock-min}) /
        #{num($lock-max) - num($lock-min)}
      );
    }
  }
}

@mixin mx-font-size-scaling-pack(
  $min,                       // font-size
  $max: null,                 // optional maximum font size
  $lock-min: $font-lock-min,  // optional minimum viewport width
  $lock-max: $font-lock-max,  // optional maximum viewport width
  $lock: "none"               // optional output max/min font size
){
  @if $lock != "none" {
    @media (max-width: $lock-min){
      & { font-size: $min; }
    }
  }
  @if $max {
    @media (min-width: $lock-min) and (max-width: $lock-max){
      & {
        font-size: calc(
          #{$min} +
          #{num($max) - num($min)} *
          (100vw - #{$lock-min}) /
          #{num($lock-max) - num($lock-min)}
        );
      }
    }
  }
  @if $lock != "none" {
    @media (min-width: $lock-max){
      & { font-size: $max; }
    }
  }
}

//  strip units helper function
@function num($input){
  @return $input/($input * 0 + 1);
}

// Font CSS Package
// -------------------------------------------------

@mixin mx-font-pack($size: "none", $line: "none", $weight: "none", $tracking: "none", $base-size: 16) {
  @if $size != "none" {
    font-size: $size + px;
    font-size: ($size / $base-size) * 1rem;
  }
  @if $weight != "none" {
    font-weight: $weight;
  }
  @if $line != "none" {
    line-height: $line;
  }
  @if $tracking != "none" {
    letter-spacing: $tracking / 1000 + em;
  }
}

// Initializing Element
// -------------------------------------------------

@mixin mx-block-init($position: "none", $display: "none", $width: "none", $height: "none") {
  @if $position != "none" {
    position: $position;
  }
  @if $display != "none" {
    display: $display;
  }
  @if $width != "none" {
    width: $width;
  }
  @if $height != "none" {
    height: $height;
  }
  @content;
}

@mixin mx-a-init($display: "none", $width: "none", $height: "none") {
  @if $display != "none" {
    display: $display;
  }
  @if $width != "none" {
    width: $width;
  }
  @if $height != "none" {
    height: $height;
  }
  @content;
  text-decoration: none;
}

// Translate3D Animation Package
// -------------------------------------------------

@mixin mx-translate3D-anim($x: 0, $y: 0, $z: 0, $style: "transform", $duration: 0s, $delay: 0s, $easing: "ease") {
  transform: translate3D($x, $y, $z);
  transition: $style $duration $delay $easing;
  @content;
}

// Font CSS Package
// -------------------------------------------------

@mixin mx-hover-opacity($alpha: 0.5, $duration: 0.5s, $easing: ease) {
  transition: opacity $duration $easing;
  &:hover {
    opacity: $alpha;
  }
}

@mixin mx-position-center($horizontal: 50%, $vertical: 50%) {
  position: absolute;
  left: $horizontal;
  top: $vertical;
  transform: translate($horizontal * -1, $vertical * -1);
}

@mixin mx-text-hide() {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

@mixin mx-clearfix() {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
