@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?uxha60');
  src:  url('../fonts/icomoon.eot?uxha60#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?uxha60') format('truetype'),
    url('../fonts/icomoon.woff?uxha60') format('woff'),
    url('../fonts/icomoon.svg?uxha60#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-linkedin:before {
  content: "\e906";
}
.icon-check:before {
  content: "\e904";
  color: #0b78b1;
}
.icon-close:before {
  content: "\e905";
  color: #0b78b1;
}
.icon-arrow-line:before {
  content: "\e903";
  color: #0b78b1;
}
.icon-list:before {
  content: "\e910";
}
.icon-twitter:before {
  content: "\e90f";
}
.icon-facebook:before {
  content: "\e90e";
}
.icon-instagram:before {
  content: "\e90d";
}
.icon-search:before {
  content: "\e90c";
}
.icon-gem:before {
  content: "\e90a";
  color: #0b78b1;
}
.icon-team:before {
  content: "\e90b";
  color: #0b78b1;
}
.icon-arrow:before {
  content: "\e900";
  color: #0b78b1;
}
.icon-expand:before {
  content: "\e901";
  color: #0b78b1;
}
.icon-global:before {
  content: "\e902";
  color: #0b78b1;
}
.icon-hamburger:before {
  content: "\e908";
  color: #0b78b1;
}
.icon-mail:before {
  content: "\e909";
  color: #fff;
}
