/*
 * Montserrat Regular: 400, Medium: 500, Bold: 700
 *
 * Layout
 * $block90: 1296px; / .cp-block-90 --- 1296px / 1440px
 * $block80: 1152px; / .cp-block-80 --- 1152px / 1440px
 * $block70: 1008px; / .cp-block-70 --- 1008px / 1440px
 * $block60:  864px; / .cp-block-60 ---  864px / 1440px
 */

 /****************************************************
 * MODULE
 */
.m {

	&-ofi {
		position: relative;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			font-family: 'object-fit: cover;';
		}

		// .ie11 & {
		// 	height: auto;
		// }
	}

	&-ttl {
		margin: 0;
		text-align: center;
		font-weight: bold;
		@include lh( 45, 36 );

		.cp-block-80 & {
			@include per( $property: 'margin-bottom', $val: calcPer( $block80, 60, '%') );
		}
	}

	&-hdr {

		.cp-block-90 & {
			@include per( $property: 'margin', $val: 0 0 calcPer( $block90, 60, '%') );

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'margin-bottom', $val: calcPer( $block90sp, 72, '%') );
			}
		}

		.cp-block-80 & {
			@include per( $property: 'margin', $val: 0 0 calcPer( $block80, 60, '%') );

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 72, '%') );
			}
		}

		.m-ttl {

			.cp-block-90 & {
				@include per( $property: 'margin', $val: 0 0 calcPer( $block90, 24, '%') );

				@include mx-mq-max($bp-sp-max) {
					@include per( $property: 'margin', $val: 0 0 calcPer( $block90sp, 36, '%') );
				}
			}

			.cp-block-80 & {
				@include per( $property: 'margin', $val: 0 0 calcPer( $block80, 24, '%') );

				@include mx-mq-max($bp-sp-max) {
					@include per( $property: 'margin', $val: 0 0 calcPer( $block80sp, 36, '%') );
				}
			}
		}

		.lead {
			margin: 0;
			text-align: center;
			@include lh( 28, 16 );
			@include letter( 50 );

			@include mx-mq-max($bp-sp-max) {
				// @include per( $property: 'width', $val: calcPer( $block90sp, 631, '%') );
				// margin: 0 auto;
				// @include letter( 50 );

				br {
					display: none;
				}
			}
		}
	}

	&-border {

		&--top {
			@include per( $property: 'padding-top', $val: calcPer( 1440, 96, '%') );
			position: relative;

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'padding-top', $val: calcPer( $block100sp, 120, '%') );
			}

			&:before {
				display: block;
				background-color: #eee;
				height: 1px;
				position: absolute;
				top: 0;
				content: '';
			}

			&.cp-block-90:before {
				right: 5%;
				left: 5%;

				@include mx-mq-max($bp-sp-max) {
					right: 4%;
					left: 4%;
				}
			}

			&.cp-block-80:before {
				right: 10%;
				left: 10%;

				@include mx-mq-max($bp-sp-max) {
					right: 8%;
					left: 8%;
				}
			}

			&.cp-block-70:before {
				right: 15%;
				left: 15%;

				@include mx-mq-max($bp-sp-max) {
					right: 8%;
					left: 8%;
				}
			}

			&.cp-block-60:before {
				right: 20%;
				left: 20%;

				@include mx-mq-max($bp-sp-max) {
					right: 8%;
					left: 8%;
				}
			}
		}

		&--bottom {
			@include per( $property: 'padding-bottom', $val: calcPer( 1440, 96, '%') );
			position: relative;

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'padding-bottom', $val: calcPer( $block100sp, 120, '%') );
			}

			&:after {
				display: block;
				background-color: #eee;
				height: 1px;
				position: absolute;
				bottom: 0;
				content: '';

				@include mx-mq-max($bp-sp-max) {
					right: 4%;
					left: 4%;
				}
			}

			&.cp-block-90:after {
				right: 5%;
				left: 5%;

				@include mx-mq-max($bp-sp-max) {
					right: 4%;
					left: 4%;
				}
			}

			&.cp-block-80:after {
				right: 10%;
				left: 10%;

				@include mx-mq-max($bp-sp-max) {
					right: 8%;
					left: 8%;
				}
			}

			&.cp-block-70:after {
				right: 15%;
				left: 15%;

				@include mx-mq-max($bp-sp-max) {
					right: 8%;
					left: 8%;
				}
			}

			&.cp-block-60:after {
				right: 20%;
				left: 20%;

				@include mx-mq-max($bp-sp-max) {
					right: 8%;
					left: 8%;
				}
			}
		}
	}
}
