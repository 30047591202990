$font-size--base: 16;
// @include mixin-name();
@mixin fs( $target, $basesize: 16, $context: $font-size--base ) {
	@if $basesize != 16 {
		font-size: unquote( $target / $basesize + "rem" );
	} @else {
		font-size: unquote( $target / $context + "rem" );
	}
	// font-size: unquote($target / $font-size--base + "rem");

	// @include mx-mq-min-max( 799px, 959px) {
	// 	font-size: unquote( $target / 12 + "rem" );
	// }
}

%ff--yugo { font-family: "游ゴシック", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Osaka, "ＭＳ ゴシック", "MS Gothic", sans-serif; }
%ff--hira { font-family: "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック", "Yu Gothic", YuGothic, "メイリオ", Meiryo, Osaka, "ＭＳ ゴシック", "MS Gothic", sans-serif; }
// %ff--hiragino { font-family: "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック", "Yu Gothic", YuGothic, "メイリオ", Meiryo, Osaka, "ＭＳ ゴシック", "MS Gothic", sans-serif; }
// %ff--roboto { font-family: 'Roboto', sans-serif; }
// %ff--roboto-slab { font-family: 'Roboto Slab', serif; }
// %ff--noto { font-family: 'Noto Sans Japanese', sans-serif; }
// %ff--open-sans { font-family: 'Open Sans', sans-serif; }
// %ff--carrois-gothic { font-family: 'Carrois Gothic', sans-serif; }
%ff--roboto { font-family: 'Roboto', sans-serif; }
// %ff--crimson { font-family: 'Crimson Text', serif; }
// %ff--cinzel { font-family: 'Cinzel', serif; }
// %ff--hannari { font-family: 'Hannari'; }
// %ff--bebas { font-family: 'Bebas'; }
// %ff--raleway { font-family: 'Raleway', sans-serif; }
%ff--ptsans { font-family: 'PT Sans', sans-serif; }
// %ff--montserrat { font-family: "montserrat-font"; }
%ff--montserrat { font-family: 'Montserrat', sans-serif; }
%ff--noto {
	font-family: Montserrat, "notosans-font", sans-serif;

	.en & {
		font-family: "notosans-font", sans-serif;
	}
}
// %ff--en-bold {
// 	font-family: HelveticaNeueLTStd, "HelveticaNeue-Bold", Arial, sans-serif;
// }

@mixin ff( $name ) {
	@if $name == noto {
		@extend %ff--noto;
	} @else if $name == montserrat {
		@extend %ff--montserrat;
	} @else if $name == yugo {
		@extend %ff--yugo;
	} @else if $name == hira {
		@extend %ff--hira;
	}
}

%writing-v {
	writing-mode: tb-rl; /*IE*/
	-o-writing-mode: vertical-rl; /*Opera*/
	-webkit-writing-mode: vertical-rl; /*Chrome, Safari*/
	-ms-writing-mode: tb-rl;
	writing-mode: vertical-rl; /*Firefox, Edge*/
}
%writing-upright {
	-webkit-text-orientation: upright;
	text-orientation: upright;
}
%writing-h {
	writing-mode: lr-tb; /*IE*/
	-o-writing-mode: horizontal-tb; /*Opera*/
	-webkit-writing-mode: horizontal-tb; /*Chrome, Safari*/
	-ms-writing-mode: lr-tb;
	writing-mode: horizontal-tb; /*Firefox, Edge*/
}

@mixin writing-v() {
	@extend %writing-v;
}

// @mixin writing-h() {
// 	@extend %writing-h;
// }

@mixin writing-upright() {
	@extend %writing-upright;
}
