@import '../../_scss/vendors/reset-sanitize';
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_appearance.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_breakpoint.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_browser.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_calc.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_filter.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_font.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_hack.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_icon.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_keyframe.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_layout.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_letter-spacing.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_line-height.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_mixins.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_percent.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_text.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/variable/_breakpoint.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/variable/_color.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/variable/_font.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/variable/_margin.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/variable/_width.scss";
@import '../../_scss/webfont/icomoon';
@import '../../_scss/webfont/fonts';
@import '../../_scss/component/override';
@import '../../_scss/vendors/slick';
@import '../../_scss/vendors/photoswipe';
@import '../../_scss/vendors/photoswipe-default-skin';

$path: '../img/';
$easing: cubic-bezier(0.28, 0.11, 0.32, 1);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);
/*
 * Montserrat Regular: 400, Medium: 500, Bold: 700
 *
 * Layout
 * $block90: 1296px; / .cp-block-90 --- 1296px / 1440px
 * $block80: 1152px; / .cp-block-80 --- 1152px / 1440px
 * $block70: 1008px; / .cp-block-70 --- 1008px / 1440px
 * $block60:  864px; / .cp-block-60 ---  864px / 1440px
 */

@import './m';
@import './cp';
@import './is';
@import './override';

/****************************************************
 * STRUCTURE
 */
.st {

	&-container {
		// min-width: $bp-tb-max;
		padding-top: 84px;
		margin: 0 auto;
		position: relative;
		z-index: 100;
		overflow: hidden;

		@include mx-mq-max($bp-tb-max) {
			padding-top: 84px;
		}

		@include mx-mq-max($bp-sp-max) {
			padding-top: 60px;
		}

		&--fixed {
			min-width: 1000px;
			margin: 0 auto;
		}
	}

	&-hdr {
	}

	&-ftr {
		background-color: $c-thm-02;
		color: #fff;
	}
}

/****************************************************
 * PARTS
 */
.link {

	&-text {
		text-decoration: underline;
		color: currentColor;
		transition: all .3s $easing;

		&--b {
			color: $c-thm-01;
		}

		&:hover {
			opacity: .6;
		}
	}

	&-container {
		list-style: none;

		&--horizontal {
			display: flex;

			.link-btn {
				list-style: none;

				&:not(:last-child) {
					@include per( $property: 'margin-right', $val: calcPerTypo( 16, 12, 'em') );
				}
			}
		}

		&--text {
			font-weight: bold;

			li {

				&:not(:last-child) {
					@include per( $property: 'margin-bottom', $val: calcPerTypo( 14, 12, 'em') );

					@include mx-mq-max( $bp-sp ) {
						@include per( $property: 'margin-bottom', $val: calcPer( $block80sp, 24, '%') );
					}
				}
			}

			[class^="icon-"] {
				margin-left: 0.25em;
			}
		}
	}

	&-btn {
		max-width: 100%;

		a, span {
			background-color: #222;
			color: #fff;
			font-weight: bold;
			text-align: center;
			@include letter( 50 );
			position: relative;
			// border: 1px solid #222;
			transition: all .3s $easing;

			&:after {
				display: block;
				border: 1px solid #222;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				content: '';
				transition: all .3s $easing;
			}

			&:hover {
				background-color: $c-thm-01;

				&:after {
					border-color: $c-thm-01;
				}
			}
		}

		span {
			cursor: pointer;
		}

		&--center {
			text-align: center;
		}

		&--fixed {
			margin: 0 auto;

			&--s {

				a, span {
					display: block;
					// @include per( $property: 'padding', $val: calcPerTypo( 14, 6, 'em') calcPerTypo( 14, 18, 'em') );
					@include per( $property: 'padding', $val: calcPerTypo( 14, 6, 'em') 0 );
					@include fs( 14 );
					@include lh( 21, 14 );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'padding', $val: calcPerTypo( 12, 6, 'em') 0 );
						@include fs( 12 );
					}
				}
			}

			&--l {

				a, span {
					display: block;
					// @include per( $property: 'padding', $val: calcPerTypo( 16, 12, 'em') calcPerTypo( 16, 24, 'em') );
					@include per( $property: 'padding', $val: calcPerTypo( 16, 12, 'em') 0 );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'padding', $val: calcPerTypo( 14, 14, 'em') 0 );
						@include fs( 14 );
					}
				}
			}
		}

		&--form {
			display: block;
			background-color: transparent;
			width: 100%;
			padding: 0;

			span {
				display: block;
				@include per( $property: 'padding', $val: calcPerTypo( 16, 18, 'em') calcPerTypo( 16, 36, 'em') );

				@include mx-mq-max($bp-sp-max) {
					@include per( $property: 'padding', $val: calcPerTypo( 14, 14, 'em') 0 );
					@include fs( 14 );
				}
			}

			&:disabled {

				span {
					background-color: #ddd;
					cursor: default;

					&:after {
						border-color: #ddd;
					}
				}
			}
		}

		&--fluid {
			max-width: 100%;

			&--s {

				a, span {
					display: inline-block;
					@include per( $property: 'padding', $val: calcPerTypo( 14, 6, 'em') calcPerTypo( 14, 18, 'em') );
					@include fs( 14 );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'padding', $val: calcPerTypo( 12, 6, 'em') calcPerTypo( 12, 18, 'em') );
						@include fs( 12 );
					}
				}
			}

			&--l {

				a, span {
					display: inline-block;
					@include per( $property: 'padding', $val: calcPerTypo( 16, 12, 'em') calcPerTypo( 16, 24, 'em') );

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'padding', $val: calcPerTypo( 14, 14, 'em') calcPerTypo( 14, 28, 'em') );
						@include fs( 14 );
					}
				}
			}
		}

		&--border {
			// display: none;

			// @include mx-mq-max($bp-sp-max) {
			// 	display: block;
			// }

			a, span {
				background-color: transparent;
				color: #222;
				border-color: #222;

				&:hover {
					background-color: transparent;
					color: $c-thm-01;
					border-color: $c-thm-01;
				}
			}
		}
	}

	&-arrow {
		color: $c-thm-01;
		@include fs( 14 );

		@include mx-mq-max($bp-sp-max) {
			@include fs( 12 );
			@include letter( 50 );
		}

		a {
			transition: all .3s $easing;

			[class^="icon-"] {
				margin-left: .5em;
				position: relative;
				right: 0;
				transition: all .3s $easing;
			}

			&:hover {

				@include mx-mq-min($bp-pc-min) {
					opacity: .6;

					[class^="icon-"] {
						right: -4px;
					}
				}
			}
		}

		> span, > a {
			// @include per( $property: 'padding-right', $val: calcPerTypo( 14, 15, 'em') );
			color: $c-thm-01;
			font-weight: bold;
			position: relative;

			[class^="icon-"] {
				margin-left: .5em;
				@include fs( 14 );
				line-height: 1.5;
				vertical-align: top;
				position: relative;
				// top: 50%;
				right: 0;
				// transform: translateY(-50%);
				transition: all .3s $easing;

				@include mx-mq-max($bp-sp-max) {
				// 	@include fs( 14 );
					line-height: 1.3;
				}
			}
		}
	}
}

a {

	[class^="icon-arrow"] {
		transition: right .9s $easing;
	}

	&:hover {

		[class^="icon-arrow"] {
			right: -4px;
		}
	}

	&.photo-swipe {
		position: relative;

		&[href$=".jpg"],
		&[href$=".png"],
		&[href$=".gif"] {
			display: block;
			text-decoration: none;

			&:before {
				/* use !important to prevent issues with browser extensions that change fonts */
				font-family: 'icomoon' !important;
				speak: none;
				font-style: normal;
				font-weight: normal;
				font-variant: normal;
				text-transform: none;
				line-height: 1;

				/* Better Font Rendering =========== */
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;

				display: none;
				background-color: hsla(0, 0%, 13%, .75);
				width: 48px;
				height: 48px;
				color: #fff;
				text-align: center;
				@include fs( 16 );
				line-height: 48px;
				position: absolute;
				right: 0;
				bottom: 0;
				content: "\e901";
				pointer-events: none;

				@include mx-mq-max($bp-sp-max) {
					display: block;
				}

				:not(.entry-content) & {
					display: none;
				}
			}
		}
	}
}

[class*="cp-block"] {

	.text {

		&--center {
			text-align: center;
		}
	}
}

/****************************************************
 * STYLE
 */
.hdr-bar {
	display: flex;
	align-content: center;
	background-color: #fff;
	// height: 84px;
	line-height: 1;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1000;
	box-shadow: 0 0 6px hsla(0,0%,0%,.2);

	.navigation {
		display: flex;
		align-content: center;
		flex: 1;
		@include ff( noto );
		@include fs( 14 );
		// font-size: 14px;
		font-weight: bold;
		@include letter( 50 );

		@include mx-mq-max( $bp-tb-max ) {
			// font-size: ( 14 / 12.533333333 ) + rem;
			font-size: 1rem;
		}

		@include mx-mq-max( $bp-sp-max ) {
			@include fs( 14 );
		}

		> ul {
			padding-left: 0;
		}
	}
}

.siteID {
	height: 84px;
	padding: 22px 0 0 36px;
	margin: 0;

	@include mx-mq-max($bp-sp-max) {
		height: 60px;
		padding: 14px 0 0 15px;
	}

	a {
		display: block;
		transition: opacity .3s $easing;

		&:hover {
			opacity: .6;
		}
	}

	img {
		width: 144px;

		@include mx-mq-max($bp-sp-max) {
			width: 120px;
		}
	}
}

.nav {

	&-global {
		display: flex;
		align-content: center;
		margin: 0;
		line-height: 80px;
		transition: transform .6s $easing;

		@include mx-mq-max( $bp-tb-max ) {
			// display: none;
			display: block;
			// flex-direction: column;
			background-color: hsla(201, 88%, 37%, .95);
			@include per( $property: 'width', $val: calcPer( 768, 480, '%') );
			@include calc( height, '100vh - 84px' );
			@include lh( 21, 14 );
			position: absolute;
			top: 84px;
			left: 100%;
			// bottom: 0;
			overflow: auto;
			-webkit-overflow-scrolling: touch;
			transform: translate3d( 0, 0, 0 );

			a, .has-lower__parent {
				display: block;
				@include per( $property: 'padding', $val: calcPerTypo( 14, 18, 'em') 0 calcPerTypo( 14, 18, 'em') calcPerTypo( 14, 24, 'em') );
				color: #fff;
			}
		}

		@include mx-mq-max( $bp-sp-max ) {
			width: 100%;
			// @include calc( height, '100vh - 60px' );
			height: 0;
			top: 60px;
			left: 0;
			transition: height .6s $easing 0s;

			.has-lower__parent {
				@include per( $property: 'padding-left', $val: calcPerTypo( 14, 15, 'em') );
			}
		}

		.is-menu-open & {
			transform: translate3d(-100%,0,0);

			@include mx-mq-max( $bp-sp-max ) {
				@include calc( height, '100vh - 60px' );
				transform: translate3d(0,0,0);
			}
		}

		> li {

			&:not(:nth-child(1)) {
				@include per( $property: 'padding-left', $val: calcPerTypo( 14, 30, 'em') );

				@include mx-mq-min-max( $bp-tb-max, 1152px ) {
					@include per( $property: 'padding-left', $val: calcPerTypo( 11, 21, 'em') );
				}

				@include mx-mq-max( $bp-tb-max ) {
					// @include per( $property: 'padding-left', $val: calcPerTypo( 14, 24, 'em') );
					padding-left: 0;
				}
			}

			&:nth-child(1) {
				@include per( $property: 'padding-left', $val: calcPerTypo( 14, 36, 'em') );

				@include mx-mq-min-max( $bp-tb-max, 1152px ) {
					@include per( $property: 'padding-left', $val: calcPerTypo( 11, 29, 'em') );
				}

				@include mx-mq-max( $bp-tb-max ) {
					// @include per( $property: 'padding-left', $val: calcPerTypo( 14, 24, 'em') );
					padding-left: 0;
				}
			}

			&.public-offering {
				display: none;

				@include mx-mq-max( $bp-tb-max ) {
					display: block;
				}

				a {

					@include mx-mq-max( $bp-tb-max ) {
						@include per( $property: 'width', $val: calcPer( 480, 360, '%') );
						@include per( $property: 'padding', $val: calcPerTypo( 14, 13, 'em') calcPerTypo( 14, 27, 'em') );
						@include per( $property: 'margin', $val: calcPer( 480, 36, '%') auto );
						// @include fs( 14 );
						font-size: 1rem;
						@include letter( 50 );
						text-align: center;
						border: 1px solid hsla(0,0%,100%,.75);
						@include per( $property: 'border-radius', $val: calcPerTypo( 14, 24.5, 'em') );
					}

					@include mx-mq-max( $bp-sp-max ) {
						@include per( $property: 'width', $val: calcPer( $block100sp, 690, '%') );
						@include per( $property: 'margin', $val: calcPer( $block100sp, 72, '%') auto );
						@include fs( 14 );
					}
				}
			}
		}

		.has-lower {

			&__parent {
				display: block;
				height: 100%;
				position: relative;

				@include mx-mq-max( $bp-tb-max ) {
					border-bottom: 1px solid hsla(0,0%,100%,.25);
					// position: relative;
				}

				&:before {
					display: block;
					background-color: $c-thm-01;
					height: 3px;
					position: absolute;
					bottom: 0;
					right: 0;
					left: 0;
					content: '';
					transition: opacity .3s $easing;
					opacity: 0;
				}

				> [class^="icon-"] {
					display: none;

					@include mx-mq-max( $bp-tb-max ) {
						display: block;
						@include per( $property: 'width', $val: calcPerTypo( 14, 14, 'em') );
						@include per( $property: 'height', $val: calcPerTypo( 14, 14, 'em') );
						// @include fs( 16 );
						font-size: 16px;
						position: absolute;
						top: 50%;
						@include per( $property: 'right', $val: calcPerTypo( 16, 14, 'em') );
						// transform: rotate(90deg) translateX(-50%);
						// transform: rotate(90deg) translateX(-50%);
						transform: translateY(-50%) rotate(-90deg);
						// transform: rotate(90deg) translateX(-50%) scale( 1, -1 );
						transition: transform .6s $easing;
					}

					&:before {
						color: #fff;

						@include mx-mq-max( $bp-tb-max ) {
							display: block;
							position: absolute;
							top: 0;
							left: 50%;
							transform: translateX(-50%);
							// transform: rotate(-90deg);
						}
					}
				}
			}

			&:hover {

				.has-lower__parent {
					opacity: .6;

					&:before {
						opacity: 1;
					}
				}

				.lower-container {
					z-index: 20;

					&__inner {

						@include mx-mq-min( $bp-pc-min ) {
							max-height: 100%;
							padding: 4.16667% 0;
							opacity: 1;
							transition:
								/* openning */
								max-height 0s 0s $easing,
								padding .3s 0s $easing,
								opacity .3s .2s $easing;
						}
					}

					&__bg {

						@include mx-mq-min( $bp-pc-min ) {
							padding-bottom: 6px;
							// opacity: 1;
							transition:
								/* openning */
								all .3s 0s $easing;
						}
					}
				}
			}

			&.is-closed {

				.has-lower__parent {

					> [class^="icon-"] {
						transform: translateY(-50%) rotate(90deg);
						// transform: rotate(90deg) translateX(-50%);
					}
				}

				// .lower-container__inner {
				// 	padding: 0;
				// }
			}
		}

		.lower {

			&-container {
				// display: none;
				width: 100vw;
				height: 0;
				// @include per( $property: 'padding', $val: calcPer( $block100, 60, '%') 0 );
				line-height: 1.5;
				position: absolute;
				top: 100%;
				left: 50%;
				transform: translateX(-50%);
				overflow: hidden;

				@include mx-mq-min( $bp-pc-min ) {
					height: auto !important;
					z-index: 10;
				}

				@include mx-mq-max( $bp-tb-max ) {
					display: block;
					background-color: transparent;
					width: 100%;
					padding: 0;
					position: static;
					transform: none;
					overflow: hidden;
				}

				&:before {
					display: block;
					// background-color: hsla(0, 0%, 13%, 0.2);
					background-color: rgba(33,33,33,.1);
					width: 100%;
					height: 1px;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 100;
					content: '';

					@include mx-mq-max( $bp-tb-max ) {
						display: none;
					}
				}

				&.is-hidden {
					display: none;
				}

				&__bg {
					position: relative;

					@include mx-mq-min( $bp-pc-min ) {
						transition:
							/* closing */
							all .3s .3s $easing;
					}

					@include mx-mq-max( $bp-tb-max ) {
					}

					&:before {
						display: block;
						background-color: #fff;
						position: absolute;
						top: 0;
						right: 0;
						left: 0;
						bottom: 6px;
						content: '';

						@include mx-mq-max( $bp-tb-max ) {
							display: none;
						}
					}

					&:after {
						display: block;
						background-color: #fff;
						height: 20px;
						position: absolute;
						bottom: 6px;
						right: -20px;
						left: -20px;
						content: '';
						box-shadow: 0 6px 6px -6px hsla(0, 0%, 0%, 0.2);

						@include mx-mq-max( $bp-tb-max ) {
							display: none;
						}
					}
				}

				&__inner {
					display: flex;
					width: 80%;
					@include per( $property: 'padding', $val: calcPer( $block100, 60, '%') 0 );
					margin: 0 auto;
					position: relative;
					z-index: 10;

					@include mx-mq-min( $bp-pc-min ) {
						max-height: 0;
						padding: 0;
						opacity: 0;
						transition:
							/* closing */
							max-height 0s 0s $easing,
							padding .3s 0s $easing,
							opacity 0s 0s $easing;
					}

					@include mx-mq-max( $bp-tb-max ) {
						width: 100%;
						@include per( $property: 'padding', $val: calcPerTypo( 14, 18, 'em') 0 );
						border-bottom: 1px solid hsla(0,0%,100%,.25);
						transition: padding .3s $easing;
					}
				}
			}

			&__head {
				// flex: 1;
				@include per( $property: 'width', $val: calcPer( $block80, 288, '%') );
				// @include per( $property: 'padding', $val: calcPer( $block80, 12, '%') calcPer( $block80, 36, '%') calcPer( $block80, 12, '%') 0 );
				@include per( $property: 'padding', $val: calcPerTypo( 14, 12, 'em') calcPerTypo( 14, 36, 'em') calcPerTypo( 14, 12, 'em') 0 );
				// @include per( $property: 'padding', $val: 0 calcPer( $block80, 36, '%') 0 0 );

				@include mx-mq-max( $bp-tb-max ) {
					display: none;
				}

				dt, p {
					margin: 0;
					letter-spacing: normal;
					@include lh( 30, 24 );
				}

				dd {
					@include per( $property: 'padding-top', $val: calcPerTypo( 14, 24, 'em') );
					@include fs( 14 );
					@include letter( 50 );
					@include ff( yugo );
					font-weight: normal;
				}
			}

			&__list {
				// flex: 1;

				.link-arrow {

					@include mx-mq-max( $bp-tb-max ) {
						// font-size: ( 14 / 12.533333333 ) + rem;
						font-size: 1rem;

						span {
							display: block;
						}
					}

					@include mx-mq-max( $bp-sp-max ) {
						@include fs( 14 );
					}

					[class^="icon-"] {

						@include mx-mq-max( $bp-tb-max ) {
							// font-size: ( 14 / 12.533333333 ) + rem;
							font-size: 1rem;
							position: absolute;
							top: 50%;
							@include per( $property: 'right', $val: calcPerTypo( 14, 19, 'em') );
							transform: translateY(-50%);

							&:before {
								color: #fff;
							}
						}

						@include mx-mq-max( $bp-sp-max ) {
							// font-size: 14px;
							@include fs( 14 );
						}
					}
				}

				&--text {
					display: flex;
					flex-direction: column;
					flex-wrap: wrap;
					@include per( $property: 'height', $val: calcPerTypo( 14, 180, 'em') );
					// @include per( $property: 'padding', $val: calcPer( $block80, 12, '%') 0 calcPer( $block80, 12, '%') calcPer( $block80, 71, '%') );
					@include per( $property: 'padding', $val: 0 0 0 calcPer( $block80, 71, '%') );
					border-left: 1px solid #eee;

					@include mx-mq-max( $bp-tb-max ) {
						width: 100%;
						height: auto;
						@include per( $property: 'padding', $val: 0 0 0 calcPerTypo( 14, 24, 'em') );
						border-left: none;
					}

					@include mx-mq-max( $bp-sp-max ) {
						@include per( $property: 'padding-left', $val: calcPerTypo( 14, 30, 'em') );
					}

					li {
						@include per( $property: 'width', $val: calcPerTypo( 14, 272, 'em') );
						@include per( $property: 'padding', $val: calcPerTypo( 14, 12, 'em') calcPerTypo( 14, 24, 'em') calcPerTypo( 14, 12, 'em') 0 );

						@include mx-mq-max( $bp-tb-max ) {
							width: 100%;
						}

						&.link-arrow {

							@include mx-mq-max( $bp-tb-max ) {
								padding: 0;
							}
						}
					}
				}

				&--image {
					display: flex;
					flex-wrap: wrap;
					flex: 1;
					@include per( $property: 'padding', $val: 0 0 0 calcPer( $block80, 71, '%') );
					border-left: 1px solid #eee;

					@include mx-mq-max( $bp-tb-max ) {
						flex-direction: column;
						@include per( $property: 'padding', $val: 0 0 0 calcPerTypo( 14, 24, 'em') );
						border-left: none;
					}

					@include mx-mq-max( $bp-sp-max ) {
						@include per( $property: 'padding-left', $val: calcPerTypo( 14, 30, 'em') );
					}

					li {
						@include per( $property: 'width', $val: calcPer( 793, 180, '%') );
						@include per( $property: 'padding', $val: calcPerTypo( 14, 12, 'em') 0 );
						@include per( $property: 'margin', $val: 0 calcPer( 793, 24, '%') calcPer( 793, 12, '%') 0 );

						@include mx-mq-max( $bp-tb-max ) {
							width: 100%;
							padding: 0;
							margin: 0;
						}

						.link-arrow {
							@include per( $property: 'padding-top', $val: calcPerTypo( 14, 12, 'em') );
							transition: opacity .3s $easing;

							@include mx-mq-max( $bp-tb-max ) {
								padding: 0;

								> span {
									color: #fff;
								}
							}
						}

						&:nth-child( 4n + 4 ) {
							margin-right: 0;
						}

						&:nth-child( 4n + 1 ):nth-last-child(-n+4) {
							margin-bottom: 0;

							& ~ li {
								margin-bottom: 0;
							}
						}

						.thumb-unit {

							> span {
								display: block;
								position: relative;
								overflow: hidden;

								&:after {
									display: block;
									background-color: #fff;
									position: absolute;
									top: 0;
									right: 0;
									bottom: 0;
									left: 0;
									z-index: 50;
									opacity: 0;
									content: '';
									transition: all .3s $easing;
								}

								@include mx-mq-max( $bp-tb-max ) {
									display: none;
								}
							}

							img {
								transition: all .6s $easing;
							}
						}

						a {

							&:hover {

								.thumb-unit {

									> span {

										&:after {
											opacity: .4;
										}
									}

									img {
										transform: scale(1.05);
									}
								}

								.link-arrow {
									opacity: .6;
								}
							}
						}
					}
				}
			}
		}
	}

	&-extra {
		display: flex;
		align-content: center;
		justify-content: flex-end;
		flex: 1;
		margin: 0;
		line-height: 84px;
		margin-left: auto;

		a, div {
			position: relative;

			[class^="icon-"] {
				font-size: 14px;
				position: relative;
				@include per( $property: 'top', $val: calcPerTypo( 14, 1, 'em') );

				@include mx-mq-max( 1152px ) {
					font-size: 20px;
					@include per( $property: 'top', $val: calcPerTypo( 20, 4, 'em') );
				}

				@include mx-mq-max( $bp-tb-max ) {
					font-size: 24px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
				}

				@include mx-mq-max( $bp-sp-max ) {
					@include fs( 18 );
				}
			}
		}

		.label {
			@include per( $property: 'margin-left', $val: calcPerTypo( 14, 6, 'em') );

			@include mx-mq-max( 1152px ) {
				display: none;
			}
		}

		li {

			&:not(.nav-extra__public-offering) {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}

		&__public-offering {

			@include mx-mq-max( $bp-tb-max ) {
				display: none;
			}

			a {
				@include per( $property: 'padding', $val: calcPerTypo( 14, 8, 'em') calcPerTypo( 14, 29, 'em') );
				@include letter( 50 );
				color: $c-thm-01;
				border: 1px solid currentColor;
				@include per( $property: 'border-radius', $val: calcPerTypo( 14, 18, 'em') );
				transition: opacity .3s $easing;

				@include mx-mq-min-max( $bp-tb-max, 1152px ) {
					background-color: transparent;
					@include per( $property: 'padding', $val: calcPerTypo( 11, 7, 'em') calcPerTypo( 11, 20, 'em') );
				}

				&:hover {
					opacity: .6;
				}
			}
		}

		&__global {
			position: relative;
			z-index: 10;

			@include mx-mq-max( $bp-sp-max ) {
				position: static;
			}

			&__inner {
				@include per( $property: 'padding', $val: 0 calcPerTypo( 14, 30, 'em') );
				color: $c-thm-01;
				letter-spacing: normal;
				font-weight: 500;
				@include ff( montserrat );
				transition: opacity .3s $easing;

				@include mx-mq-max( 1280px ) {
					@include per( $property: 'padding', $val: 0 calcPerTypo( 13, 26, 'em') );
				}

				@include mx-mq-max( $bp-tb-max ) {
					width: 60px;
					height: 60px;
					padding: 0;
				}

				@include mx-mq-max( $bp-sp-max ) {
					width: 44px;
					height: 44px;
				}
			}

			@include mx-mq-min( $bp-pc-min ) {

				&:hover {

					.nav-extra__global__inner {
						opacity: .6;
					}

					.lang-container {
						visibility: visible;
						opacity: 1;
						transform: translate(-50%, 0);
					}
				}
			}

			&.is-show {

				.nav-extra__global__inner {
					opacity: .6;
				}

				.lang-container {
					visibility: visible;
					opacity: 1;
					transform: translate(-50%, 0);
				}
			}

			.lang {

				&-container {
					@include lh( 21, 14 );
					white-space: nowrap;
					position: absolute;
					// top: 100%;
					@include per( $property: 'top', $val: calcPer( 84, 78, '%') );
					left: 50%;
					transform: translate(-50%, -20px);
					visibility: hidden;
					opacity: 0;
					transition: all .3s $easing;

					@include mx-mq-max( $bp-tb-max ) {
						@include per( $property: 'top', $val: calcPer( 84, 108, '%') );
					}

					@include mx-mq-max( $bp-sp-max ) {
						@include per( $property: 'width', $val: calcPer( $block100sp, 690, 'vw') );
						@include per( $property: 'top', $val: calcPer( 120, 156, '%') );
					}

					&:before, &:after {
						display: block;
						background-color: #fff;
						width: 12px;
						height: 12px;
						margin-right: -4px;
						transform: rotate(-45deg) translate(0%,0%);
						transform-origin: 0% 0%;
						position: absolute;
						top: 0;
						right: 50%;
						content: '';

						@include mx-mq-max( $bp-tb-max ) {
							margin-right: -3px;
						}

						@include mx-mq-max( $bp-sp-max ) {
							margin-right: 0;
							@include calc( right, '114px - 4vw');
						}
					}

					&:before {
						box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
					}

					&:after {
						z-index: 20;
					}

					ul {
						background-color: #fff;
						@include per( $property: 'padding', $val: calcPerTypo( 14, 4, 'em') );
						border-radius: 4px;
						box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
						position: relative;
						z-index: 10;
					}
				}

				&__list {
					text-align: left;
					letter-spacing: normal;

					&:nth-child(1) {
						border-bottom: 1px solid #eee;
					}

					a {
						display: block;
						@include per( $property: 'width', $val: calcPerTypo( 14, 192, 'em') );
						height: auto;
						@include per( $property: 'padding', $val: calcPerTypo( 14, 12, 'em') calcPerTypo( 14, 14, 'em') calcPerTypo( 14, 12, 'em') calcPerTypo( 14, 34, 'em') );
						color: #222;
						font-weight: normal;
						transition: opacity .3s $easing;

						@include mx-mq-max( $bp-tb-max ) {
							@include per( $property: 'width', $val: calcPerTypo( 14, 232, 'em') );
							@include per( $property: 'padding-right', $val: calcPerTypo( 14, 54, 'em') );
						}

						@include mx-mq-max( $bp-sp-max ) {
							width: 100%;
						}

						&:hover {
							opacity: .6;
						}

						&:before {
							display: none;
							/* use !important to prevent issues with browser extensions that change fonts */
							font-family: 'icomoon' !important;
							speak: none;
							font-style: normal;
							font-weight: normal;
							font-variant: normal;
							text-transform: none;
							line-height: 1;
							// font-size: 16px;
							font-size: 1rem;

							/* Better Font Rendering =========== */
							-webkit-font-smoothing: antialiased;
							-moz-osx-font-smoothing: grayscale;

							content: "\e904";
							color: $c-thm-01;
							position: absolute;
							top: 50%;
							@include per( $property: 'left', $val: calcPerTypo( 16, 14, 'em') );
							transform: translateY(-50%);
						}

						span {
							font-size: ( 14 / 16 ) + em;
						}
					}

					&--jp {

						.jp & {

							a {
								color: $c-thm-01;
								font-weight: bold;
								pointer-events: none;

								&:before {
									display: block;
								}
							}
						}
					}

					&--en {

						.en & {

							a {
								color: $c-thm-01;
								font-weight: 700;
								pointer-events: none;

								&:before {
									display: block;
								}
							}
						}
					}
				}
			}
		}

		&__contact {

			a {
				background-color: $c-thm-01;
				@include per( $property: 'padding', $val: 0 calcPerTypo( 14, 30, 'em') );
				@include ff( noto );
				@include letter( 25 );
				color: #fff;
				transition: opacity .3s $easing;

				@include mx-mq-max( 1280px ) {
					@include per( $property: 'padding', $val: 0 calcPerTypo( 13, 26, 'em') );
					@include letter( 50 );
				}

				@include mx-mq-max( $bp-tb-max ) {
					background-color: transparent;
					width: 60px;
					height: 60px;
					padding: 0;
				}

				@include mx-mq-max( $bp-sp-max ) {
					width: 44px;
					height: 44px;
				}

				[class^="icon-"] {

					&:before {

						@include mx-mq-max( $bp-tb-max ) {
							color: $c-thm-01;
						}
					}
				}

				&:hover {
					opacity: .6;
				}
			}
		}
	}

	&-sitemap {
		display: flex;
		justify-content: flex-end;
		// flex: 1;
		order: 0;
		@include per( $property: 'width', $val: calcPer( $block90, 1056, '%') );
		@include letter( 50 );

		@include mx-mq-max($bp-tb-max) {
			flex-wrap: wrap;
			@include per( $property: 'width', $val: calcPer( 690, 476, '%') );
		}

		@include mx-mq-max($bp-sp-max) {
			flex-direction: column;
			justify-content: flex-start;
			width: 100%;
			letter-spacing: normal;
		}

		.sitemap {

			&__unit {
				@include per( $property: 'width', $val: calcPer( 1056, 244, '%') );
				@include per( $property: 'padding-right', $val: calcPer( 1056, 24, '%') );

				@include mx-mq-max($bp-tb-max) {
					@include per( $property: 'width', $val: calcPer( 476, 238, '%') );
					@include per( $property: 'margin-bottom', $val: calcPerTypo( 14, 48, 'em') );

					&:nth-child( 2n + 1 ):nth-last-child( -n + 2 ) {
						margin-bottom: 0;

						& ~ .sitemap__unit {
							margin-bottom: 0;
						}
					}
				}

				@include mx-mq-max($bp-sp-max) {
					width: 100%;
					padding-right: 0;
					margin-bottom: 0;
				}

				&:last-child {

					> ul {

						&:not(:last-child) {
							@include per( $property: 'margin-bottom', $val: calcPerTypo( 16, 48, 'em') );

							@include mx-mq-max($bp-tb-max) {
								@include per( $property: 'margin-bottom', $val: calcPerTypo( 14, 48, 'em') );
							}

							@include mx-mq-max($bp-sp-max) {
								margin-bottom: 0;
							}
						}

						&:last-child {

							> li {

								@include mx-mq-max($bp-sp-max) {
									border-bottom: 1px solid hsla( 0, 0%, 100%, .2 );

									> a {
										margin-bottom: 0;
									}
								}
							}
						}
					}
				}
			}

			&__group {

				@include mx-mq-max($bp-sp-max) {
					border-top: 1px solid hsla( 0, 0%, 100%, .2 );
				}

				> li {

					ul {
						@include fs( 14 );
						@include letter( 50 );

						@include mx-mq-max($bp-tb-max) {
							font-size: 1rem;
						}

						@include mx-mq-max($bp-sp-max) {
							// display: none;
							// @include per( $property: 'padding', $val: 0 0 calcPer( $block90sp, 78, '%') calcPer( $block90sp, 30, '%') );
							@include per( $property: 'padding', $val: 0 0 calcPerTypo( 12, 36, 'em') calcPerTypo( 12, 15, 'em') );
							@include fs( 12 );
						}
					}

					li {
						@include per( $property: 'margin-top', $val: calcPerTypo( 14, 12, 'em') );

						@include mx-mq-max($bp-sp-max) {
							@include per( $property: 'margin-top', $val: calcPerTypo( 12, 18, 'em') );

							&:nth-child(1){
								margin-top: 0;
							}
						}

						a {
							transition: opacity .3s $easing;

							@include mx-mq-max($bp-sp-max) {
								display: block;
								// @include per( $property: 'padding', $val: calcPer( 660, 18, '%') 0 );
								// @include per( $property: 'padding', $val: calcPerTypo( 12, 9, 'em') 0 );
							}

							&:hover {
								opacity: .6;
							}
						}
					}
				}

				&.is-closed {

					.has-lower {

						[class^="icon-arrow"] {
							transform: translateY(-50%) rotate(90deg);
						}
					}
				}
			}

			&__lower {
				overflow: hidden;
			}

			&__ttl {
				display: inline-block;
				// @include per( $property: 'margin-bottom', $val: calcPerTypo( 16, 12, 'em') );
				@include ff( noto );
				font-weight: bold;
				@include letter( 50 );

				@include mx-mq-max($bp-tb-max) {
					// @include fs( 16 );
					font-size: (16 / 14 ) + rem;
				}

				@include mx-mq-max($bp-sp-max) {
					display: block;
					// @include per( $property: 'padding', $val: calcPer( $block90sp, 36, '%') 0 );
					@include per( $property: 'padding', $val: calcPerTypo( 14, 18, 'em') 0 );
					// @include per( $property: 'margin-bottom', $val: calcPer( $block90sp, 18, '%') );
					// margin-bottom: 0;
					@include fs( 14 );
					position: relative;
				}

				[class^="icon-"] {
					display: none;
					position: absolute;
					top: 50%;
					@include per( $property: 'right', $val: calcPer( $block90sp, 28, '%') );
					// transform: translateY(-50%) rotate(-90deg);
					transform: translateY(-50%);
					transition: transform .6s $easing;

					@include mx-mq-max($bp-sp-max) {
						display: block;
					}

					&:before {
						color: #fff;
					}
				}
			}
		}

		.has-lower {

			.sitemap__ttl {

				[class^="icon-"] {
					transform: translateY(-50%) rotate(-90deg);
				}
			}
		}
	}

	&-other {
		display: flex;
		justify-content: center;
		order: 2;
		width: 100%;
		@include per( $property: 'padding-top', $val: calcPerTypo( 14, 72, 'em') );
		@include letter( 50 );
		@include fs( 14 );

		@include mx-mq-max($bp-tb-max) {
			@include per( $property: 'padding-top', $val: calcPerTypo( 14, 52, 'em') );
			font-size: 1rem;
		}

		@include mx-mq-max($bp-sp-max) {
			flex-direction: column;
			@include per( $property: 'padding-top', $val: calcPer( $block90sp, 54, '%') );
			@include fs( 12 );
		}

		li {
			margin: 0 1em;

			@include mx-mq-max($bp-sp-max) {
				margin: 0;
			}
		}

		a {
			transition: opacity .3s $easing;

			@include mx-mq-max($bp-sp-max) {
				display: block;
				@include per( $property: 'padding', $val: calcPer( $block90sp, 18, '%') 0 );
			}

			&:hover {
				opacity: .6;
			}
		}
	}
}

.menu {
	display: none;
	background-color: transparent;

	@include mx-mq-max( $bp-tb-max ) {
		align-self: center;
		display: block;
		width: 60px;
		height: 60px;
		padding: 0;
		margin-right: 6px;
		position: relative;
	}

	@include mx-mq-max( $bp-sp-max ) {
		width: 44px;
		height: 44px;
		margin-right: 8px;
	}

	span {
		display: block;
		background-color: $c-thm-01;
		width: 24px;
		height: 2px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		transition: background-color .6s $easing;

		@include mx-mq-max( $bp-sp-max ) {
			width: 20px;
			height: 1px;
			transform: translate(-50%,0);
		}

		.is-menu-open & {
			background-color: transparent;
		}

		&:before, &:after {
			display: block;
			background-color: $c-thm-01;
			width: 100%;
			height: 2px;
			position: absolute;
			top: 0;
			left: 0;
			transform: rotate( 0deg );
			content: '';

			@include mx-mq-max( $bp-sp-max ) {
				height: 1px;
			}
		}

		&:before {
			top: -6px;
			transition: top .3s $easing .3s, transform .3s $easing 0s;
			// animation: menuCloseBefore .6s ease 0s forwards;

			.is-menu-open & {
				top: 0;
				transform: rotate( 45deg );
				transition: top .3s $easing 0s, transform .3s $easing .3s;
				// animation: menuOpenBefore .6s ease 0s forwards;
			}

			@include mx-mq-max( $bp-sp-max ) {
				top: -5px;
			}
		}

		&:after {
			top: 6px;
			transition: top .3s $easing .3s, transform .3s $easing 0s;
			// animation: menuCloseAfter .6s ease 0s forwards;

			.is-menu-open & {
				top: 0;
				transform: rotate( -45deg );
				transition: top .3s $easing 0s, transform .3s $easing .3s;
				// animation: menuOpenAfter .6s ease 0s forwards;
			}

			@include mx-mq-max( $bp-sp-max ) {
				top: 5px;
			}
		}
	}
}

@keyframes menuOpenBefore {
	0% { top: -6px; }
	50% { top: 0; transform: rotate(0deg); }
	100% { top: 0; transform: rotate(45deg); }
}
@keyframes menuCloseBefore {
	0% { top: 0; transform: rotate(45deg); }
	50% { top: 0; transform: rotate(0deg); }
	100% { top: -6px; }
}
@keyframes menuOpenAfter {
	0% { top: 6px; }
	50% { top: 0; transform: rotate(0deg); }
	100% { top: 0; transform: rotate(-45deg); }
}
@keyframes menuCloseAfter {
	0% { top: 0; transform: rotate(-45deg); }
	50% { top: 0; transform: rotate(0deg); }
	100% { top: 6px; }
}

.mv {
	position: relative;

	&:after {
		display: block;
		background-color: hsla(0, 0%, 93%, .25);
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		content: '';
	}

	&--color--w {

		&:after {
			background-color: hsla(0, 0%, 27%, .25);
		}

		.text {
			color: #fff;
		}
	}

	.text {
		width: 100%;
		position: absolute;
		top: 50%;
		z-index: 100;
		transform: translateY(-50%);

		.description {
			margin: 0;
			@include lh( 28, 16 );
			@include letter( 50 );
			font-weight: bold;

			@include mx-mq-max($bp-sp-max) {
				@include fs( 12 );
				@include lh( 18, 12 );
			}
		}
	}

	// &--top {

	// 	@include per( $property: 'padding-bottom', $val: calcPer( 1440, 640, '%') );

	// 	@include mx-mq-max($bp-sp-max) {
	// 		// @include per( $property: 'padding-bottom', $val: calcPer( $block100sp, 525, '%') );
	// 		@include calc( height, '100vh - 60px' );
	// 	}

	// 	img, canvas {
	// 		width: 100%;
	// 	}

	// 	.text {
	// 		@include per( $property: 'padding-left', $val: calcPer( 1440, 145, '%') );
	// 		color: #002b5b;
	// 		left: 0;

	// 		@include mx-mq-max($bp-sp-max) {
	// 			@include per( $property: 'padding', $val: 0 calcPer( $block100sp, 60, '%') );
	// 		}

	// 		.title {
	// 			@include per( $property: 'margin', $val: 0 0 calcPer( 1295, 18, '%') );
	// 			@include lh( 60, 48 );

	// 			@include mx-mq-max($bp-sp-max) {
	// 				@include per( $property: 'margin-bottom', $val: calcPer( 630, 24, '%') );
	// 				@include fs( 30 );
	// 				@include lh( 37.5, 30 );
	// 			}
	// 		}

	// 		.description {
	// 			@include per( $property: 'margin', $val: 0 0 calcPer( 1295, 30, '%') );
	// 			@include ff( noto );

	// 			@include mx-mq-max($bp-sp-max) {
	// 				@include per( $property: 'margin-bottom', $val: calcPer( 630, 48, '%') );
	// 				@include fs( 12 );
	// 			}
	// 		}

	// 		.btn {
	// 			width: 180px;

	// 			@include mx-mq-max($bp-sp-max) {
	// 				width: 120px;
	// 			}

	// 			a {
	// 				display: block;
	// 				@include per( $property: 'padding', $val: calcPerTypo( 16, 11, 'em') 0 );
	// 				// @include lh( 24, 16 );
	// 				text-align: center;
	// 				font-weight: bold;
	// 				border: 1px solid currentColor;
	// 				transition: all .3s $easing;

	// 				@include mx-mq-max($bp-sp-max) {
	// 					@include per( $property: 'padding', $val: calcPerTypo( 12, 8, 'em') 0 );
	// 					@include fs( 12 );
	// 				}

	// 				&:hover {
	// 					color: $c-thm-01;
	// 					// border-color: $c-thm-01;
	// 				}
	// 			}
	// 		}
	// 	}
	// }

	&--lower {
		// background: {
		// 	repeat: no-repeat;
		// 	position: 50% 50%;
		// 	size: cover;
		// }
		@include per( $property: 'padding-bottom', $val: calcPer( 1440, 432, '%') );

		@include mx-mq-max($bp-sp-max) {
			@include per( $property: 'padding-bottom', $val: calcPer( $block100sp, 525, '%') );
		}

		.text {
			text-align: center;
			right: 0;
			left: 0;

			.category {
				display: inline-block;
				@include per( $property: 'margin', $val: 0 0 calcPer( 1440, 24, '%') );
				@include fs( 18 );
				// @include lh( 27, 18 );
				font-weight: bold;
				border-bottom: 3px solid currentColor;

				@include mx-mq-max($bp-sp-max) {
					@include per( $property: 'margin-bottom', $val: calcPer( $block100sp, 36, '%') );
					@include fs( 12 );
					border-bottom-width: 2px;
				}
			}

			.title {
				// @include per( $property: 'margin', $val: 0 0 calcPer( 1440, 24, '%') );
				margin: 0;
				letter-spacing: normal;
				@include lh( 75, 60 );

				@include mx-mq-max($bp-sp-max) {
					@include fs( 30 );
					@include lh( 37.5, 30 );
					@include letter( -10 );
				}
			}

			.description {
				@include per( $property: 'margin', $val: calcPer( 1440, 24, '%') 0 0 );
				text-align: center;
				@include fs( 16 );
				@include ff( noto );

				@include mx-mq-max($bp-sp-max) {
					@include per( $property: 'margin', $val: calcPer( $block100sp, 36, '%') calcPer( $block100sp, 60, '%') 0 );
					@include fs( 12 );

					br {
						display: none;
					}
				}
			}
		}

		&--low {
			@include per( $property: 'padding-bottom', $val: calcPer( 1440, 288, '%') );

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'padding-bottom', $val: calcPer( $block100sp, 375, '%') );
			}
		}
	}


	&--text {
		@include per( $property: 'padding-top', $val: calcPer( 1440, 96, '%') );

		@include mx-mq-max($bp-sp-max) {
			@include per( $property: 'padding-top', $val: calcPer( $block100sp, 120, '%') );
		}

		&:after {
			display: none;
		}

		.title {
			margin: 0;
			text-align: center;
			@include lh( 45, 36 );

			@include mx-mq-max($bp-sp-max) {
				@include fs( 20 );
			}
		}
	}
}

.fig {

	a {
		display: block;
		position: relative;

		@include mx-mq-min($bp-sp, 'gt') {
			pointer-events: none;
		}

		&:before {
			display: none;
			background-color: $c-thm-01;
			width: 4.7em;
			height: 1px;
			margin: 0 0 .2em -.6em;
			position: absolute;
			bottom: 0;
			left: 50%;
			content: '';
			transform: translateX(-50%);

			@include mx-mq-max( $bp-sp ) {
				display: block;
			}
		}

		&:after {
			display: none;
			/* use !important to prevent issues with browser extensions that change fonts */
			font-family: 'icomoon' !important;
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 1;

			/* Better Font Rendering =========== */
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: "図を拡大表示 \e901";

			@include mx-mq-max( $bp-sp ) {
				display: inline-block;
				@include per( $property: 'margin', $val: calcPerTypo( 12, 6, 'em') 0 0 );
				@include fs( 12 );
				@include lh( 21, 12 );
				@include letter( 50 );
				color: $c-thm-01;
			}
		}
	}

	// &__btn {
	// 	display: none;

	// 	@include mx-mq-max( $bp-sp ) {
	// 		display: inline-block;
	// 		@include per( $property: 'margin', $val: calcPer( $block80sp, 12, '%') 0 0 );
	// 		@include fs( 12 );
	// 		@include lh( 21, 12 );
	// 		@include letter( 50 );
	// 		color: $c-thm-01;

	// 		[class^="icon-"] {
	// 			@include per( $property: 'margin-left', $val: calcPerTypo( 12, 6, 'em') );
	// 		}
	// 	}
	// }

	// &__txt {
	// 	text-decoration: underline;
	// }
}

.learn-more {
	// @include per( $property: 'padding-top', $val: calcPer( 1440, 96, '%') );
	// margin-top: 0;
	// position: relative;

	// @include mx-mq-max( $bp-sp ) {
	// 	@include per( $property: 'padding-top', $val: calcPer( $block100sp, 120, '%') );
	// }

	// &:before {
	// 	display: block;
	// 	background-color: #eee;
	// 	// width: 92%;
	// 	height: 1px;
	// 	position: absolute;
	// 	top: 0;
	// 	left: 5%;
	// 	right: 5%;
	// 	content: '';

	// 	@include mx-mq-max( $bp-sp ) {
	// 		left: 4%;
	// 		right: 4%;
	// 	}
	// }

	.m-ttl {
		@include per( $property: 'margin-bottom', $val: calcPer( $block90, 48, '%') );
		@include fs( 30 );

		@include mx-mq-max( $bp-sp ) {
			@include per( $property: 'margin-bottom', $val: calcPer( $block90sp, 60, '%') );
			@include fs( 20 );
			@include lh( 25, 20 );
		}
	}
}

.ftr__inner {
	width: 90%;
	@include per( $property: 'padding', $val: calcPer( 1440, 72, '%') 0 calcPer( 1440, 60, '%') );
	margin: 0 auto;

	@include mx-mq-max($bp-tb-max) {
		@include per( $property: 'width', $val: calcPer( 768, 690, '%') );
		@include per( $property: 'padding', $val: calcPer( 768, 52, '%') 0 );
	}

	@include mx-mq-max($bp-sp-max) {
		@include per( $property: 'width', $val: calcPer( $block100sp, 690, '%') );
		@include per( $property: 'padding', $val: calcPer( $block100sp, 96, '%') 0 calcPer( $block100sp, 72, '%') );
	}

	.navigation {
		display: flex;
		flex-wrap: wrap;
	}

	.global-site {
		order: 1;
		@include per( $property: 'width', $val: calcPer( $block90, 240, '%') );
		// @include fs( 14 );
		@include lh( 24, 16 );

		@include mx-mq-max($bp-tb-max) {
			@include per( $property: 'width', $val: calcPer( 690, 214, '%') );
			font-size: 1rem;
		}

		@include mx-mq-max($bp-sp-max) {
			order: 0;
			width: 100%;
			@include per( $property: 'margin-bottom', $val: calcPer( $block90sp, 72, '%') );
		}

		&__inner {
			display: block;
			background-color: #fff;
			@include per( $property: 'padding', $val: calcPerTypo( 16, 11, 'em') calcPerTypo( 16, 35, 'em') calcPerTypo( 16, 11, 'em') calcPerTypo( 16, 33, 'em') );
			font-weight: bold;
			color: #000d1b;
			border: 1px solid hsla(0, 100%, 100%, 1);
			position: relative;
			cursor: pointer;
			transition: all .3s $easing;

			@include mx-mq-max($bp-tb-max) {
				font-size: ( 16 / 14 ) + rem;
			}

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'padding', $val: calcPerTypo( 14, 13, 'em') calcPerTypo( 14, 17, 'em') calcPerTypo( 14, 13, 'em') calcPerTypo( 14, 41, 'em') );
				@include fs( 14 );
				@include lh( 21, 14 );
			}

			.small {
				font-size: ( 14 / 16 ) + em;
			}
		}

		.icon-global {
			@include fs( 14 );
			position: absolute;
			top: 50%;
			@include per( $property: 'left', $val: calcPerTypo( 14, 11, 'em') );
			// left: 23px;
			transform: translateY(-50%);

			@include mx-mq-max($bp-tb-max) {
				font-size: 1rem;
			}

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'left', $val: calcPerTypo( 14, 12, 'em') );
			}

			&:before {
				color: #000d1b;
				transition: color .3s $easing;
			}
		}

		.icon-arrow {
			@include fs( 16 );
			position: absolute;
			top: 50%;
			@include per( $property: 'right', $val: calcPerTypo( 16, 15, 'em') );
			transform: translateY(-50%) rotate(-90deg);
			transition: transform .3s $easing;

			&:before {
				color: #000d1b;
				transition: color .3s $easing;
			}
		}

		&.is-closed {

			.global-site__inner {
				background-color: transparent;
				color: #fff;
				border-color: hsla(0, 100%, 100%, .5);
			}

			.icon-global {

				&:before {
					color: #fff;
				}
			}

			.icon-arrow {
				transform: translateY(-50%) rotate(90deg);

				&:before {
					color: #fff;
				}
			}
		}

		.lang {

			&-container {
				overflow: hidden;

				ul {
				}
			}

			&__list {
				border: {
					width: 0 1px 1px;
					style: solid;
					color: hsla(0, 100%, 100%, .5);
				}

				a {
					display: block;
					@include per( $property: 'padding', $val: calcPerTypo( 16, 11, 'em') calcPerTypo( 16, 35, 'em') calcPerTypo( 16, 11, 'em') calcPerTypo( 16, 33, 'em') );
					position: relative;

					@include mx-mq-max($bp-tb-max) {
						font-size: ( 16 / 14 ) + rem;
					}

					@include mx-mq-max($bp-sp-max) {
						@include per( $property: 'padding', $val: calcPerTypo( 14, 13, 'em') calcPerTypo( 14, 17, 'em') calcPerTypo( 14, 13, 'em') calcPerTypo( 14, 41, 'em') );
						@include fs( 14 );
						@include lh( 21, 14 );
					}

					&:before {
						display: none;
						/* use !important to prevent issues with browser extensions that change fonts */
						font-family: 'icomoon' !important;
						speak: none;
						font-style: normal;
						font-weight: normal;
						font-variant: normal;
						text-transform: none;
						line-height: 1;

						/* Better Font Rendering =========== */
						-webkit-font-smoothing: antialiased;
						-moz-osx-font-smoothing: grayscale;

						content: "\e904";
						@include fs( 16 );
						position: absolute;
						top: 50%;
						@include per( $property: 'left', $val: calcPerTypo( 16, 11, 'em') );
						transform: translateY(-50%);

						@include mx-mq-max($bp-tb-max) {
							font-size: ( 16 / 14 ) + rem;
						}

						@include mx-mq-max($bp-sp-max) {
							@include fs( 16 );
							@include per( $property: 'left', $val: calcPerTypo( 16, 14, 'em') );
						}
					}

					span {
						font-size: ( 14 / 16 ) + em;
					}
				}

				&--jp {

					.jp & {

						a {
							font-weight: bold;
							pointer-events: none;

							&:before {
								display: block;
							}
						}
					}
				}

				&--en {

					.en & {

						a {
							font-weight: 700;
							pointer-events: none;

							&:before {
								display: block;
							}
						}
					}
				}
			}
		}
	}
}

.copyright {
	@include per( $property: 'margin', $val: calcPerTypo( -12, 21, 'em') 0 0 );
	// @include fs( 12 );
	@include lh( 21, 12 );

	@include mx-mq-max($bp-tb-max) {
		@include per( $property: 'margin-top', $val: calcPerTypo( 14, 12, 'em') );
		text-align: center;
	}

	@include mx-mq-max($bp-sp-max) {
		@include per( $property: 'margin-top', $val: calcPerTypo( 14, 27, 'em') );
		text-align: left;
	}

	small {
		@include fs( 12 );
		@include letter( 50 );
		font-weight: 500;
		color: hsla(0,0%,100%,.75);

		@include mx-mq-max($bp-tb-max) {
			font-size: ( 12 / 14 ) + rem;
			@include lh( 21, 12 );
		}

		@include mx-mq-max($bp-sp-max) {
			@include fs( 12 );
			letter-spacing: normal;
		}
	}
}

.pagetop {
	width: 64px;
	height: 64px;
	position: fixed;
	right: 40px;
	bottom: 28px;
	z-index: 100;
	visibility: hidden;
	opacity: 0;
	transition: all .3s $easing;

	@include mx-mq-max($bp-sp-max) {
		width: 50px;
		height: 50px;
		right: 15px;
		bottom: 15px;
	}

	&.is-show {
		visibility: visible;
		opacity: 1;
	}

	a {
		display: block;
		background-color: #000d1b;
		background-clip: content-box;
		// border: 1px solid hsla(0,0%,100%,.5);
		border-radius: 50%;
		box-shadow: 0 0 0.5px 1px rgba(255,255,255,0.5);
		position: relative;
		overflow: hidden;

		&:after {
			display: block;
			background-color: hsla(0,0%,100%,0);
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			content: '';
			transition: background-color .3s $easing;
		}

		&:hover {

			&:after {
				background-color: hsla(0,0%,100%,.4);
			}
		}
	}

	[class^="icon-"] {
		display: block;
		line-height: 64px;
		text-align: center;

		@include mx-mq-max($bp-sp-max) {
			line-height: 50px;
		}

		&:before {
			display: block;
			color: #fff;
			transform: rotate( -90deg );
		}
	}
}

.alert {
	background-color: hsla(0, 0%, 93%, .95);
	margin: 0;
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
	transform: translateY(100%);
	// transition: transform .6s $easing .6s;

	// &.is-show {
	// 	transform: translateY(0);
	// }

	.block__inner {
		@include per( $property: 'padding', $val: calcPer( $block100, 48, '%') 0 );
		margin: 0 auto;

		@include mx-mq-max($bp-sp-max) {
			@include per( $property: 'width', $val: calcPer( $block100sp, 630, '%') );
			@include per( $property: 'padding', $val: calcPer( $block100sp, 69, '%') 0 calcPer( $block100sp, 60, '%') );
		}
	}

	&__close {
		background-color: transparent;
		@include per( $property: 'width', $val: calcPerTypo( 24, 48, 'em') );
		@include per( $property: 'height', $val: calcPerTypo( 24, 48, 'em') );
		padding: 0;
		@include fs( 24 );
		position: absolute;
		top: 0;
		right: 0;
		transition: opacity .3s $easing;

		@include mx-mq-max($bp-sp-max) {
			@include per( $property: 'width', $val: calcPerTypo( 18, 42, 'em') );
			@include per( $property: 'height', $val: calcPerTypo( 18, 42, 'em') );
			@include fs( 18 );
		}

		[class^="icon-"] {
			@include per( $property: 'line-height', $val: calcPerTypo( 24, 48, 'em') );

			&:before {
				color: #888;
			}
		}

		&:hover {
			opacity: .6;
		}
	}

	&__contents {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include mx-mq-max($bp-sp-max) {
			flex-direction: column;
		}

		.text {
			@include per( $property: 'width', $val: calcPer( $block90, 1056, '%') );
			margin: 0;
			@include fs( 14 );

			@include mx-mq-max($bp-sp-max) {
				width: 100%;
				@include per( $property: 'margin-bottom', $val: calcPerTypo( 14, 24, 'em') );
				@include letter( 15 );
			}

			a {
				text-decoration: underline;
				color: $c-thm-01;
				transition: all .3s $easing;

				&:hover {
					opacity: .6;
				}
			}
		}

		.btn {
			@include per( $property: 'width', $val: calcPer( $block90, 192, '%') );

			@include mx-mq-max($bp-sp-max) {
				width: 100%;
			}
		}

		.link-btn {
			width: 100%;
		}
	}
}

.notfound {
	@include per( $property: 'margin', $val: calcPer( $block100, 120, '%') 0 );
	text-align: center;

	@include mx-mq-max( $bp-sp-max ) {
		@include per( $property: 'margin', $val: calcPer( $block100sp, 144, '%') 0 );
	}

	.block__inner {

		.title {

			@include mx-mq-max( $bp-sp-max ) {
				@include per( $property: 'margin-bottom', $val: calcPer( $block60sp, 48, '%') );
			}
		}
	}

	p {
		@include per( $property: 'margin', $val: 0 0 calcPer( $block60, 72, '%') );
		@include lh( 28, 16 );

		@include mx-mq-max( $bp-sp-max ) {
			@include per( $property: 'margin-bottom', $val: calcPer( $block60sp, 96, '%') );
			text-align: left;
		}
	}

	.link-btn {
		width: 240px;
		margin: 0 auto;

		@include mx-mq-max( $bp-sp-max ) {
			@include per( $property: 'width', $val: calcPer( $block100sp, 630, '%') );
		}
	}
}

/****************************************************
 * OVERRIDE
 */
.panel-grid-cell {
	width: 100%;
}




