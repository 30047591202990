/*
 * Montserrat Regular: 400, Medium: 500, Bold: 700
 *
 * Layout
 * $block90: 1296px; / .cp-block-90 --- 1296px / 1440px
 * $block80: 1152px; / .cp-block-80 --- 1152px / 1440px
 * $block70: 1008px; / .cp-block-70 --- 1008px / 1440px
 * $block60:  864px; / .cp-block-60 ---  864px / 1440px
 */

/****************************************************
 * OVERRIDE
 */
.slick {

    &-slider {
        position: relative;
    }

    &-list {
        // @include per( $property: 'padding-bottom', $val: calcPer( 1320, 8, '%') );
        // padding-bottom: 0.6060606% !important;

        // @include mx-mq-max($bp-sp) {
        //     overflow: visible;
        // }
    }

    &-arrow {
        background-color: transparent;
        @include per( $property: 'width', $val: calcPer( 1320, 72, '%') );
        @include per( $property: 'padding', $val: 0 0 calcPer( 1320, 72, '%') );
        position: absolute;
        top: 50%;
        z-index: 100;
        transform: translateY(-50%);

        @include mx-mq-max($bp-sp) {
            background-color: hsla(0, 0%, 13%, .75);
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }

        [class^="icon-"] {
            display: block;
            // background-color: #ff0;
            @include fs( 21 );
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate( -50%, -50% );

            @include mx-mq-max($bp-sp) {
                @include fs( 16 );
            }

            &:before {
                color: #222;

                @include mx-mq-max($bp-sp) {
                    color: #fff;
                }
            }
        }
    }

    &-prev {
        margin-left: -50vw;
        left: 50%;

        @include mx-mq-max($bp-sp) {
            margin-left: 0;
            @include per( $property: 'left', $val: calcPer( $block100sp, 30, 'vw') );
        }

        [class^="icon-"] {
            transform: translate( -50%, -50% ) rotate(180deg);
        }
    }

    &-next {
        margin-right: -50vw;
        right: 50%;

        @include mx-mq-max($bp-sp) {
            margin-right: 0;
            @include per( $property: 'right', $val: calcPer( $block100sp, 30, 'vw') );
        }
    }

    &-dots {
        display: flex;
        justify-content: center;
        width: 100%;
        @include per( $property: 'padding-top', $val: calcPer( 1320, 28, '%') );
        // position: absolute;
        // top: 100%;
        // left: 0;

        @include mx-mq-max($bp-sp) {
            @include per( $property: 'padding-top', $val: calcPer( $block100sp, 48, '%') );
        }

        li {
            // @include per( $property: 'width', $val: calcPer( 1320, 8, '%') );
            // @include per( $property: 'padding-bottom', $val: calcPer( 1320, 8, '%') );
            // @include per( $property: 'margin', $val: 0 calcPer( 1320, 8, '%') );
            width: 8px;
            margin: 0 4px;
            list-style: none;
            position: relative;

            button {
                display: block;
                background-color: hsla(0, 0%, 13%, .25);
                width: 8px;
                height: 8px;
                padding: 0;
                text-indent: -999px;
                font-size: 0;
                // position: absolute;
                // top: 0;
                // right: 0;
                // bottom: 0;
                // left: 0;
                overflow: hidden;
                border-radius: 4px;
            }

            &.slick-active {

                button {
                    background-color: hsla(0, 0%, 13%, 1);
                }
            }
        }
    }
}