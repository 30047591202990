// @font-face {
//   font-family: "montserrat-font";
//   font-style: normal;
//   font-weight: normal;
//   src: url("../fonts/Montserrat-Regular.ttf") format("truetype");
//  }

// @font-face {
//   font-family: "montserrat-font";
//   font-style: normal;
//   font-weight: bold;
//   src: url("../fonts/Montserrat-Semibold.ttf") format("truetype");
// }

@font-face {
  font-family: "opensans-font";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/OpenSans-Regular.ttf") format("truetype");
 }

@font-face {
  font-family: "opensans-font";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/OpenSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "ibmplexsans-font";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/IBMPlexSans-Regular.otf") format("opentype");
 }

@font-face {
  font-family: "ibmplexsans-font";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/IBMPlexSans-Bold.otf") format("opentype");
}

@font-face {
  font-family: "notosans-font";
  font-style: normal;
  font-weight: normal;
  src:
    local("Noto Sans CJK JP Regular"),
    url("https://fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff2") format('woff2'), // Google Fonts CDN (Early Access, Noto Sans Japanese / v5)
    url("https://fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff") format('woff'), // Google Fonts CDN (Early Access, Noto Sans Japanese / v5)
    url("https://fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.otf") format('opentype'), // Google Fonts CDN (Early Access, Noto Sans Japanese / v5)
    url("../fonts/NotoSansCJKjp-Bold-sub.woff2") format("woff2"); // Server Installed
}

@font-face {
  font-family: "notosans-font";
  font-style: normal;
  font-weight: bold;
  src:
    local("Noto Sans CJK JP Bold"),
    url("https://fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff2") format('woff2'), // Google Fonts CDN (Early Access, Noto Sans Japanese / v5)
    url("https://fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff") format('woff'), // Google Fonts CDN (Early Access, Noto Sans Japanese / v5)
    url("https://fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.otf") format('opentype'), // Google Fonts CDN (Early Access, Noto Sans Japanese / v5)
    url("../fonts/NotoSansCJKjp-Bold-sub.woff2") format("woff2"); // Server Installed
}
