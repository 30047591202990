@mixin calc( $property, $expression ) {
	#{$property}: -moz-calc(#{$expression});
	#{$property}: -webkit-calc(#{$expression});
	#{$property}: calc(#{$expression});
}

@mixin calcImportant( $property, $expression ) {
	#{$property}: -moz-calc(#{$expression}) !important;
	#{$property}: -webkit-calc(#{$expression}) !important;
	#{$property}: calc(#{$expression}) !important;
}
