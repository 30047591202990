/****************************************************
 * OVERRIDE
 */
body {
	// font-size: 16px;
	// font-size: 1.0rem;
	// font-family: $font-family--hiragino;
	line-height: 1.5;
	word-wrap: break-word;
	outline: none;
	color: #222;

	font-feature-settings: "palt";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-variant-ligatures: none;

	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-o-text-size-adjust: 100%;
	text-size-adjust: 100%;
}

html {

	&.jp {
		@include mx-font-size-scaling(12px, 16px, 320px, 375px); // Mobile (Small Display)
		@include mx-mq-min(375px){
			font-size: 16px; // Mobile (Large Display / Landscape Mode)
		}
		@include mx-mq-min($bp-tb-min){
			font-size: 14px; // Tablet
		}
		// @include mx-mq-min(800px){
		// 	font-size: 12px; // PC (Small Display)
		// }
		@include mx-mq-min($bp-pc-min){
			@include mx-font-size-scaling(12px, 16px, $bp-pc-min, 1440px); // PC (Middle Display)
		}
		@include mx-mq-min(1440px){
			font-size: 16px; // PC (Large Display)
		}

		font-family: "Montserrat", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
		@include mx-mq-max( $bp-sp ) {
			font-family: "Montserrat", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "游ゴシック", YuGothic, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
		}

		@media all and (-ms-high-contrast:none) {
			font-family: "メイリオ", Meiryo, "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
		}

		h1, h2, h3, h4, h5, .lv1, .lv2, .lv3, .lv4, .lv5, .lv6 {
			font-family: "Montserrat","notosans-font", sans-serif;
			// letter-spacing: 0.0em;
		}
		p, dt, dd {
			// text-align: justify;
			// text-justify: inter-ideograph;
			letter-spacing: 0.075em;
		}
		code {
			font-family: inherit;
		}
	}

	&.en {
		@include mx-font-size-scaling(12px, 16px, 320px, 375px); // Mobile (Small Display)
		@include mx-mq-min(375px){
			font-size: 16px; // Mobile (Large Display / Landscape Mode)
		}
		@include mx-mq-min($bp-tb-min){
			font-size: 14px; // Tablet
		}
		// @include mx-mq-min(800px){
		// 	font-size: 12px; // PC (Small Display)
		// }
		@include mx-mq-min($bp-pc-min){
			@include mx-font-size-scaling(12px, 16px, $bp-pc-min, 1440px); // PC (Middle Display)
		}
		@include mx-mq-min(1440px){
			font-size: 16px; // PC (Large Display)
		}

		font-family: "opensans-font", sans-serif;
		// font-family: "ibmplexsans-font", sans-serif;

		h1, h2, h3, h4, h5, .lv1, .lv2, .lv3, .lv4, .lv5, .lv6 {
			font-family: "Montserrat", sans-serif;
			// letter-spacing: 0em;
		}
		p, dt, dd {
			// text-align: justify;
			// text-justify: inter-ideograph;
			// letter-spacing: 0.025em;
		}
		code {
			font-family: inherit;
		}
	}
}

picture {
	display: block;
}

img, svg {
	// max-width: 100%;
	width: 100%;
	height: auto;
	line-height: 1;
	vertical-align: top;
	border: none;
}

textarea {
	vertical-align: top;
	resize: vertical;
}

a {
	color: currentColor;
	text-decoration: none;

	&:focus {
		outline: none;
	}

	&[href^='tel:'] {
		cursor: default;
	}
}

::selection {
	background: $c-thm-01;
	color: #fff;
}

::-moz-selection {
	background: $c-thm-01;
	color: #fff;
}

:focus {
	outline: none;
}

input, textarea {
	// -webkit-appearance: none;
	// -moz-appearance: none;
	// appearance: none;
	box-sizing: border-box;
}

button {
	border: none;
	cursor: pointer;
}

h1, .lv1 {
	font-size: 1.875rem; // Mobile & Tablet
	font-weight: bold;
	@include letter( 0 );
	@include mx-mq-min($bp-tb-min){
		font-size: 2.5rem;
	}
	@include mx-mq-min(960px){
		font-size: 3.75rem; // PC
	}
}
h2, .lv2 {
	font-size: 1.75rem; // Mobile & Tablet
	font-weight: bold;
	@include letter( 0 );
	@include mx-mq-min($bp-tb-min){
		font-size: 2.25rem;
	}
	@include mx-mq-min(960px){
		font-size: 3rem; // PC
	}
}

body:not(.is-home) {

	.lv2 {
		font-size: 1.5rem; // Mobile & Tablet
		font-weight: bold;
		@include letter( 0 );
		@include mx-mq-min($bp-tb-min){
			font-size: 1.75rem;
		}
		@include mx-mq-min(960px){
			font-size: 2.25rem; // PC
		}
	}
}

h3, .lv3 {
	font-size: 1.5rem; // Mobile & Tablet
	font-weight: bold;
	@include letter( 0 );
	@include mx-mq-min($bp-tb-min){
		font-size: 1.75rem;
	}
	@include mx-mq-min(960px){
		font-size: 2.25rem; // PC
	}
}
h4, .lv4 {
	font-size: 1.125rem; // Mobile & Tablet
	font-weight: bold;
	@include letter( 50 );
	@include mx-mq-min($bp-tb-min){
		font-size: 1.25rem;
	}
	@include mx-mq-min(960px){
		font-size: 1.5rem; // PC
	}
}
h5, .lv5 {
	font-size: 1rem; // Mobile & Tablet
	font-weight: bold;
	@include letter( 50 );
	@include mx-mq-min($bp-tb-min){
		font-size: 1rem;
	}
	@include mx-mq-min(960px){
		font-size: 1.125rem; // PC
	}
}
h6, .lv6 {
	font-size: .875rem; // Mobile & Tablet
	font-weight: bold;
	@include letter( 50 );
	@include mx-mq-min($bp-tb-min){
		font-size: .875rem;
	}
	@include mx-mq-min(960px){
		font-size: 1rem; // PC
	}
}
p, dt, dd {
	font-size: .875rem; // Mobile
	@include mx-mq-min($bp-tb-min){
		font-size: 1rem; // Tablet
	}
	@include mx-mq-min(960px){
		font-size: 1rem; // PC
	}
}

dl, dd, ul, ol, figure {
	padding: 0;
	margin: 0;
}


/* 各ベンダープレフィックスが取れた標準版！！(http://caniuse.com/#search=placeholder-shown) */
:placeholder-shown {
	color: #888;
	@include letter( 50 );
}

/* Google Chrome, Safari, Opera 15+, Android, iOS */
::-webkit-input-placeholder {
	color: #888;
	@include letter( 50 );
}

/* Firefox 18- */
:-moz-placeholder {
	color: #888;
	@include letter( 50 );
	opacity: 1;
}

/* Firefox 19+ */
::-moz-placeholder {
	color: #888;
	@include letter( 50 );
	opacity: 1;
}

/* IE 10+ */
:-ms-input-placeholder {
	color: #888;
	@include letter( 50 );
}