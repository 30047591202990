$c-thm-01: #0b78b1;
$c-thm-02: #000d1b;
$c-thm-03: #627482;
$c-thm-04: #93a0ab;

$c-act-01: #ab6333;
$c-act-02: #c18259;
$c-act-03: #d3a07f;
$c-act-04: #e3bfa9;
$c-act-05: #eb7629;

$c-otr-01: #8a6e4e;
$c-otr-02: #b4aca4;
$c-otr-03: #e6e7e8;
$c-otr-04: #0f181f;
$c-otr-05: #898a8d;